import {
  infoDisplayEnabled,
  platetechEnabled,
  postNotifierEnabled,
  snb3rdPartyEnabled,
  snbEnabled,
  taskuparkkiEnabled,
} from "../config";

export enum SystemSetting {
  PLATETECH_API_URL = "PLATETECH_API_URL",
  PLATETECH_TRIGGER_ENABLED = "PLATETECH_TRIGGER_ENABLED",
  PLATETECH_TRIGGER_AUTH_WHITELIST = "PLATETECH_TRIGGER_AUTH_WHITELIST",
  PLATETECH_SEND_EXTENDED_LPR_DATA = "PLATETECH_SEND_EXTENDED_LPR_DATA",
  PLATETECH_DIRECTION_REQUIRED_FOR_EXTENDED_LPR_DATA = "PLATETECH_DIRECTION_REQUIRED_FOR_EXTENDED_LPR_DATA",
  PLATETECH_WAIT_AFTER_IMAGE_WRITE = "PLATETECH_WAIT_AFTER_IMAGE_WRITE",
  SNB_API_URL = "SNB_API_URL",
  SNB_API_USERNAME = "SNB_API_USERNAME",
  SNB_API_PASSWORD = "SNB_API_PASSWORD",
  SNB_IP_AUTH_WHITELIST = "SNB_IP_AUTH_WHITELIST",
  TASKUPARKKI_API_URL = "TASKUPARKKI_API_URL",
  TASKUPARKKI_API_USERNAME = "TASKUPARKKI_API_USERNAME",
  TASKUPARKKI_API_PASSWORD = "TASKUPARKKI_API_PASSWORD",
  POST_NOTIFICATION_URLS = "POST_NOTIFICATION_URLS",
  PARKING_HALL_ID = "PARKING_HALL_ID",
  INCLUDED_PLATES_ONLY = "INCLUDED_PLATES_ONLY",
  INFO_DISPLAY_STATIC_ENTRANCE_MESSAGE = "INFO_DISPLAY_STATIC_ENTRANCE_MESSAGE",
  INFO_DISPLAY_WELCOME_MESSAGE = "INFO_DISPLAY_WELCOME_MESSAGE",
  INFO_DISPLAY_CONTRACT_WELCOME_MESSAGE = "INFO_DISPLAY_CONTRACT_WELCOME_MESSAGE",
  INFO_DISPLAY_BYE_MESSAGE = "INFO_DISPLAY_BYE_MESSAGE",
  INFO_DISPLAY_PARKING_OPERATED_BY_MESSAGE = "INFO_DISPLAY_PARKING_OPERATED_BY_MESSAGE",
  INFO_DISPLAY_PARKING_PAID_MESSAGE = "INFO_DISPLAY_PARKING_PAID_MESSAGE",
  INFO_DISPLAY_PARKING_NOT_PAID_MESSAGE = "INFO_DISPLAY_PARKING_NOT_PAID_MESSAGE",
  INFO_DISPLAY_OVERPARKING_MESSAGE = "INFO_DISPLAY_OVERPARKING_MESSAGE",
  INFO_DISPLAY_PARKING_WAS_FREE_MESSAGE = "INFO_DISPLAY_PARKING_WAS_FREE_MESSAGE",
  INFO_DISPLAY_WAIT_TIME = "INFO_DISPLAY_WAIT_TIME",
  INFO_DISPLAY_MAX_TIME = "INFO_DISPLAY_MAX_TIME",
  INFO_DISPLAY_SIMULTANEOUS_PLATES = "INFO_DISPLAY_SIMULTANEOUS_PLATES",

  MAX_AGE_FOR_REQUEST_FULFILLING_DETECTIONS_MS = "MAX_AGE_FOR_REQUEST_FULFILLING_DETECTIONS_MS",
  REQUESTS_TTL_MS = "REQUESTS_TTL_MS",
  FALLBACK_BEFORE_TIMEOUT_MS = "FALLBACK_BEFORE_TIMEOUT_MS",
}

export const INFO_DISPLAY_SETTINGS = [
  SystemSetting.INFO_DISPLAY_STATIC_ENTRANCE_MESSAGE,
  SystemSetting.INFO_DISPLAY_WELCOME_MESSAGE,
  SystemSetting.INFO_DISPLAY_CONTRACT_WELCOME_MESSAGE,
  SystemSetting.INFO_DISPLAY_BYE_MESSAGE,
  SystemSetting.INFO_DISPLAY_PARKING_OPERATED_BY_MESSAGE,
  SystemSetting.INFO_DISPLAY_PARKING_PAID_MESSAGE,
  SystemSetting.INFO_DISPLAY_PARKING_NOT_PAID_MESSAGE,
  SystemSetting.INFO_DISPLAY_OVERPARKING_MESSAGE,
  SystemSetting.INFO_DISPLAY_WAIT_TIME,
  SystemSetting.INFO_DISPLAY_MAX_TIME,
  SystemSetting.INFO_DISPLAY_SIMULTANEOUS_PLATES,
  SystemSetting.INFO_DISPLAY_PARKING_WAS_FREE_MESSAGE,
];

export const ALLOWED_SETTINGS = [
  ...(platetechEnabled || snb3rdPartyEnabled
    ? [
        SystemSetting.MAX_AGE_FOR_REQUEST_FULFILLING_DETECTIONS_MS,
        SystemSetting.REQUESTS_TTL_MS,
        SystemSetting.FALLBACK_BEFORE_TIMEOUT_MS,
      ]
    : []),
  ...(platetechEnabled
    ? [
        SystemSetting.PLATETECH_API_URL,
        SystemSetting.PLATETECH_SEND_EXTENDED_LPR_DATA,
        SystemSetting.PLATETECH_TRIGGER_ENABLED,
        SystemSetting.PLATETECH_TRIGGER_AUTH_WHITELIST,
        SystemSetting.PLATETECH_DIRECTION_REQUIRED_FOR_EXTENDED_LPR_DATA,
        SystemSetting.PLATETECH_WAIT_AFTER_IMAGE_WRITE,
      ]
    : []),
  ...(snbEnabled
    ? [
        SystemSetting.SNB_API_URL,
        SystemSetting.SNB_API_USERNAME,
        SystemSetting.SNB_API_PASSWORD,
      ]
    : []),
  ...(snb3rdPartyEnabled ? [SystemSetting.SNB_IP_AUTH_WHITELIST] : []),
  ...(taskuparkkiEnabled
    ? [
        SystemSetting.TASKUPARKKI_API_URL,
        SystemSetting.TASKUPARKKI_API_USERNAME,
        SystemSetting.TASKUPARKKI_API_PASSWORD,
        SystemSetting.PARKING_HALL_ID,
      ]
    : []),
  SystemSetting.INCLUDED_PLATES_ONLY,
  ...(postNotifierEnabled ? [SystemSetting.POST_NOTIFICATION_URLS] : []),
  ...(infoDisplayEnabled ? INFO_DISPLAY_SETTINGS : []),
];

export type SettingName = typeof ALLOWED_SETTINGS[number];

export type Setting = {
  codeName: SettingName;
  values: string[];
};

export type SettingConfig = {
  description: string;
  isMultiValue?: boolean;
  isHttpUrl?: boolean;
  isIpAddress?: boolean;
  isBoolean?: boolean;
  isNumber?: boolean;
  isReadOnly?: boolean;
  shouldDisplay?: (value: unknown) => boolean;
  defaults?: string[];
  validate?: (value: unknown[]) => string[] | undefined;
};

export enum SettingBoolValue {
  True = "Yes",
  False = "No",
}

export const openApiSystemSetting = {
  type: "object",
  required: ["codeName", "values"],
  properties: {
    codeName: {
      type: "string",
      enum: Object.keys(SystemSetting).filter((item) => {
        return isNaN(Number(item));
      }),
    },
    values: {
      type: "array",
      items: {
        type: "string",
      },
    },
  },
};
