import { ReactNode } from "react";
import styled from "styled-components";

type Props = {
  children?: ReactNode;
};

export const Table = ({ children }: Props) => {
  return (
    <Container>
      <Header />
      <Footer />
      <Scrollable>
        <StyledTable>{children}</StyledTable>
      </Scrollable>
    </Container>
  );
};

const Container = styled.div`
  max-height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--secondary);
  border-radius: 4px;
  position: relative;
`;

const Header = styled.div`
  width: 100%;
  height: 49px;
  top: 0px;
  background: var(--tertiary);
  border-radius: 4px 4px 0 0;
  flex-shrink: 0;
  position: absolute;
`;

const Footer = styled.div`
  width: 100%;
  height: 16px;
  bottom: 0;
  background: var(--tertiary);
  border-radius: 0 0 4px 4px;
  flex-shrink: 0;
  position: absolute;
`;

const Scrollable = styled.div`
  max-height: 100%;
  position: relative;
  overflow-y: auto;

  &:after {
    position: sticky;
    content: "";
    bottom: 0;
    display: block;
    width: 100%;
    height: 16px;
    box-shadow: 0px -3px 6px #00000029;
    border-radius: 0px 0px 4px 4px;
    background: var(--tertiary);
  }
`;

const StyledTable = styled.table`
  position: relative;
  width: 100%;
  border: 0;
  border-spacing: 0;

  & thead {
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 4px 4px 0px 0px;
    background: var(--tertiary);
    position: sticky;
    top: 0px;

    & tr {
      & th {
        padding: 14px 16px;
        text-align: left;
        border: 0;

        &:first-child {
          border-top-left-radius: 4px;
        }

        &:last-child {
          border-top-right-radius: 4px;
        }
      }
    }
  }

  & tbody {
    border: 0;

    & tr {
      & td {
        padding: 14px 16px;
        border: 0;
        color: #aaaaaa;
        background: none;

        &:first-of-type {
          font-weight: bold;
          color: var(--text-primary);
        }
      }
    }
  }
`;
