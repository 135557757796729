import React from "react";

import { useAuth } from "common/hooks";
import { Redirect, Route } from "react-router-dom";

type Props = {
  redirectLoggedIn: string;
} & React.PropsWithChildren<Route["props"]>;

/**
 * A wrapper for Route component that redirects authenticated users.
 */
export const AnonOnlyRoute = ({
  children,
  redirectLoggedIn,
  ...routeProps
}: Props) => {
  const { isAuth } = useAuth();
  return (
    <Route
      {...routeProps}
      render={({ location }) =>
        !isAuth ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: redirectLoggedIn,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
