import { useCallback } from "react";
import styled from "styled-components";
import { DoorDb } from "../../../../../backend/src/doors/types";

type Props = {
  door: DoorDb;
  isSelected: boolean;
  onClick: (doorId: string) => void;
};

export const DoorName = ({ door, isSelected, onClick }: Props) => {
  const handleClick = useCallback(() => onClick(door.id), [door.id, onClick]);

  return (
    <Li selected={isSelected} onClick={handleClick}>
      {door.name}
    </Li>
  );
};

const Li = styled.li<{
  selected?: boolean;
}>`
  font-size: 16px;
  line-height: 22px;
  margin-top: 1px;
  padding: 12px 10px 13px 30px;
  word-break: break-all;
  background-color: ${({ selected }) =>
    selected ? "#313740" : "var(--secondary)"};
  color: var(--text-primary);
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;
