import { ApiEndpoints } from "api/endpoints";
import { ChildrenOnly } from "common/types";
import { backendRootUrl } from "config";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import ReconnectingWebSocket from "reconnecting-websocket";
import {
  InfoDisplayMessageType,
  WebSocketMessage,
} from "../../../../backend/src/integration/infoDisplay/types";
import { appendTokenIfAuthenticated } from "./useSocket";

const SocketContext = createContext<ReconnectingWebSocket | null | undefined>(
  undefined
);

export const SocketContextProvider = ({ children }: ChildrenOnly) => {
  const [socket, setSocket] = useState<ReconnectingWebSocket | null>(null);

  useEffect(() => {
    const socket = new ReconnectingWebSocket(
      appendTokenIfAuthenticated(
        `${backendRootUrl.replace(/^http/, "ws")}${
          ApiEndpoints.InfoDisplayListen
        }`
      ),
      undefined,
      { connectionTimeout: 1000 }
    );

    setSocket(socket);
    return () => socket.close();
  }, []);

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};

type InfoDisplayMessage =
  | WebSocketMessage<InfoDisplayMessageType.PlateEntranceOrExit>
  | WebSocketMessage<InfoDisplayMessageType.Settings>;

export const useSocket = (messageCb: (message: InfoDisplayMessage) => void) => {
  const socket = useContext(SocketContext);

  if (socket === undefined)
    throw new Error("useSocket must be used within SocketContextProvider");

  useEffect(() => {
    if (!messageCb || !socket) return;

    const handleMessage = (messageEvent: MessageEvent) => {
      messageCb(JSON.parse(messageEvent.data));
    };

    socket.addEventListener("message", handleMessage);
    return () => socket.removeEventListener("message", handleMessage);
  }, [socket, messageCb]);

  return socket;
};

export const useInfoDisplayMessageWebSocket = <
  T extends InfoDisplayMessageType
>(
  type: T,
  handler: (message: WebSocketMessage<T>["payload"]) => void
) => {
  const handleMessage = useCallback(
    (message: InfoDisplayMessage) => {
      if (message.type === type) {
        handler(message.payload as WebSocketMessage<T>["payload"]);
      }
    },
    [handler, type]
  );
  useSocket(handleMessage);
};
