import { CarPlatePlain } from "@asantech/common/react/CarPlate";
import { usePlateData } from "common/hooks";
import { Table, TableSelectableRow } from "components";
import styled from "styled-components";
import { getIncludedPlates } from "../../../api/plates";

export const IncludedPlatesTable = () => {
  const { plateData } = usePlateData(getIncludedPlates);

  return (
    <PanelCenter>
      <Table>
        <thead>
          <tr>
            <th>Plate</th>
            <th>Comment</th>
          </tr>
        </thead>
        <tbody>
          {plateData.map(({ id, text, country, regionCode, comment }) => (
            <TableSelectableRow key={id}>
              <td>
                <CarPlatePlain
                  text={text}
                  country={country}
                  regionCode={regionCode}
                />
              </td>
              <td>{comment}</td>
            </TableSelectableRow>
          ))}
        </tbody>
      </Table>
    </PanelCenter>
  );
};

const PanelCenter = styled.div`
  max-width: 1300px;
  padding: 20px;
  margin: 20px auto;
`;
