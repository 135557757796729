import { CarPlatePlain } from "@asantech/common/react/CarPlate";
import { useIsDebugEnabled } from "@asantech/common/react/common/hooks/useIsDebugEnabled";
import { ZoomableImage } from "@asantech/common/react/ZoomableImage";
import { ReactComponent as AlertIcon } from "assets/svgs/alert-warning-icon.svg";
import { ReactComponent as ArrowIcon } from "assets/svgs/arrow-icon.svg";
import { LoadingBar, ScrollablePane } from "components";
import { format, parseISO } from "date-fns";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Virtuoso } from "react-virtuoso";
import styled from "styled-components";
import {
  DetectionDb,
  Direction,
  ExternalNotifications,
  OrphanReason,
} from "../../../../backend/src/detections/types";
import { Lane } from "../../../../backend/src/lanes/types";
import {
  getLowConfidenceAlertText,
  getOrphanedDetectionAlertText,
} from "../../common/utils";
import {
  Detection,
  isDetectionLowConfidence,
} from "../../common/utils/detections";
import { useLanes } from "../../store/Lanes.context";
import { DebugInfo } from "./DebugInfo";
import {
  TrafficTableColumn,
  useTrafficTableColumns,
} from "./useTrafficTableColumns";

const virtualListOverscan = 1500;

export type TrafficTableContentData = {
  lane: string;
  date: string;
  direction: React.ReactNode;
  alerts: React.ReactNode;
  expand: React.ReactNode;
  vehicle: React.ReactNode;
  sent?: React.ReactNode;
  detected: React.ReactNode;
};

export type TrafficTableDataRow = TrafficTableContentData & {
  id: string;
  thumbnails: Detection["thumbnails"];
  isExpanded: boolean;
  setExpandedRow: (rowId: string) => void;
  sentExternalNotifications: ExternalNotifications[];
};

const getSentPlates = (detection: Detection) => {
  const sent: Record<
    string,
    Pick<DetectionDb, "plateText" | "plateCountry" | "regionCode">
  > = {};
  for (const n of detection.sentExternalNotifications) {
    const { sentPlateText: plateText, sentPlateCountry: plateCountry } = n;
    const regionCode = undefined; // TODO ANPR-3499 store sent region code
    const key = plateText + plateCountry + regionCode;
    sent[key] = { plateText, plateCountry, regionCode };
  }
  return Object.values(sent);
};

const reduceLaneById = (
  result: Record<string, Lane>,
  lane: Lane
): Record<string, Lane> => ({
  ...result,
  [lane.id]: lane,
});

const LaneDirectionCell: React.FC<{
  direction: Direction | null;
  date: Date;
}> = ({ direction, date }) =>
  !date ? null : (
    <LaneDirectionContainer>
      <span>{direction || "unknown"}</span>
    </LaneDirectionContainer>
  );

const DetectionRowAlert: React.FC<{
  detection: Detection;
  lane: Lane;
  orphanReasons?: OrphanReason[] | null;
}> = ({ detection, lane, orphanReasons }): React.ReactElement<string> => {
  const orphanReasonPresent = !!orphanReasons;
  const hasOrphaned = !!orphanReasons && orphanReasons.length > 0;
  const hasLowConfidence = isDetectionLowConfidence(detection);
  const noAlerts = !hasLowConfidence && orphanReasonPresent && !hasOrphaned;

  return (
    <AlertContainer>
      {hasOrphaned && (
        <AlertIcon
          className="orphaned"
          role="tooltip"
          data-tooltip-id="tooltip"
          data-tooltip-content={getOrphanedDetectionAlertText(
            lane.direction,
            detection.direction,
            orphanReasons
          )}
        />
      )}
      {hasLowConfidence && (
        <AlertIcon
          className="lowConfidence"
          role="tooltip"
          data-tooltip-id="tooltip"
          data-tooltip-content={getLowConfidenceAlertText(detection)}
        />
      )}
      {noAlerts && <div>No alerts</div>}
    </AlertContainer>
  );
};

const TrafficListItem = ({
  isLast,
  data,
}: {
  isLast?: boolean;
  data: TrafficTableDataRow;
}) => {
  const isDebugMode = useIsDebugEnabled();
  const tableColumns = useTrafficTableColumns();
  const { getDeviceById } = useLanes();
  return (
    <>
      <TrafficListRow
        role="row"
        noBorder={isLast || data.isExpanded}
        onClick={() => data.setExpandedRow(data.isExpanded ? "" : data.id)}
      >
        {tableColumns.map((column: TrafficTableColumn) => (
          <TrafficListCol role="cell" key={`${column.key}-${data.id}`}>
            {data[column.key as keyof TrafficTableContentData]}
          </TrafficListCol>
        ))}
      </TrafficListRow>
      {data.isExpanded && (
        <TrafficListRow noBorder={isLast}>
          <ScrollablePane scrollByValue={400}>
            {data.thumbnails.map(
              ({
                id,
                src,
                plateArea,
                plateCountry,
                plateText,
                deviceId,
                plateTextConfidence,
                detectionDate,
              }) => (
                <Thumbnail key={id}>
                  <ZoomableImage
                    width={380}
                    height={190}
                    src={src}
                    alt="detection"
                    zoomArea={plateArea}
                  />
                  <DeviceName>
                    {deviceId && getDeviceById(deviceId)?.deviceName}
                  </DeviceName>
                  {isDebugMode && (
                    <DebugInfo
                      plateText={plateText}
                      plateCountry={plateCountry}
                      plateTextConfidence={plateTextConfidence}
                      detectionDate={detectionDate}
                      sentToExternal={data.sentExternalNotifications}
                      imageId={id}
                    />
                  )}
                </Thumbnail>
              )
            )}
          </ScrollablePane>
        </TrafficListRow>
      )}
    </>
  );
};

export const TrafficAuditLogTable: React.FC<{
  detections: Detection[];
  detectionsCount: number;
  lanes: Lane[];
  dataLoading?: boolean;
  dataLoadingKey?: string;
  onScrolledToEnd: () => void;
  firstItemIndex: number;
  scrollTopRef: React.MutableRefObject<number>;
}> = ({
  dataLoading,
  dataLoadingKey,
  detections,
  detectionsCount,
  lanes,
  onScrolledToEnd,
  firstItemIndex,
  scrollTopRef,
}): JSX.Element => {
  const [expandedRow, setExpandedRow] = useState("");
  const tableColumns = useTrafficTableColumns();
  const { doFetchDevices } = useLanes();

  useEffect(() => {
    doFetchDevices();
  }, [doFetchDevices]);

  const data: TrafficTableDataRow[] = useMemo(() => {
    const laneById = (lanes || []).reduce(reduceLaneById, {});

    return detections.map((row: Detection) => {
      const date = parseISO(row.detectionDate);
      const isExpanded = expandedRow === row.id;

      const sent = getSentPlates(row);
      const firstSent = sent[0];
      const warning =
        firstSent !== undefined &&
        (sent.length > 1 ||
          firstSent.plateText !== row.plateText ||
          firstSent.plateCountry !== row.plateCountry ||
          (firstSent.regionCode || "") !== (row.regionCode || ""));

      const plate = (
        <CarPlatePlain
          text={row.plateText}
          country={row.plateCountry}
          regionCode={row.regionCode}
        />
      );

      return {
        id: row.id,
        lane: laneById[row.laneId] ? laneById[row.laneId].name : "",
        sent: sent.map((s, i) => (
          <Fragment key={i}>
            <CarPlatePlain
              text={s.plateText}
              country={s.plateCountry}
              regionCode={s.regionCode}
            />
            {i < sent.length - 1 ? <>, </> : <></>}
          </Fragment>
        )),
        detected: <PlateInfo warning={warning}>{plate}</PlateInfo>,
        vehicle: <PlateInfo>{plate}</PlateInfo>,
        date: format(date, "MMMM d, yyyy HH:mm"),
        isExpanded,
        setExpandedRow,
        thumbnails: row.thumbnails,
        direction: <LaneDirectionCell direction={row.direction} date={date} />,
        alerts: (
          <DetectionRowAlert
            detection={row}
            lane={laneById[row.laneId]}
            orphanReasons={row.orphanReasons}
          />
        ),
        expand: <ExpandIcon $isActive={isExpanded} />,
        sentExternalNotifications: row.sentExternalNotifications,
      };
    });
  }, [lanes, detections, expandedRow]);

  const getListItem = useCallback(
    (index: number) => {
      const row = data[index - firstItemIndex];

      return <TrafficListItem isLast={index === data.length - 1} data={row} />;
    },
    [data, firstItemIndex]
  );

  const computeItemKey = useCallback(
    (_: number, item: TrafficTableDataRow) => item.id,
    []
  );

  const handleOnScroll = useCallback(
    (e: React.UIEvent<"div">) => {
      scrollTopRef.current = (
        e.currentTarget as unknown as HTMLDivElement
      ).scrollTop;
    },
    [scrollTopRef]
  );

  return (
    <TrafficList>
      <TrafficListHead role="row">
        {tableColumns.map((header: TrafficTableColumn) => (
          <TrafficListCol role="columnheader" key={header.key}>
            {header.label}
          </TrafficListCol>
        ))}
      </TrafficListHead>
      <LoadingBarStyled
        key={dataLoadingKey || "loading-bar"}
        isLoading={dataLoading}
      />
      <TrafficListBody>
        <Virtuoso
          firstItemIndex={firstItemIndex}
          itemContent={getListItem}
          data={data}
          initialItemCount={data.length}
          onScroll={handleOnScroll}
          computeItemKey={computeItemKey}
          endReached={onScrolledToEnd}
          increaseViewportBy={{ top: 0, bottom: virtualListOverscan }}
        />
      </TrafficListBody>
      <TrafficListFooter>Total results: {detectionsCount}</TrafficListFooter>
    </TrafficList>
  );
};

const LaneDirectionContainer = styled.div`
  display: flex;
  align-items: center;
  text-transform: capitalize;

  & > span:first-child {
    display: inline-block;
    min-width: 25px;
    margin-right: 16px;
  }
`;

const AlertContainer = styled.div`
  & > svg {
    outline: none;
  }

  & > svg:not(:first-of-type) {
    margin-left: 8px;
  }

  & > svg > path {
    fill: transparent;
  }

  & > svg.lowConfidence > path {
    stroke: #fff;
  }

  & > svg.orphaned > path {
    stroke: #f7ca47;
    background-color: #f7ca47a1;
  }
`;

const TrafficList = styled.div`
  margin-bottom: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const TrafficListFooter = styled.div`
  padding: 10px;
  text-align: right;
  color: var(--text-secondary);
  background: var(--tertiary);
  box-shadow: 0px -3px 6px #00000029;
  border-radius: 0px 0px 4px 4px;
`;

const TrafficListCol = styled.div`
  flex: 1;
  padding: 14px 16px;
  color: #aaa;

  &:first-of-type {
    flex: 0;
    padding: 0;
    flex: none;
    width: 45px;
    text-align: center;
  }
`;

const TrafficListHead = styled.div`
  display: flex;
  position: sticky;
  top: 0;
  background: var(--tertiary);
  border-radius: 4px 4px 0 0;

  ${TrafficListCol} {
    color: #fff;
    font-weight: bold;
  }

  ${LoadingBar} {
    bottom: 0;
  }
`;

const TrafficListBody = styled.div`
  flex: 1;

  & > div {
    background: var(--secondary);
  }
`;

const TrafficListRow = styled.div<{ noBorder?: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  ${(props) =>
    props.noBorder
      ? ""
      : `
    border-bottom: 2px solid var(--tertiary);
  `}
`;
const ExpandIcon = styled(ArrowIcon)<{ $isActive: boolean }>`
  transform: rotate(${({ $isActive }) => ($isActive ? "-" : "")}90deg);
`;

const Thumbnail = styled.div`
  margin: 16px;

  & + & {
    margin-left: 0;
  }
`;

const PlateInfo = styled.div<{ warning?: boolean }>`
  color: ${({ warning }) => (warning ? "var(--error)" : "var(--text-primary)")};
  font-weight: bold;
  padding-left: 0;
`;

const DeviceName = styled.div`
  width: 100%;
  font-size: 14px;
  font-weight: normal;
  color: #aaa;
  letter-spacing: 0;
`;

const LoadingBarStyled = styled(LoadingBar)`
  margin-top: -3px;
  position: relative;
`;
