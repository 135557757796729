import React from "react";

import { SocketContextProvider } from "common/hooks";
import { useAuth } from "common/hooks";
import { Redirect, Route } from "react-router-dom";

/**
 * A wrapper for Route component that redirects to the login when not authenticated.
 */
export const PrivateRoute = ({
  children,
  ...routeProps
}: React.PropsWithChildren<Route["props"]>) => {
  const { isAuth } = useAuth();
  return (
    <Route
      {...routeProps}
      render={({ location }) =>
        isAuth ? (
          <SocketContextProvider>{children}</SocketContextProvider>
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
