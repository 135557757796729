import { getToken } from "@asantech/common/react/localStorage";
import client, { AxiosRequestConfig } from "api/client";
import { ApiEndpoints } from "api/endpoints";
import {
  Detection,
  DetectionsBatch,
} from "../../../backend/src/detections/types";
import { blobDownload } from "../common/utils";
import { backendRootUrl } from "../config";

export const DETECTIONS_PAGE_SIZE = 50;

export async function getDetections(
  lastDetectionRetrieved?: { id: Detection["id"] },
  filters?: DetectionsFilterParams,
  config: Partial<AxiosRequestConfig> = {}
) {
  const params: DetectionsBatchQueryParams = {
    ...(filters || {}),
    limit: DETECTIONS_PAGE_SIZE,
    ...(lastDetectionRetrieved ? { beforeId: lastDetectionRetrieved.id } : {}),
  };
  return client.get<DetectionsBatch>(`${ApiEndpoints.DetectionsBatch}`, {
    ...config,
    params,
  });
}

export async function downloadCSVDetections(filters: DetectionsFilterParams) {
  const params: DetectionsBatchQueryParams = {
    ...(filters || {}),
  };
  const response = await client.get<Blob>(ApiEndpoints.DetectionsExport, {
    params,
  });
  blobDownload(response.data, "traffic-log.csv");
}

export function createImageUrl(id: string) {
  return `${backendRootUrl}${
    ApiEndpoints.DetectionsImages
  }/${id}?token=${getToken()}`;
}

export type DetectionsFilterParams = {
  fromDate?: string | Date | null;
  toDate?: string | Date | null;
  plateText?: string;
  lanes?: string[];
  lowConfidence?: boolean;
  orphan?: boolean;
};

type DetectionsBatchQueryParams = Partial<DetectionsFilterParams> & {
  limit?: number;
  beforeId?: string;
};
