import { isTest } from "./nodeEnv";

export const port: number = Number(process.env.PORT) || 5000;
export const cleanUpTaskDetectionsTtlDays = Number(
  process.env.DETECTIONS_TTL_DAYS || 365
);
export const wsStreamUrl = process.env.WS_STREAM_URL;

export const accessTokenSecret =
  process.env.JWT_SECRET || "development-access-token-secret";

export const jwtPublicKey =
  process.env.ANPR_SERVICE_JWT_PUBLIC_KEY ||
  process.env.ANPR_RECOGNITION_JWT_PUBLIC_KEY ||
  "";
export const jwtPrivateKey = process.env.ANPR_SERVICE_JWT_PRIVATE_KEY || "";
if (process.env.ANPR_RECOGNITION_JWT_PUBLIC_KEY)
  console.warn(
    "ANPR_RECOGNITION_JWT_PUBLIC_KEY is deprecated, define ANPR_SERVICE_JWT_PUBLIC_KEY instead"
  );

export const awsRegion = process.env.AWS_REGION || "eu-central-1";
export const awsDetectionsS3Bucket =
  process.env.AWS_DETECTIONS_IMAGES_S3_BUCKET;
export const awsLogGroup = process.env.AWS_LOG_GROUP;

export const anprEdgesAwsAccessKeyId =
  process.env.ANPR_EDGES_AWS_ACCESS_KEY_ID || "";
export const anprEdgesAwsSecretAccessKey =
  process.env.ANPR_EDGES_AWS_SECRET_ACCESS_KEY || "";
export const anprEdgesApiUrl = process.env.ANPR_EDGES_API_URL;
export const hallId = process.env.HALL_ID;
export const hallName = process.env.HALL_NAME;
export const hallCountryCode = (
  process.env.HALL_COUNTRY_CODE || ""
).toUpperCase();
export const operatorId = process.env.OPERATOR_ID;

export const useAsantech = Boolean(Number(process.env.USE_ASANTECH || 1));
export const authUsername = process.env.AUTH_USER;
export const authPassword = process.env.AUTH_PASSWORD;

export const asantechApiRootUrl = process.env.ASANTECH_API_ROOT_URL || "";
export const asantechUsername = process.env.ASANTECH_API_USER;
export const asantechApiPassword = process.env.ASANTECH_API_PASSWORD;
export const mockAsantechClient = Boolean(Number(process.env.MOCK_ASANTECH));
export const mockVehiclesPerHour =
  mockAsantechClient && Number(process.env.MOCKED_VEHICLES_PER_HOUR);

export const snbApiUsername = process.env.SNB_API_USERNAME || "";
export const snbApiPassword = process.env.SNB_API_PASSWORD || "";

export const plateTechImagesRootDir = process.env.PLATETECH_IMG_ROOT_DIR || "";

export const platetechEnabled = Boolean(Number(process.env.PLATETECH_ENABLED));

export const snbEnabled = Boolean(Number(process.env.SNB_ENABLED));
export const taskuparkkiEnabled = Boolean(
  Number(process.env.TASKUPARKKI_ENABLED)
);
export const snb3rdPartyEnabled = Boolean(
  Number(process.env.SNB_3RD_PARTY_ENABLED)
);
export const infoDisplayEnabled = Boolean(
  Number(process.env.INFO_DISPLAY_ENABLED)
);

export const infoDisplayAuthorizedIps =
  process.env.INFO_DISPLAY_AUTHORIZED_IPS?.split(",") || ["127.0.0.1"];

export const [parkingLogicListenHost, parkingLogicListenPort] = (() => {
  const parts = (process.env.PARKING_LOGIC_LISTEN_ON || ":").split(":", 2);
  return [parts[0], Number(parts[1])];
})();
export const parkingLogicEnabled =
  Boolean(parkingLogicListenPort) && Boolean(parkingLogicListenHost);
export const diagnosticsCloudwatchEnabled = Boolean(
  Number(process.env.DIAGNOSTICS_CLOUDWATCH_ENABLED)
);
export const parkingLogicProxyEnabled = Boolean(
  process.env.PARKING_LOGIC_PROXY_TARGET
);
export const parkingLogicProxyTarget = process.env.PARKING_LOGIC_PROXY_TARGET;

export const diagnosticsCloudwatchNamespace =
  process.env.DIAGNOSTICS_CLOUDWATCH_NAMESPACE || "";

export const postNotifierEnabled = Boolean(
  Number(process.env.POST_NOTIFIER_ENABLED)
);

export const edgesNotifierEnabled = Boolean(
  Number(process.env.ANPR_EDGES_NOTIFIER_ENABLED)
);

export const recognitionClientsMonitoringTimespanMins = Number(
  process.env.RECOGNITION_CLIENTS_MONITORING_TIMESPAN_MINS || "5"
);

if (isTest && !process.env.TEST_CONFIG_APPLIED)
  throw new Error("Config was imported before test config was applied.");

export const cleanUpMaxTotalImagesDiskUsage = !isNaN(
  Number(process.env.CLEANUP_MAX_TOTAL_IMAGES_MB)
)
  ? Number(process.env.CLEANUP_MAX_TOTAL_IMAGES_MB)
  : undefined;

export const cleanUpMaxAppliedImagesDiskUsage = !isNaN(
  Number(process.env.CLEANUP_MAX_APPLIED_IMAGES_MB)
)
  ? Number(process.env.CLEANUP_MAX_APPLIED_IMAGES_MB)
  : undefined;

export const dbReplicationUser = process.env.DB_REPLICATION_USER || "repuser";
export const dbReplicationPassword = process.env.DB_REPLICATION_PASSWORD || "";
export const nodeNumber = Number(process.env.NODE_NUMBER || "1");
export const nodeUrls = (process.env.NODE_URLS || "")
  .split(",")
  .map((url) => url.trim())
  .filter((url) => !!url);

export const nodeAliveThresholdSeconds = Number(
  process.env.NODE_ALIVE_THRESHOLD_SECONDS || "30"
);
export const nodeScheduledReboot = Boolean(
  Number(process.env.NODE_SCHEDULED_REBOOT)
);
export const uvpDbConnectionString = process.env.UVP_DB_CONNECTION_STRING;

export const thingName = process.env.AWS_IOT_THING_NAME || "";

export const backendDisabled = Boolean(Number(process.env.BACKEND_DISABLED));

export const disableCloudwatchLogs = Boolean(
  Number(process.env.DISABLE_CLOUDWATCH_LOGS)
);

export const disableSymbolBasedMatching = Boolean(
  Number(process.env.DISABLE_SYMBOL_BASED_MATCHING)
);
