import { useToggle } from "@react-hookz/web";
import { handleError } from "api/client";
import { deleteDoor as deleteDoorRequest } from "api/doors";
import {
  Button,
  Loader,
  TooltipDialog,
  TooltipDialogContainer,
  TooltipDialogContent,
  TooltipDialogFooter,
} from "components";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDoors } from "store/Doors.context";
import { useLanes } from "store/Lanes.context";
import styled from "styled-components";
import { DoorSidebar } from "../DoorSidebar";
import { ButtonWrapper, PanelCenter, PanelLeft } from "../LanesView";
import { DoorName } from "./DoorName";

export const DoorsView = () => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [isAdding, toggleIsAdding] = useToggle(false);
  const [showDeleteDialog, toggleDeleteDialog] = useToggle(false);
  const { doFetchDevices, deviceFetchState } = useLanes();
  const { doors, deleteDoor, selectDoor, selectedDoorId, doFetchDoors } =
    useDoors();
  const handleDoorClick = useCallback(
    (laneId: string) => {
      toggleIsAdding(false);
      selectDoor((prevLaneId) => (laneId === prevLaneId ? undefined : laneId));
    },
    [selectDoor, toggleIsAdding]
  );

  const handleDoorDelete = useCallback(async () => {
    if (!selectedDoorId) return;

    try {
      setIsDeleting(true);
      await deleteDoorRequest(selectedDoorId);
      deleteDoor(selectedDoorId);
      selectDoor(undefined);
      toast.success("Door was deleted successfully.");
    } catch (error) {
      handleError(error, "Error deleting door.");
    }

    setIsDeleting(false);
    toggleDeleteDialog(false);
  }, [deleteDoor, selectDoor, selectedDoorId, toggleDeleteDialog]);

  const handleDoorAdd = useCallback(async () => {
    toggleIsAdding(true);
    selectDoor(undefined);
  }, [selectDoor, toggleIsAdding]);

  const handleToggleDeleteDialog = useCallback(() => {
    toggleDeleteDialog();
  }, [toggleDeleteDialog]);

  useEffect(() => {
    doFetchDevices();
    doFetchDoors();
  }, [doFetchDevices, doFetchDoors]);

  if (
    deviceFetchState.status === "not-executed" ||
    deviceFetchState.status === "loading"
  ) {
    return <Loader />;
  }
  if (deviceFetchState.error) return null;

  return (
    <Panels>
      <PanelLeft>
        <ButtonWrapper>
          <Button size="large" onClick={handleDoorAdd}>
            Add a door
          </Button>
          <TooltipDialog
            id="delete-door-dialog"
            isOpen={showDeleteDialog}
            onDismiss={toggleDeleteDialog}
            content={
              <TooltipDialogContainer>
                <TooltipDialogContent>
                  Are you sure you want
                  <br /> to delete this door?
                </TooltipDialogContent>
                <TooltipDialogFooter>
                  <Button disabled={isDeleting} onClick={handleDoorDelete}>
                    Delete
                  </Button>
                  <Button
                    disabled={isDeleting}
                    onClick={handleToggleDeleteDialog}
                  >
                    Don&apos;t
                  </Button>
                </TooltipDialogFooter>
              </TooltipDialogContainer>
            }
          >
            <Button
              variant="error"
              disabled={isDeleting || !selectedDoorId}
              onClick={handleToggleDeleteDialog}
              size="largish"
            >
              Delete
            </Button>
          </TooltipDialog>
        </ButtonWrapper>
        <DoorNames>
          {doors.map((door) => (
            <DoorName
              key={door.id}
              door={door}
              isSelected={door.id === selectedDoorId}
              onClick={handleDoorClick}
            />
          ))}
        </DoorNames>
      </PanelLeft>
      <PanelCenter>
        {selectedDoorId || isAdding ? (
          <DoorSidebar
            key={selectedDoorId}
            addMode={isAdding}
            toggleAddMode={toggleIsAdding}
            selectedDoorId={selectedDoorId}
          />
        ) : (
          <EmptySidebarMessage>
            <span>Select a door to view details</span>
          </EmptySidebarMessage>
        )}
      </PanelCenter>
    </Panels>
  );
};

const Panels = styled.div`
  display: flex;
  height: 100%;
`;

const EmptySidebarMessage = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    margin-bottom: 282px;
    color: #949498;
  }
`;

const DoorNames = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;
  flex: 1;
`;
