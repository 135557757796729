import { openApiLaneId, openApiUvpRtspDeviceId } from "../lanes/types";
import { isTest } from "../nodeEnv";

export type RectCorners = [number, number, number, number];

export type Direction = "in" | "out";

export enum OrphanReason {
  SameDirectionTwice = "SAME_DIRECTION_TWICE",
  WrongDirection = "WRONG_DIRECTION",
  DirectionNotDetected = "DIRECTION_NOT_DETECTED",
  NoExitForTooLong = "NO_EXIT_FOR_TOO_LONG",
  ExitWithoutEntrance = "EXIT_WITHOUT_ENTRANCE",
  InternalAreaDetectionMismatch = "INTERNAL_AREA_DETECTIONS_MISMATCH",
}

export const OrphanReasonMessage = {
  [OrphanReason.SameDirectionTwice]:
    "Detected driving the same direction twice in a row",
  [OrphanReason.WrongDirection]: "Detected driving the wrong direction",
  [OrphanReason.DirectionNotDetected]: "Driving direction not detected",
  [OrphanReason.NoExitForTooLong]:
    "Detected entering but no exit for more than 24 hours",
  [OrphanReason.ExitWithoutEntrance]: "Detected exiting but no entrance before",
  [OrphanReason.InternalAreaDetectionMismatch]:
    "Hall entrance or exit doesn't match internal area driving direction",
};

export const NOTIFIER_MAX_RESPONSE_TIME_MS = isTest ? 300 : 3000;
export const TRACK_DELETED_EVENT_TIMEOUT_MS = isTest ? 70 : 70000;

/**
 * A single vehicle that has been detected entering or exiting the parking hall.
 */
export type DetectionDb = {
  id: string;
  laneId: string;
  vehicleId: string;
  detectionDate: Date;
  plateText: string;
  plateCountry: string;
  regionCode?: string;
  lowConfidenceSymbols?: boolean;
  lowConfidenceCountry?: boolean;
  plateTextConfidence: number;
  plateCountryConfidence: number;
  insertOrder: number;
  appliedTextDetectionImageId: string | null;
  appliedCountryDetectionImageId: string | null;
  inconsistent: boolean;
  imageUploaded?: boolean;
  symbols: string;
  symbolDetails: { symbol: string; lowConfidence: boolean }[];
  duplicateOf: string | null;
  prevDetectionId?: string | null;
  outVehicleId?: string | null;
  madeOrphanByVehicleId?: string | null;
};

/**
 * Data about detection image without the image itself.
 */
export type Thumbnail = Pick<
  DetectionImageDb,
  | "id"
  | "plateArea"
  | "plateText"
  | "plateTextConfidence"
  | "plateCountry"
  | "detectionDate"
  | "deviceId"
>;

/**
 * Detection with plate detection images and direction detection.
 */
export type DetectionWithParts = DetectionDb & {
  direction: Direction | null;
  thumbnails: Thumbnail[];
  sentExternalNotifications?: {
    sentDetectionImageId: string;
    sentDate: Date;
    sentPlateText: string;
    sentPlateCountry: string;
  }[];
  orphanReasons?: OrphanReason[];
};

export type DetectionsWithPartsBatch = {
  detections: DetectionWithParts[];
  count: number;
};

export type DetectionWithThumbnails = DetectionDb & {
  thumbnails: Thumbnail[];
};

export type DetectionThumbnail = Omit<Thumbnail, "detectionDate"> & {
  detectionDate: string;
};
export type ExternalNotifications = {
  sentDetectionImageId: string;
  sentDate: string;
  sentPlateText: string;
  sentPlateCountry: string;
  notifiers: string[];
};
/**
 * Detection as returned by the API.
 */
export type Detection = Omit<DetectionDb, "detectionDate"> & {
  detectionDate: string;
  direction: Direction | null;
  thumbnails: DetectionThumbnail[];
  sentExternalNotifications: ExternalNotifications[];
  orphanReasons?: OrphanReason[];
};

export type DetectionsBatch = {
  detections: Detection[];
  count: number;
};

export type DetectionMessage = {
  type: "detection";
  payload: Detection;
};

export type DirectionMessage = {
  type: "detection_direction";
  payload: Omit<DirectionDb, "appliedAt" | "lastExitedAt"> & {
    appliedAt: string;
    lastExitedAt?: string | null;
  };
};

export type SystemStatusMessage = {
  type: "system_status";
  payload: { message: string };
};

export type DirectionDb = {
  id: string;
  direction: Direction;
  appliedAt: Date;
  lastExitedAt: Date;
  vehicleId: string;
  directionConfidence: number;
  lowConfidenceDirection: boolean;
  exitsVideoSources: string[];
};

export type ExternalNotificationMessage = {
  type: "external_notification";
  payload: ExternalNotifications;
};

/**
 * WebSocket messages as returned by the API
 */
export type WebSocketMessage =
  | DetectionMessage
  | DirectionMessage
  | SystemStatusMessage
  | ExternalNotificationMessage;

export type InsertDetectionInput = {
  laneId: string;
  vehicleId: string;
  detectionDate: Date;
  plateText?: string;
  plateCountry?: string;
  regionCode?: string;
  plateTextConfidence?: number;
  plateCountryConfidence?: number;
  lowConfidenceSymbols?: boolean;
  lowConfidenceCountry?: boolean;
  appliedTextDetectionImageId?: null;
  appliedCountryDetectionImageId?: null;
  duplicateOf?: string | null;
  symbols?: string;
  symbolDetails: string;
};

export type DetectionInputDefaultFields =
  | "plateText"
  | "plateCountry"
  | "plateTextConfidence"
  | "plateCountryConfidence"
  | "symbols";

export type CreateDetectionInput = Omit<
  InsertDetectionInput,
  "symbolDetails"
> & {
  thumbnail: string;
  deviceId?: string;
  xyxy?: RectCorners;
  symbolDetails: { symbol: string; lowConfidence: boolean }[];
};

export enum MovingDirection {
  ExitedTowards = "exited-towards",
  ExitedAway = "exited-away",
  MovingTowards = "moving-towards",
  MovingAway = "moving-away",
}

export interface SetDirectionInput {
  vehicleId: string;
  detectionDate: Date;
  direction: MovingDirection;
  directionConfidence: number;
  deviceId: string;
  laneId: string;
  lowConfidenceDirection: boolean;
}

export interface TrackEventInput {
  vehicleId: string;
}

export type CreateDetectionRequest = Omit<
  CreateDetectionInput,
  "detectionDate"
> & {
  detectionDate: string;
};

export type DetectionImageDb = {
  detectionId: string;
  image: Buffer;
  id: string;
  plateArea?: RectCorners;
  deviceId?: string;
  plateText: string;
  plateTextConfidence: number;
  detectionDate: Date;
  plateCountry: string;
};

export type InsertDetectionImageInput = {
  detectionId: string;
  image: Buffer;
  plateArea?: RectCorners;
  deviceId?: string;
  plateText: string;
  plateTextConfidence: number;
  detectionDate: Date;
  plateCountry: string;
};

export type NotifyExternalPayload = {
  id: string;
  laneId: string | number;
  timestamp: string;
  plate: string;
  countryCode: string;
  imageData: string;
  confidence: number;
  parkingHallId: string | number;
};

export type CleanupConfig = {
  detectionsTtlDays: number;
  maxTotalImagesDiskUsage?: number;
  maxAppliedImagesDiskUsage?: number;
};

export type LastDetectionBeforeMigration = {
  migrationName: "add_symbol_details_to_detections";
  insertOrder: number;
};

export type VehicleTrackDb = {
  vehicleId: string;
  updatedAt: Date;
};

const openApiPlateArea = {
  type: "array",
  minLength: 4,
  maxLength: 4,
  description:
    "License plate thumbnail top-left and bottom-right absolute coordinates counted from top left corner",
  items: {
    type: "number",
  },
};

export const openApiDetection = {
  type: "object",
  properties: {
    id: {
      type: "string",
      format: "uuid",
      description: "Universally unique ID.",
    },
    laneId: {
      type: "string",
      format: "uuid",
      description: "UUID of the lane where detection happened.",
    },
    detectionDate: {
      type: "string",
      format: "date-time",
      description: "Timestamp of detection",
    },
    plateText: {
      type: "string",
    },
    plateCountry: {
      type: "string",
    },
    orphanReasons: {
      type: "array",
      items: {
        type: "string",
      },
    },
    thumbnails: {
      type: "array",
      items: {
        type: "object",
        description: "Thumbnail image",
        properties: {
          id: {
            type: "string",
            description: "Thumbnail image id",
          },
          plateArea: {
            ...openApiPlateArea,
            nullable: true,
          },
        },
      },
    },
    sentExternalNotifications: {
      type: "array",
      items: {
        type: "object",
        properties: {
          sentDetectionImageId: {
            type: "string",
            description: "Detection image id sent to external system",
          },
          sentDate: {
            type: "string",
            description: "Timestamp of sent notification",
          },
          sentPlateText: {
            type: "string",
            description: "Plate text that was sent to external system",
          },
          sentPlateCountry: {
            type: "string",
            description: "Plate country that was sent to external system",
          },
        },
      },
    },
    plateTextConfidence: {
      type: "number",
      minimum: 0,
      maximum: 1,
    },
    plateCountryConfidence: {
      type: "number",
      minimum: 0,
      maximum: 1,
    },
    insertOrder: {
      type: "number",
    },
    appliedTextDetectionImageId: {
      type: "string",
      nullable: true,
      description: "ID of the thumbnail that was used to determine plate text",
    },
    appliedCountryDetectionImageId: {
      type: "string",
      nullable: true,
      description:
        "ID of the thumbnail that was used to determine plate country",
    },
  },
};

export const openApiSetDirectionInput = {
  type: "object",
  additionalProperties: false,
  properties: {
    vehicleId: {
      type: "string",
      description:
        "ID that identifies the vehicle that the plate belongs to (same vehicle at different times can still get a different ID).",
    },
    detectionDate: {
      type: "string",
      format: "date-time",
      description: "Timestamp of detection",
    },
    direction: {
      type: "string",
      enum: Object.values(MovingDirection),
    },
    directionConfidence: {
      type: "number",
      minimum: 0,
      maximum: 1,
    },
    deviceId: openApiUvpRtspDeviceId,
    laneId: {
      ...openApiLaneId,
      description: "UUID of the lane where detection happened.",
    },
    lowConfidenceDirection: {
      type: "boolean",
    },
  },
};

export const openApiTrackEventInput = {
  type: "object",
  additionalProperties: false,
  required: ["vehicleId"],
  properties: {
    vehicleId: {
      type: "string",
      description:
        "ID that identifies the vehicle that the plate belongs to (same vehicle at different times can still get a different ID).",
    },
  },
};

export const openApiCreateDetectionInput = {
  type: "object",
  additionalProperties: false,
  required: ["laneId", "vehicleId", "detectionDate", "thumbnail"],
  properties: {
    laneId: {
      ...openApiLaneId,
      description: "UUID of the lane where detection happened.",
    },
    vehicleId: {
      type: "string",
      description:
        "ID that identifies the vehicle that the plate belongs to (same vehicle at different times can still get a different ID). Detections with the same vehicleId are combined to one detection with multiple images.",
    },
    detectionDate: {
      type: "string",
      format: "date-time",
      description: "Timestamp of detection",
    },
    plateText: {
      type: "string",
    },
    symbols: {
      type: "string",
      description: "plateText before substitutions that layout causes",
    },
    symbolDetails: {
      type: "array",
      description: "lowConfidence per symbol",
      items: {
        type: "object",
        required: ["symbol", "lowConfidence"],
        properties: {
          symbol: {
            type: "string",
            minLength: 1,
            maxLength: 1,
          },
          lowConfidence: { type: "boolean" },
        },
      },
    },
    plateTextConfidence: {
      type: "number",
      minimum: 0,
      maximum: 1,
    },
    plateCountry: {
      type: "string",
    },
    plateCountryConfidence: {
      type: "number",
      minimum: 0,
      maximum: 1,
    },
    lowConfidenceSymbols: {
      type: "boolean",
    },
    lowConfidenceCountry: {
      type: "boolean",
    },
    regionCode: {
      type: "string",
      nullable: true,
      minLength: 1,
      description:
        "Plate region for when country and text together are not unique.",
    },
    thumbnail: {
      type: "string",
      description: "base64 encoded png",
      format: "base64-image",
    },
    xyxy: {
      ...openApiPlateArea,
      nullable: true,
    },
    deviceId: openApiUvpRtspDeviceId,
  },
};
