import { useDebouncedEffect } from "@react-hookz/web";
import { ReactComponent as SearchIcon } from "assets/svgs/search-icon.svg";
import { TextFieldInput } from "components";
import React, { useState } from "react";
import styled from "styled-components";

interface PlateFilterFieldProps {
  onSearch: (searchTerm: string) => void;
}

export const PlateFilterField: React.FC<PlateFilterFieldProps> = ({
  onSearch,
}): JSX.Element => {
  const [searchTerm, setSearchTerm] = useState("");

  useDebouncedEffect(
    () => {
      onSearch(searchTerm.toUpperCase());
    },
    [searchTerm],
    400
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.currentTarget.value.replace(/ /g, ""));
  };

  return (
    <InputContainer>
      <SearchIcon title={"Search vehicle registration"} />
      <TextFieldInput
        variant="tertiary"
        invalid={false}
        name="search_term"
        placeholder="Search vehicle registration..."
        onChange={handleChange}
      />
    </InputContainer>
  );
};

const InputContainer = styled.div`
  width: auto;
  position: relative;
  display: flex;
  align-items: center;
  & > input {
    font-style: italic;
    padding-left: 40px;
  }

  & > svg {
    position: absolute;
    left: 16px;
  }
`;
