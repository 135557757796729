enum localStorageKeys {
  filterActiveLaneIds = "filterActiveLaneIds",
}

export const setFilterActiveLaneIds = (laneIds: string[]) => {
  localStorage.setItem(localStorageKeys.filterActiveLaneIds, laneIds.join(","));
};
export const getActiveLanesLocalStorage = () => {
  const laneIds = localStorage.getItem(localStorageKeys.filterActiveLaneIds);
  return laneIds ? laneIds.split(",") : [];
};
