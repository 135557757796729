import { useToggle } from "@react-hookz/web";
import { AxiosError } from "api/client";
import { createDoor, updateDoor as updateDoorRequest } from "api/doors";
import { ReactComponent as InfoIcon } from "assets/svgs/alert-info-icon.svg";
import { handleFormSubmitError, nameValidator } from "common/formUtils";
import {
  Card,
  CardColumn,
  CardRow,
  CardSubtitle,
  FieldErrorComponent,
  FormSidebar,
  TextField,
  TooltipDialog,
} from "components";
import { Body } from "components/FormSidebar";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useDoors } from "store/Doors.context";
import styled from "styled-components";
import { DoorDb } from "../../../../../backend/src/doors/types";
import { Form } from "../Form";
import { TriggerMappingSelect } from "../TriggerMappingSelect/TriggerMappingSelect";

const newDoor = {
  name: "",
  deviceId: "",
  triggerId: "",
};

const formDataToDoor = (formData: DoorDb) => {
  return { ...formData };
};

export const DoorSidebar = (props: {
  addMode: boolean;
  toggleAddMode: (addMode?: boolean) => void;
  selectedDoorId?: string;
}) => {
  const { addMode, toggleAddMode, selectedDoorId } = props;
  const { doors, selectDoor, addDoor, updateDoor } = useDoors();

  const [doorEditMode, toggleEditMode] = useToggle(false);

  useEffect(() => {
    toggleEditMode(false);
  }, [selectedDoorId, toggleEditMode]);

  const [updatedTriggerMapping, setUpdatedTriggerMapping] = useState<{
    deviceId: string;
    triggerId: string;
  } | null>();

  const editMode = addMode || doorEditMode;

  const selectedDoor = useMemo(
    () => doors.find((door) => door.id === selectedDoorId) || { ...newDoor },
    [doors, selectedDoorId]
  );
  const {
    formState: { errors, isValid },
    handleSubmit,
    register,
    reset,
    setError,
  } = useForm<DoorDb>({
    defaultValues: selectedDoor,
    mode: "all",
  });

  const handleFormSubmit = useCallback(
    (toggleEditMode: (isEdit: boolean) => void) =>
      handleSubmit(async (formData) => {
        try {
          const data = formDataToDoor(formData);
          if (updatedTriggerMapping === null) {
            data.deviceId = "";
            data.triggerId = "";
          } else if (updatedTriggerMapping) {
            data.deviceId = updatedTriggerMapping.deviceId;
            data.triggerId = updatedTriggerMapping.triggerId;
          }

          const response = await (selectedDoorId
            ? updateDoorRequest(selectedDoorId, data)
            : createDoor(data));
          selectedDoorId ? updateDoor(response.data) : addDoor(response.data);

          if (!selectedDoorId) selectDoor(response.data.id);

          toast.success("Door configuration updated succesfully");
          toggleAddMode(false);
          toggleEditMode(false);
        } catch (error) {
          const reason = (error as AxiosError<{ error?: string }>).response
            ?.data?.error;
          handleFormSubmitError(error, setError, reason);
          return false;
        }
      })(),
    [
      handleSubmit,
      selectedDoorId,
      updateDoor,
      addDoor,
      selectDoor,
      updatedTriggerMapping,
      toggleAddMode,
      setError,
    ]
  );

  const handleFormEditCancel = useCallback(() => {
    reset(selectedDoor);
    setUpdatedTriggerMapping(undefined);
  }, [reset, selectedDoor]);

  const { deviceId, triggerId } = selectedDoor;
  const triggerMappingSelectedDoor =
    deviceId && triggerId ? { deviceId, triggerId } : undefined;

  return (
    <FormWrapper
      title={
        editMode
          ? `${addMode ? "Adding a new" : "Editing"} door`
          : "Door settings"
      }
      secondaryTitle={editMode ? "" : "Configuration for selected door"}
      onCancel={handleFormEditCancel}
      onSubmit={handleFormSubmit}
      editMode={editMode}
      toggleEditMode={toggleEditMode}
      toggleAddMode={toggleAddMode}
      size="largish"
      hasErrors={!isValid}
    >
      <Form>
        <Card size="largish">
          <CardRow>
            <CardColumn>
              <CardSubtitle>Door name</CardSubtitle>
              <TextField
                register={register}
                name="name"
                registerRules={nameValidator()}
                required={true}
                placeholder="Insert name"
                variant="secondary"
                size="largish"
                errors={errors.name}
              />
              <FieldErrorComponent error={errors.name} />
            </CardColumn>
          </CardRow>
        </Card>

        <Header>
          Door configuration
          <TooltipDialog
            id="gate-config-tooltip"
            content={
              <span>
                Select a UVP device trigger <br /> to be fired when a door open
                message is received
              </span>
            }
          >
            <StyledInfoIcon />
          </TooltipDialog>
        </Header>
        <Card size="largish">
          <TriggerMappingSelect
            key={`${editMode}${selectedDoorId}`}
            value={
              updatedTriggerMapping === null
                ? undefined
                : updatedTriggerMapping || triggerMappingSelectedDoor
            }
            onChange={setUpdatedTriggerMapping}
          />
        </Card>
      </Form>
    </FormWrapper>
  );
};

const FormWrapper = styled(FormSidebar)`
  ${Body} {
    padding: 30px;
    padding-bottom: 10px;
  }

  header {
    padding: 20px 30px;
    height: auto;
    font-size: 16px;
  }

  h4 {
    font-size: 18px;
    line-height: 25px;
  }

  .react-tooltip {
    font-size: 16px;
  }
`;

const Header = styled.div`
  display: flex;
  margin-bottom: 20px;
  margin-top: 5px;
  font-size: 16px;
`;
const StyledInfoIcon = styled(InfoIcon)`
  margin: 0 10px;
  position: relative;
  top: 1px;
  width: 16px;
  height: 16px;
`;
