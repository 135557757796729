import { handleError } from "api/client";
import { getDeviceTriggers } from "../../../api/lanes";
import {
  AsantechDevice,
  DeviceTrigger,
} from "@asantech/common/asantech-client/types";

export async function fetchDeviceTriggers(deviceId: string) {
  try {
    const response = await getDeviceTriggers(deviceId);
    if (response.status !== 200) return [];
    return response.data;
  } catch (err) {
    handleError(err, `Failed to fetch triggers for device ${deviceId}`);
    return [];
  }
}
export function createDeviceDropDownOptions(
  devices: AsantechDevice[]
): Array<{ label: string; value: string }> {
  return devices.map((device) => ({
    label: device.deviceName,
    value: device.deviceId,
  }));
}

export function createTriggerDropDownOptions(
  deviceTriggers: DeviceTrigger[]
): Array<{ label: string; value: string }> {
  if (!deviceTriggers) return [];

  return deviceTriggers.map((trigger) => ({
    label: trigger.triggerName,
    value: trigger.triggerId,
  }));
}
