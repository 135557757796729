import { CarPlatePlain } from "@asantech/common/react/CarPlate";
import { ImagesCarousel } from "@asantech/common/react/ImagesCarousel";
import { ZoomableImage } from "@asantech/common/react/ZoomableImage";
import client from "api/client";
import { moveElToFirst } from "common/utils";
import { Timestamp } from "components";
import React, { SyntheticEvent, useCallback, useMemo } from "react";
import styled from "styled-components";
import {
  Direction,
  OrphanReason,
} from "../../../../../backend/src/detections/types";
import { LaneDirection } from "../../../../../backend/src/lanes/types";
import { Detection } from "../../../common/utils/detections";
import { useLanes } from "../../../store/Lanes.context";
import { MainTitleButtons } from "./MainTitleButtons";

export interface Props {
  id: string;
  orphanReasons?: OrphanReason[];
  plateText: string;
  plateCountry: string;
  regionCode?: string | null;
  gate: string;
  date: string;
  thumbnails: Detection["thumbnails"];
  laneDirection: LaneDirection;
  direction: Direction | null;
  plateTextConfidence: number;
  plateCountryConfidence: number;
  lowConfidenceAlertText: string | null | undefined;
  appliedTextDetectionImageId: string | null;
  appliedCountryDetectionImageId: string | null;
  selectedImage: number;
  setSelectedImage: (fn: (prev: number) => number) => void;
}
const ColumnCard: React.FC<Props> = ({
  orphanReasons,
  plateText,
  plateCountry,
  regionCode,
  gate,
  date,
  thumbnails,
  laneDirection,
  direction,
  lowConfidenceAlertText,
  appliedTextDetectionImageId,
  appliedCountryDetectionImageId,
  selectedImage,
  setSelectedImage,
}) => {
  const { getDeviceById } = useLanes();
  const onImgLoadError = useCallback((e: SyntheticEvent<HTMLImageElement>) => {
    client.get(e.currentTarget.src).catch(() => undefined);
  }, []);

  const sortedThumbnails = useMemo(() => {
    const sorted = [...thumbnails];

    moveElToFirst(sorted, (t) => t.id === appliedCountryDetectionImageId);
    moveElToFirst(sorted, (t) => t.id === appliedTextDetectionImageId);

    return sorted;
  }, [thumbnails, appliedTextDetectionImageId, appliedCountryDetectionImageId]);

  const getImageTitleEl = useCallback(
    (_: number, allImagesAmount: number) => {
      const deviceId = sortedThumbnails[selectedImage].deviceId;
      const deviceName = deviceId && getDeviceById(deviceId)?.deviceName;

      return (
        <ImageTitle>{`${selectedImage + 1} of ${allImagesAmount} ${
          deviceName ? `: ${deviceName}` : ""
        }`}</ImageTitle>
      );
    },
    [getDeviceById, selectedImage, sortedThumbnails]
  );
  return (
    <Wrapper>
      <ImagesCarousel
        selectedImage={selectedImage}
        setSelectedImage={setSelectedImage}
        getTitleElement={getImageTitleEl}
        bottomBarArea={
          <MainTitleButtons
            orphanReasons={orphanReasons}
            lowConfidenceAlertText={lowConfidenceAlertText}
            laneDirection={laneDirection}
            direction={direction}
          />
        }
      >
        {sortedThumbnails.map(({ src, plateArea }, index) => (
          <ZoomableImage
            height={190}
            key={index}
            src={src}
            alt={plateText}
            zoomArea={plateArea}
            onImgLoadError={onImgLoadError}
          />
        ))}
      </ImagesCarousel>
      <ContentWrapper>
        <PlateText>
          <CarPlatePlain
            text={plateText}
            country={plateCountry}
            regionCode={regionCode}
          />
        </PlateText>
        <Date>
          <Timestamp date={date} />
        </Date>
      </ContentWrapper>
      <GateName>{gate}</GateName>
    </Wrapper>
  );
};

export const LaneColumnCard = React.memo(ColumnCard);

const Wrapper = styled.div`
  display: block;
  padding: 57px 16px 41px;
`;

const PlateText = styled.span`
  color: var(--text-primary);
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  text-transform: uppercase;
  display: inline-block;
`;

const GateName = styled.span`
  display: block;
  font-size: 14px;
  font-weight: 400;
  color: var(--text-secondary);
`;

const Date = styled.span`
  display: block;
  font-size: 12px;
  font-weight: 400;
  color: var(--text-primary);
  text-align: right;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
`;

const ImageTitle = styled.div`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;
