import { SocketContextProvider } from "common/hooks/useInfoDisplaySocket";
import { Pages } from "common/pages";
import { AnonOnlyRoute, PrivateRoute } from "components";
import { InfoDisplayView } from "pages/infoDisplay";
import { LanesView } from "pages/lanes";
import { LoginView } from "pages/login";
import { SettingsView } from "pages/settings";
import { TrafficAuditLogView } from "pages/traffic";
import React from "react";
import { Route, Router, Switch } from "react-router-dom";
import { DetectionsContextProvider } from "store/detections/Detections.context";
import { DoorsContextProvider } from "store/Doors.context";
import { LanesContextProvider } from "store/Lanes.context";
import { history } from "./history";

export const AppRouter: React.FC = () => {
  return (
    <Router history={history}>
      <Switch>
        <AnonOnlyRoute redirectLoggedIn={Pages.Home} path={Pages.Login}>
          <LoginView />
        </AnonOnlyRoute>
        <PrivateRoute path={Pages.Home} exact>
          <LanesContextProvider>
            <DetectionsContextProvider>
              <LanesView />
            </DetectionsContextProvider>
          </LanesContextProvider>
        </PrivateRoute>
        <PrivateRoute path={Pages.Settings}>
          <LanesContextProvider>
            <DoorsContextProvider>
              <SettingsView />
            </DoorsContextProvider>
          </LanesContextProvider>
        </PrivateRoute>
        <PrivateRoute path={Pages.Traffic}>
          <LanesContextProvider>
            <DetectionsContextProvider>
              <TrafficAuditLogView />
            </DetectionsContextProvider>
          </LanesContextProvider>
        </PrivateRoute>
        <Route path={Pages.InfoDisplay}>
          <SocketContextProvider>
            <InfoDisplayView />
          </SocketContextProvider>
        </Route>
      </Switch>
    </Router>
  );
};
