import {
  DevicesResponse,
  TriggersResponse,
} from "@asantech/common/asantech-client";
import client, { AxiosRequestConfig } from "api/client";
import { ApiEndpoints } from "api/endpoints";
import {
  Lane,
  LaneInput,
  LaneStats,
  TriggerMapping,
  TriggerMappingInput,
} from "../../../backend/src/lanes/types";

export function getLanes() {
  return client.get<Lane[]>(ApiEndpoints.Lanes);
}

export function getLanesStats(fromDate: Date) {
  const queryParams = `?fromDate=${encodeURIComponent(fromDate.toISOString())}`;
  return client.get<LaneStats[]>(`${ApiEndpoints.LanesStats}${queryParams}`);
}

export function getNotifiers() {
  return client.get<string[]>(ApiEndpoints.Notifiers);
}

export function getDevices() {
  return client.get<DevicesResponse>(ApiEndpoints.Devices);
}

export function getDeviceTriggers(deviceId: string) {
  return client.get<TriggersResponse>(
    `${ApiEndpoints.DeviceTriggers}?deviceId=${deviceId}`
  );
}

export async function setTriggerMapping(triggerMapping: TriggerMappingInput) {
  const response = await client.post<TriggerMapping>(
    ApiEndpoints.TriggerMapping,
    triggerMapping
  );
  return response.data;
}

export function removeTriggerMapping(triggerMappingId: string) {
  return client.delete<TriggerMapping>(
    `${ApiEndpoints.TriggerMapping}/${triggerMappingId}`
  );
}

export function getTriggerMapping(laneId: string) {
  return client.get<TriggerMapping>(
    `${ApiEndpoints.TriggerMapping}?laneId=${laneId}`
  );
}

export async function createLane(laneData: LaneInput) {
  return client.post<Lane>(ApiEndpoints.Lanes, laneData);
}

export async function deleteLane(laneId: string) {
  return client.delete(`${ApiEndpoints.Lanes}/${laneId}`);
}

export async function updateLane(laneId: string, laneData: LaneInput) {
  return client.patch<Lane>(`${ApiEndpoints.Lanes}/${laneId}`, laneData);
}

export async function updateLanesOrder(
  lanesOrder: string[],
  config: Partial<AxiosRequestConfig> = {}
) {
  return client.put<Lane>(ApiEndpoints.LanesOrder, lanesOrder, config);
}
