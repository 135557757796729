import { useAsync, useList } from "@react-hookz/web";

import { useEffect, useMemo } from "react";
import { toast } from "react-toastify";
import { Setting } from "../../../../backend/src/settings/types";
import { getSystemSettings, updateSystemSetting } from "../../api/settings";
import { keyBy } from "../utils";

export const useSystemSettings = () => {
  const [settings, { set, update }] = useList<Setting>([]);

  const [fetchSettingsState, { execute: fetchSettings }] = useAsync(
    async () => {
      try {
        const response = await getSystemSettings();
        set(response.data);
      } catch (e) {
        toast.error("Failed to fetch system settings.");
      }
    }
  );

  const [, { execute: updateSetting }] = useAsync(
    async (settingInput: Setting) => {
      try {
        await updateSystemSetting(settingInput);
        update(
          ({ codeName }) => codeName === settingInput.codeName,
          settingInput
        );
        toast.success("System setting has been saved.");
      } catch (e) {
        toast.error("Failed to save system settings.");
        console.error(e);
        throw e;
      }
    }
  );

  useEffect(() => {
    fetchSettings();
  }, [fetchSettings]);

  const settingsMap = useMemo(() => keyBy(settings, "codeName"), [settings]);
  return {
    settings,
    settingsMap,
    updateSetting,
    loaded: fetchSettingsState.status !== "loading",
  };
};
