import { ReactComponent as ArrowIcon } from "assets/svgs/arrow-icon.svg";
import { useScrollableMenu } from "common/hooks";
import React, { ReactNode } from "react";
import styled from "styled-components";

type Props = {
  scrollByValue: number;
  children: ReactNode;
};

export const ScrollablePane: React.FC<Props> = ({
  scrollByValue,
  children,
}) => {
  const {
    setScrollWrapper,
    isLeftArrowVisible,
    isRightArrowVisible,
    handleArrowLeftClick,
    handleArrowRightClick,
  } = useScrollableMenu(scrollByValue);

  return (
    <Wrapper>
      <LeftArrow
        onClick={handleArrowLeftClick}
        isVisible={isLeftArrowVisible}
        disabled={!isLeftArrowVisible}
      >
        <ArrowIcon />
      </LeftArrow>
      <ContentWrapper ref={setScrollWrapper}>{children}</ContentWrapper>
      <RightArrow
        onClick={handleArrowRightClick}
        isVisible={isRightArrowVisible}
        disabled={!isRightArrowVisible}
      >
        <ArrowIcon />
      </RightArrow>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

const ContentWrapper = styled.div`
  display: flex;
  height: 100%;
  overflow: scroll;
  margin: 0 48px;
  overflow-x: scroll;
  white-space: nowrap;
  scroll-behavior: smooth;

  ::-webkit-scrollbar {
    height: 8px;
  }
`;

const Arrow = styled.button<{ isVisible: boolean }>`
  position: absolute;
  top: 0;
  z-index: 3;
  height: 100%;
  width: 48px;
  background: var(--secondary);
  border: 0;
  box-shadow: none;
  box-shadow: 3px 0px 6px #00000029;

  &:hover {
    cursor: pointer;
  }

  & > svg {
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0.3)};
  }
`;

const LeftArrow = styled(Arrow)`
  left: 0;
  box-shadow: 3px 0px 6px #00000029;

  & > svg {
    transform: rotate(180deg);
  }
`;

const RightArrow = styled(Arrow)`
  right: 0;
  box-shadow: -3px 0px 6px #00000029;
`;
