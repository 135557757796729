import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export type Orientation = {
  deg: string;
  isVertical: boolean;
  vw: "vh" | "vw";
  vh: "vh" | "vw";
};

export const useOrientation = (): Orientation => {
  const { search } = useLocation();
  const deg = useMemo(() => {
    const match = search.match(/[?&]orientation=([0-9]*?)(&|$)/);
    if (!match) return "0";
    return match[1];
  }, [search]);

  const isVertical = deg === "90" || deg === "270";
  return {
    deg: deg,
    isVertical,
    vw: isVertical ? "vh" : "vw",
    vh: isVertical ? "vw" : "vh",
  };
};
