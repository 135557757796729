import { useToggle } from "@react-hookz/web";
import { handleError } from "api/client";
import { deleteLane as deleteLaneRequest } from "api/lanes";
import {
  Button,
  Loader,
  TooltipDialog,
  TooltipDialogContainer,
  TooltipDialogContent,
  TooltipDialogFooter,
} from "components";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useLanes } from "store/Lanes.context";
import styled from "styled-components";
import { LaneSidebar } from "../LaneSidebar";
import { LaneName } from "./LaneName";

export const LanesView = () => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [isAdding, toggleIsAdding] = useToggle(false);
  const [showDeleteDialog, toggleDeleteDialog] = useToggle(false);
  const {
    lanes,
    deleteLane,
    doFetchDevices,
    selectLane,
    moveLane,
    selectedLaneId,
    doFetchLanes,
    deviceFetchState,
    doUpdateLanesOrder,
  } = useLanes();
  const handleLaneClick = useCallback(
    (laneId: string) => {
      toggleIsAdding(false);
      selectLane((prevLaneId) => (laneId === prevLaneId ? undefined : laneId));
    },
    [selectLane, toggleIsAdding]
  );

  const handleLaneDelete = useCallback(async () => {
    if (!selectedLaneId) return;

    try {
      setIsDeleting(true);
      await deleteLaneRequest(selectedLaneId);
      deleteLane(selectedLaneId);
      selectLane(undefined);
      toast.success("Lane was deleted successfully.");
    } catch (error) {
      handleError(error, "Error deleting lane.");
    }

    setIsDeleting(false);
    toggleDeleteDialog(false);
  }, [deleteLane, selectLane, selectedLaneId, toggleDeleteDialog]);

  const handleLaneAdd = useCallback(async () => {
    toggleIsAdding(true);
    selectLane(undefined);
  }, [selectLane, toggleIsAdding]);

  const handleToggleDeleteDialog = useCallback(() => {
    toggleDeleteDialog();
  }, [toggleDeleteDialog]);

  useEffect(() => {
    doFetchDevices();
    doFetchLanes();
  }, [doFetchDevices, doFetchLanes]);

  if (
    deviceFetchState.status === "not-executed" ||
    deviceFetchState.status === "loading"
  ) {
    return <Loader />;
  }
  if (deviceFetchState.error) return null;

  return (
    <Panels>
      <PanelLeft>
        <ButtonWrapper>
          <Button size="large" onClick={handleLaneAdd}>
            Add a lane
          </Button>
          <TooltipDialog
            id="delete-lane-dialog"
            isOpen={showDeleteDialog}
            onDismiss={toggleDeleteDialog}
            content={
              <TooltipDialogContainer>
                <TooltipDialogContent>
                  Are you sure you want
                  <br /> to delete this lane?
                </TooltipDialogContent>
                <TooltipDialogFooter>
                  <Button disabled={isDeleting} onClick={handleLaneDelete}>
                    Delete
                  </Button>
                  <Button
                    disabled={isDeleting}
                    onClick={handleToggleDeleteDialog}
                  >
                    Don&apos;t
                  </Button>
                </TooltipDialogFooter>
              </TooltipDialogContainer>
            }
          >
            <Button
              variant="error"
              disabled={isDeleting || !selectedLaneId}
              onClick={handleToggleDeleteDialog}
              size="largish"
            >
              Delete
            </Button>
          </TooltipDialog>
        </ButtonWrapper>
        <LaneNames>
          {lanes.map((lane, index) => (
            <LaneName
              key={lane.id}
              lane={lane}
              index={index}
              isSelected={lane.id === selectedLaneId}
              move={moveLane}
              onClick={handleLaneClick}
              onDrop={doUpdateLanesOrder}
            />
          ))}
        </LaneNames>
      </PanelLeft>
      <PanelCenter>
        {selectedLaneId || isAdding ? (
          <LaneSidebar
            key={selectedLaneId}
            addMode={isAdding}
            toggleAddMode={toggleIsAdding}
            selectedLaneId={selectedLaneId}
          />
        ) : (
          <EmptySidebarMessage>
            <span>Select a lane to view details</span>
          </EmptySidebarMessage>
        )}
      </PanelCenter>
    </Panels>
  );
};

const Panels = styled.div`
  display: flex;
  height: 100%;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  height: 90px;
  position: sticky;
  top: 0;
  flex-shrink: 0;
  z-index: 1;
  background: var(--primary);
`;

export const PanelLeft = styled.div`
  display: flex;
  flex: 43.5;
  min-width: 300px;
  height: 100%;
  padding: 0 30px;
  flex-direction: column;
  position: relative;
  overflow-y: scroll;
  z-index: 5;
  flex-shrink: 0;
`;

export const PanelCenter = styled.div`
  flex: 56.5;
  min-width: 800px;
  overflow-y: scroll;
  background: var(--secondary);
`;

const EmptySidebarMessage = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    margin-bottom: 282px;
    color: #949498;
  }
`;

const LaneNames = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;
  flex: 1;
`;
