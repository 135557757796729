import {
  Detection as ApiDetection_,
  RectCorners,
} from "../../../../backend/src/detections/types";
import { createImageUrl } from "../../api/detections";

export type ApiDetection = ApiDetection_;

export type DetectionThumbnail = {
  id: string;
  deviceId?: string;
  src: string;
  plateArea?: RectCorners;
  plateText: string;
  plateTextConfidence: number;
  plateCountry: string;
  detectionDate: string;
};
export type Detection = Omit<ApiDetection, "thumbnails"> & {
  thumbnails: DetectionThumbnail[];
};

export const apiResponseToDetection = (
  detectionData: ApiDetection
): Detection => ({
  ...detectionData,
  thumbnails: detectionData.thumbnails.map((t) => ({
    ...t,
    src: createImageUrl(t.id),
  })),
});

export const isDetectionLowConfidence = (
  detection: Pick<
    Detection,
    "inconsistent" | "lowConfidenceSymbols" | "lowConfidenceCountry"
  >
) =>
  detection.lowConfidenceSymbols ||
  detection.lowConfidenceCountry ||
  detection.inconsistent;
