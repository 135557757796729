import { IntegrationNotifier } from "../integration/types";

export type LaneDirection = "in" | "out" | "two-way";

export type VideoSourceOrientation = "looking-in" | "looking-out";

/**
 * Lanes as returned by the API.
 */
export type Lane = LaneDb;

export type CornerPos = [number, number];

export type VideoSource = {
  deviceId: string;
  rtspUrl?: string;
  orientation: VideoSourceOrientation;
  areaToScan?: CornerPos[];
};

export type LaneInput = {
  name: string;
  direction: LaneDirection;
  externalIds?: ExternalLaneIds;
  videoSources?: VideoSource[];
  order?: number;
  minimizeNotifiersDelay?: boolean;
  isInternal?: boolean;
  disableRecognition?: boolean;
};

export type LaneDbInput = Omit<LaneInput, "videoSources"> & {
  videoSources?: string;
  isDeleted?: boolean;
};

export type LaneDb = Omit<
  LaneInput,
  "minimizeNotifiersDelay" | "isInternal" | "disableRecognition"
> & {
  id: string;
  externalIds: ExternalLaneIds;
  minimizeNotifiersDelay: boolean;
  isInternal: boolean;
  disableRecognition: boolean;
  isDeleted: boolean;
};

export type ExternalLaneId = {
  externalIdIn: string;
  externalIdOut: string;
};

export type ExternalLaneIds = Partial<
  Record<IntegrationNotifier, ExternalLaneId>
>;

export type GroupedLanes = {
  [key: string]: LaneConfig[];
};

export type LaneConfig = {
  laneId: string;
  id: string;
  areaToScan?: CornerPos[];
  cameraSource?: string;
};

export type LaneStats = {
  laneId: string;
  detectionsCount: number;
  detectionsInCount: number;
  detectionsOutCount: number;
};

export type TriggerMappingInput = {
  laneId: string;
  deviceId: string;
  triggerId: string;
  triggerName: string;
};

export type TriggerMapping = TriggerMappingInput & {
  id: string;
};
export const openApiUvpRtspDeviceId = {
  type: "string",
  oneOf: [
    {
      description: "RTSP device ID",
      format: "uuid",
      example: "79ee59ad-6b76-4c65-a486-fac59922bc7c",
    },
    {
      description: "UVP device ID",
      pattern: "^\\d+$",
      example: "123",
    },
  ],
};

export const openApiUvpDeviceId = {
  type: "string",
  description: "UVP device ID",
  pattern: "^\\d+$",
  example: "123",
};

export const openApiLaneId = {
  type: "string",
  format: "uuid",
  example: "79ee59ad-6b76-4c65-a486-fac59922bc7c",
  description: "Universally unique ID.",
};

export const openApiLane = {
  type: "object",
  properties: {
    id: openApiLaneId,
    name: {
      type: "string",
      minLength: 1,
      maxLength: 100,
      description: "User given name for the lane.",
    },
    direction: {
      $ref: "#/components/schemas/DirectionType",
    },
    videoSources: {
      description:
        "It stores device ids attached to the lane as well as coordinates of the polygon vertices" +
        "that are used by algorithm for detections for specific camera. " +
        "Coordinates should be given in following form [[0.0, 0.1] , [1.0,0.5] ....] " +
        "where [0.000, 0.000] corresponds to top left corner of video feed and [1.0,1.0] to bottom right corner.",
      type: "array",
      items: {
        type: "object",
        required: ["orientation"],
        properties: {
          deviceId: openApiUvpRtspDeviceId,
          areaToScan: {
            type: "array",
            items: {
              type: "array",
              minLength: 2,
              maxLength: 2,
              items: { type: "number" },
            },
          },
          orientation: {
            type: "string",
            enum: ["looking-in", "looking-out"],
          },
        },
      },
    },
    externalIdIn: {
      type: "number",
      nullable: true,
      description:
        "Unique id for identifying lane in an external system, used when vehicle is detected entering",
    },
    externalIdOut: {
      type: "number",
      nullable: true,
      description:
        "Unique id for identifying lane in an external system, used when vehicle is detected exiting",
    },
    order: {
      type: "number",
      nullable: false,
      description: "Custom sorting order for UI",
    },
    isInternal: {
      type: "boolean",
      description: "Whether this lane leads to or from an internal area.",
    },
    disableRecognition: {
      type: "boolean",
      description:
        "Whether anpr-recognition algorithm should be disabled for this lane.",
    },
  },
};

const { id, ...openApiLaneInputProps } = openApiLane.properties;

export const openApiUpdateLaneInput = {
  type: "object",
  properties: openApiLaneInputProps,
};

export const openApiCreateLaneInput = {
  ...openApiUpdateLaneInput,
  required: ["name"],
};

export const openApiLaneStats = {
  type: "object",
  properties: {
    laneId: {
      ...openApiLaneId,
      description: "UUID for lane",
    },
    detectionsCount: {
      type: "number",
      description: "Number of detections recognized.",
    },
    detectionsInCount: {
      type: "number",
      description: "Number of detections recognized as incoming traffic.",
    },
    detectionsOutCount: {
      type: "number",
      description: "Number of detections recognized as outgoing traffic.",
    },
  },
};

export const openApiTriggerMappingInput = {
  type: "object",
  properties: {
    laneId: {
      ...openApiLaneId,
      description: "Lane ID (UUID) that trigger is mapped to.",
    },
    deviceId: openApiUvpDeviceId,
    triggerId: {
      type: "string",
      description: "UVP trigger ID",
      minLength: 1,
    },
    triggerName: {
      type: "string",
      description: "UVP trigger name",
    },
  },
};

export const openApiTriggerMapping = {
  type: "object",
  properties: {
    ...openApiTriggerMappingInput.properties,
    id: {
      type: "number",
      description: "Mapping lane to trigger id",
    },
  },
};

export const openApiLiveStreamResponse = {
  type: "object",
  properties: {
    asanPlayerStreamUrl: {
      type: "string",
      description: "URL to websocket video stream.",
    },
    asanPlayerUrl: {
      type: "string",
      description:
        "URL to javascript video player library that works with websocket and HLS video streams.",
    },
    hlsStreamUrl: {
      type: "string",
      description: "URL to HLS video stream.",
    },
    jsplayerUrl: {
      type: "string",
      description:
        "URL to javascript video player library that works with dash video streams.",
    },
    videoStreamUrl: {
      type: "string",
      description: "URL to MPEG DASH video stream.",
    },
  },
};

export const openApiAsantechLiveStreamParams = {
  type: "object",
  properties: {
    cameraId: {
      type: "string",
    },
    clientIp: {
      type: "string",
      example: "",
      description:
        "It should be client's public IP address. IT can be left empty string then stream URL would work from any IP address.",
    },
    width: {
      type: "integer",
      example: 640,
      description: "Width of requested video stream.",
    },
    height: {
      type: "integer",
      example: 320,
      description: "Height of requested video stream.",
    },
  },
};

export const openApiRtspLiveStreamParams = {
  type: "object",
  properties: {
    rtspStreamUrl: {
      type: "string",
    },
  },
};

export const openApiDeviceTrigger = {
  type: "object",
  properties: {
    triggerName: {
      type: "string",
      description: "Name of the trigger",
    },
    cameraId: {
      type: "string",
    },
    triggerId: {
      type: "string",
    },
    type: {
      type: "string",
    },
    deviceId: {
      type: "string",
    },
    parentId: {
      type: "string",
    },
  },
};

export const openApiDevice = {
  type: "object",
  properties: {
    isCamera: {
      type: "boolean",
    },
    deviceId: {
      type: "string",
    },
    deviceName: {
      type: "string",
    },
    parentId: {
      type: "string",
    },
  },
};
