import client from "api/client";
import { ApiEndpoints } from "api/endpoints";
import { DoorDb, DoorInput } from "../../../backend/src/doors/types";

export function getDoors() {
  return client.get<DoorDb[]>(ApiEndpoints.Doors);
}

export async function createDoor(doorData: DoorInput) {
  return client.post<DoorDb>(ApiEndpoints.Doors, doorData);
}

export async function deleteDoor(doorId: string) {
  return client.delete(`${ApiEndpoints.Doors}/${doorId}`);
}

export async function updateDoor(doorId: string, doorData: DoorInput) {
  return client.patch<DoorDb>(`${ApiEndpoints.Doors}/${doorId}`, doorData);
}
