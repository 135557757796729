import Modal from "react-modal";

if (process.env.NODE_ENV !== "test") {
  Modal.setAppElement("#root");
}

// override default modal styles
Modal.defaultStyles = {
  overlay: {
    ...Modal.defaultStyles.overlay,
    backgroundColor: "#0F0F10CC",
    zIndex: 9,
  },
  content: {
    ...Modal.defaultStyles.content,
    background: "transparent",
    border: "none",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "hidden",
  },
};
