import { ImagesCarousel } from "@asantech/common/react/ImagesCarousel";
import { LiveVideo } from "@asantech/common/react/LiveVideo/LiveVideo";
import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { Lane } from "../../../../../backend/src/lanes/types";
import { getLiveStream, handleError } from "../../../api/client";
import { useLanes } from "../../../store/Lanes.context";

export const LaneCardLiveStream: React.FC<{
  lane: Lane;
}> = (props) => {
  const { lane } = props;
  const videoSources = useMemo(
    () => lane.videoSources || [],
    [lane.videoSources]
  );

  const [selected, setSelected] = useState(0);
  const { devices } = useLanes();

  const videoSourceName = useMemo(() => {
    const name =
      devices.find(
        (device) => device.deviceId === videoSources[selected].deviceId
      )?.deviceName || "";

    return name;
  }, [devices, selected, videoSources]);

  return (
    <Wrapper>
      <ImagesCarousel
        selectedImage={selected}
        setSelectedImage={setSelected}
        bottomBarArea={<>{videoSourceName}</>}
      >
        {videoSources.map(({ deviceId, rtspUrl }) => (
          <LaneCardLiveStreamWrapper key={lane.id + deviceId}>
            <LiveVideo
              cameraId={deviceId}
              onError={handleError}
              getLiveStream={getLiveStream}
              useAsanPlayer={!rtspUrl}
              rtspStreamUrl={rtspUrl}
            />
          </LaneCardLiveStreamWrapper>
        ))}
      </ImagesCarousel>
    </Wrapper>
  );
};

const LaneCardLiveStreamWrapper = styled.div`
  height: 200px;
  width: 100%;
  position: relative;
  display: inline-block;
  overflow: hidden;
  margin-top: 8px;
`;
const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
  & .btn-next + * {
    display: none;
  }
`;
