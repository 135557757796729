import React, { ReactNode } from "react";

import styled from "styled-components";

type Size = "normal" | "largish";

interface Props {
  title?: string;
  actions?: React.ReactNode;
  size?: Size;
  className?: string;
  children?: ReactNode;
}

export const Card: React.FC<Props> = ({
  actions,
  title,
  className,
  children,
  size = "normal",
}) => {
  return (
    <Wrapper size={size} className={className}>
      {(!!title || !!actions) && (
        <CardHeader size={size}>
          {title && <Title size={size}>{title}</Title>}
          {actions}
        </CardHeader>
      )}
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ size: Size }>`
  padding: ${({ size }) => (size === "largish" ? "20px" : "16px")};
  background: var(--tertiary);
  border-radius: 4px;
  margin-bottom: ${({ size }) => (size === "largish" ? "20px" : "16px")};
`;

export const CardHeader = styled.div<{ size: Size }>`
  display: flex;
  padding-bottom: ${({ size }) => (size === "largish" ? "20px" : "16px")};
`;

const Title = styled.p<{ size: Size }>`
  font-size: ${({ size }) => (size === "largish" ? "16px" : "14px")};
  font-weight: bold;
  color: var(--text-primary);
  padding: 0;
  flex: 1;
`;

export const CardSubtitle = styled.div`
  font-size: 16px;
  color: var(--text-primary);
  line-height: 22px;
  padding: 0;
  padding-bottom: 10px;
`;

export const CardRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
`;

export const CardColumn = styled.div`
  flex: 1;

  & + & {
    margin-left: 20px;
  }
`;
