import { useCallback, useRef } from "react";
import { useDrag, useDrop, XYCoord } from "react-dnd";
import styled from "styled-components";
import { Lane } from "../../../../../backend/src/lanes/types";
import { ReactComponent as HandleIcon } from "../../../assets/svgs/handle-icon.svg";

type Props = {
  lane: Lane;
  isSelected: boolean;
  index: number;
  move: (index: number, newIndex: number) => void;
  onDrop: () => void;
  onClick: (laneId: string) => void;
};

type DragItem = {
  index: number;
  id: string;
};

export const LaneName = ({
  lane,
  isSelected,
  onClick,
  onDrop,
  index,
  move,
}: Props) => {
  const ref = useRef<HTMLLIElement>(null);
  const handleClick = useCallback(() => onClick(lane.id), [lane.id, onClick]);
  const [, drop] = useDrop<DragItem>({
    accept: "laneName",
    hover: (item: DragItem, monitor) => {
      if (!ref.current) return;

      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) return;

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

      item.index = hoverIndex;
      move(dragIndex, hoverIndex);
    },
  });

  const [isDragging, dragHandleRef, drag] = useDrag({
    type: "laneName",
    item: { id: lane.id, index },
    collect: (monitor) => monitor.isDragging(),
    end: onDrop,
  });

  drag(drop(ref));

  return (
    <Li
      ref={ref}
      selected={isSelected}
      dragging={isDragging}
      onClick={handleClick}
    >
      {lane.name}
      <Handle ref={dragHandleRef}>
        <HandleIcon />
      </Handle>
    </Li>
  );
};

const Li = styled.li<{
  selected?: boolean;
  dragging?: boolean;
}>`
  font-size: 16px;
  line-height: 22px;
  margin-top: 1px;
  padding: 12px 10px 13px 30px;
  word-break: break-all;
  background-color: ${({ selected }) =>
    selected ? "#313740" : "var(--secondary)"};
  color: var(--text-primary);
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  opacity: ${({ dragging }) => (dragging ? 0 : 1)};
`;

const Handle = styled.div`
  opacity: 0.5;
  padding: 6px 10px 7px 11px;
  cursor: grab;
`;
