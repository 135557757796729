import { useAsync, useList, usePrevious } from "@react-hookz/web";
import { axiosStatic as axios, CancelTokenSource } from "api/client";
import {
  DetectionsFilterParams,
  DETECTIONS_PAGE_SIZE,
  getDetections,
} from "api/detections";
import { initialFirstItemIndex } from "common/consts";
import {
  useDetectionsWebSocket,
  useDirectionsWebSocket,
  useExternalNotificationWebSocket,
} from "common/hooks";
import { ChildrenOnly } from "common/types";
import { groupByKey } from "common/utils";
import {
  ApiDetection,
  apiResponseToDetection,
  Detection,
} from "common/utils/detections";
import { parseISO } from "date-fns";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLanes } from "store/Lanes.context";
import {
  Direction,
  DirectionMessage,
  ExternalNotifications,
} from "../../../../backend/src/detections/types";

export type TrafficFilters = {
  toDate: Date | null;
  fromDate: Date | null;
  plateText?: string;
  lanes: string[];
  lowConfidence?: boolean;
  differing?: boolean;
  sentDifferent?: boolean;
  orphaned?: boolean;
};

export type DetectionsContextType = {
  isLoading: boolean;
  hasMoreResults: boolean;
  canLoadMore: boolean;
  filters: TrafficFilters;
  detections: Detection[];
  detectionsCount: number;
  fetchConfig: FetchConfig | undefined;
  doFetchDetections: () => Promise<void>;
  setFilters: React.Dispatch<React.SetStateAction<TrafficFilters>>;
  isFilterActive: boolean;
  lastDetectionFetched: Detection | undefined;
  getLaneFirstItemIndex: (
    laneId: string,
    direction: Direction
  ) => number | undefined;
  selectedImageByDetection: Record<string, number>;
  setSelectedImageByDetection: React.Dispatch<
    React.SetStateAction<Record<string, number>>
  >;
  logTableFirstItemIndex: number;
  logTableScrollTop: React.MutableRefObject<number>;
};

type FetchConfig = {
  lastIfExists: Detection | undefined;
  qsParams: DetectionsFilterParams;
  filtersChanged: boolean;
};

const filterByPlate = (item: Detection, searchPlate?: string): boolean => {
  const plateToCompare = `${item.plateText}-${item.plateCountry}`.toLowerCase();
  return plateToCompare.includes((searchPlate || "").toLowerCase());
};

const filterByActiveLane = (
  item: Detection,
  activeLaneIds: string[]
): boolean => activeLaneIds && activeLaneIds.includes(item.laneId);

const filterByDateRange = (
  item: Detection,
  dateRange: [Date | null, Date | null]
): boolean => {
  const [from, to] = dateRange;
  const date = parseISO(item.detectionDate);

  if (!from || !to) return true;
  return date <= to && date >= from;
};

export const DetectionsContext = createContext<
  DetectionsContextType | undefined
>(undefined);

/**
 * Apply all filters except orphaned due to its complexity.
 */
const applyFilters = (item: Detection, filters: TrafficFilters) =>
  filterByActiveLane(item, filters.lanes) &&
  filterByDateRange(item, [filters.fromDate, filters.toDate]) &&
  filterByPlate(item, filters.plateText);

export const DetectionsContextProvider = ({ children }: ChildrenOnly) => {
  const axiosSource = useRef<CancelTokenSource>();
  const [firstItemIndexes, setFirstItemIndexes] = useState<
    Record<string, Partial<Record<Direction, number>>>
  >({});
  const [fetchConfig, setFetchConfig] = useState<FetchConfig | undefined>(
    undefined
  );
  const [detections, { set: setDetections, insertAt, updateAt }] =
    useList<Detection>([]);
  const [detectionsCount, setDetectionsCount] = useState(0);
  const [selectedImageByDetection, setSelectedImageByDetection] = useState<
    Record<string, number>
  >({});

  const [filters, setFilters] = useState<TrafficFilters>({
    fromDate: null,
    toDate: null,
    lanes: [],
  });

  const logTableScrollTop = useRef(0);

  const [logTableFirstItemIndex, setLogTableFirstItemIndex] = useState(
    initialFirstItemIndex
  );

  const decrementLogTableFirstItemIndex = useCallback((count = 1) => {
    setLogTableFirstItemIndex((prev) => prev - count);
  }, []);

  const { lanes, lanesKeepScrollState } = useLanes();

  const [hasMoreResults, setHasMoreResults] = useState(true);
  const [lastDetectionFetched, setLastDetectionFetched] = useState<
    Detection | undefined
  >(undefined);
  const prevFilters = usePrevious(filters);

  const getLaneFirstItemIndex = useCallback(
    (laneId: string, direction: Direction) =>
      (firstItemIndexes[laneId] || {})[direction],
    [firstItemIndexes]
  );

  const decrementLaneFirstItemIndex = useCallback(
    (laneId: string, direction: Direction, count = 1) =>
      setFirstItemIndexes((prev) => {
        prev[laneId] = prev[laneId] || {};
        prev[laneId][direction] =
          (prev[laneId][direction] || initialFirstItemIndex) - count;
        return { ...prev };
      }),
    []
  );

  const handleSocketMessage = useCallback(
    (message: ApiDetection) => {
      const detection: Detection = apiResponseToDetection(message);
      const index = detections.findIndex((d) => d.id === detection.id);
      const { laneId } = detection;

      if (index > -1) {
        const existing = detections[index];

        detection.sentExternalNotifications =
          existing.sentExternalNotifications;
        updateAt(index, detection);
      } else {
        if (filters.orphaned) {
          // we'd have to check new detections that are orphaned separately from the server
          // assume this one was not and orphan and skip adding it
          return;
        }

        const isVisible = applyFilters(detection, filters);

        if (!isVisible) return;

        if (detection.duplicateOf) {
          setDetections(handleDuplicates(detections, [detection]));
        } else {
          insertAt(0, detection);
          setDetectionsCount((prev) => prev + 1);

          const lane = lanes.find((l) => l.id === laneId);
          const direction: Direction | null | undefined =
            lane?.direction === "two-way"
              ? detection.direction
              : lane?.direction;
          const { scrollTop, isFocused } =
            lanesKeepScrollState[laneId]?.[direction as Direction] || {};

          if ((scrollTop || isFocused) && direction) {
            decrementLaneFirstItemIndex(laneId, direction);
          }

          if (logTableScrollTop.current) {
            decrementLogTableFirstItemIndex();
          }
        }
      }
    },
    [
      filters,
      detections,
      updateAt,
      insertAt,
      lanesKeepScrollState,
      lanes,
      decrementLaneFirstItemIndex,
      setDetections,
      decrementLogTableFirstItemIndex,
    ]
  );

  useDetectionsWebSocket(handleSocketMessage);

  const handleDirectionMessage = useCallback(
    (message: DirectionMessage["payload"]) => {
      const index = detections.findIndex(
        (d) => d.vehicleId === message.vehicleId
      );
      if (index > -1) {
        const existing = detections[index];
        const { laneId } = existing;
        const { direction } = message;
        const isVisible = applyFilters(existing, filters);

        updateAt(index, { ...existing, direction });

        if (!isVisible) return;

        const lane = lanes.find((l) => l.id === laneId);
        const { scrollTop, isFocused } =
          lanesKeepScrollState[laneId]?.[direction] || {};
        const shouldKeepScroll =
          (scrollTop || isFocused) && lane?.direction === "two-way";
        const itemMoved =
          !existing.direction || existing.direction !== direction;

        if (shouldKeepScroll && itemMoved) {
          decrementLaneFirstItemIndex(laneId, direction);
        }
      }
    },
    [
      detections,
      updateAt,
      lanes,
      decrementLaneFirstItemIndex,
      filters,
      lanesKeepScrollState,
    ]
  );

  useDirectionsWebSocket(handleDirectionMessage);

  const handleExternalNotificationUpdate = useCallback(
    (payload: ExternalNotifications) => {
      const index = detections.findIndex(
        ({ thumbnails }) =>
          !!thumbnails.find(
            (image) => image.id === payload.sentDetectionImageId
          )
      );
      if (index > -1) {
        const detection = { ...detections[index] };
        const notifications = [...detection.sentExternalNotifications, payload];
        detection.sentExternalNotifications = notifications;
        updateAt(index, detection);
      }
    },
    [detections, updateAt]
  );

  useExternalNotificationWebSocket(handleExternalNotificationUpdate);

  const [detectionsResponseState, { execute: doFetchDetections }] = useAsync(
    async () => {
      const filtersChanged = filters !== prevFilters;
      if (!hasMoreResults && !filtersChanged) return;

      const lastIfExists = !filtersChanged ? lastDetectionFetched : undefined;
      const { orphaned, lowConfidence, ...otherFilters } = filters;
      const qsParams = {
        ...otherFilters,
        ...(lowConfidence ? { lowConfidence: true } : {}),
        ...(orphaned ? { orphan: true } : {}),
      };

      if (filtersChanged && axiosSource.current) axiosSource.current.cancel();
      if (qsParams.lanes && !qsParams.lanes.length) {
        setDetections([]);
        setDetectionsCount(0);
        setHasMoreResults(false);
        setLastDetectionFetched(undefined);
        return;
      }

      axiosSource.current = axios.CancelToken.source();
      const axiosConfig = { cancelToken: axiosSource.current.token };

      const response = await getDetections(lastIfExists, qsParams, axiosConfig);
      setFetchConfig({ lastIfExists, qsParams, filtersChanged });
      const newDetections: Detection[] = response.data.detections.map(
        apiResponseToDetection
      );

      if (newDetections.length) {
        setLastDetectionFetched(newDetections[newDetections.length - 1]);
        setHasMoreResults(newDetections.length >= DETECTIONS_PAGE_SIZE);
      } else {
        setHasMoreResults(false);
      }

      axiosSource.current = undefined;
      setDetectionsCount(response.data.count);
      setDetections((prev) => {
        if (filtersChanged) {
          return handleDuplicates([], newDetections);
        }

        return handleDuplicates(prev, newDetections);
      });
    }
  );

  useEffect(() => {
    if (prevFilters && filters !== prevFilters) {
      void doFetchDetections();
    }
  }, [filters, prevFilters, doFetchDetections]);

  const value: DetectionsContextType = {
    isLoading: detectionsResponseState.status === "loading",
    hasMoreResults,
    canLoadMore: detectionsResponseState.status !== "loading" && hasMoreResults,
    filters,
    setFilters,
    detections,
    detectionsCount,
    doFetchDetections,
    lastDetectionFetched,
    isFilterActive:
      filters.fromDate !== null ||
      filters.toDate !== null ||
      filters.lowConfidence === true ||
      filters.orphaned === true ||
      !!filters.plateText,
    fetchConfig,
    getLaneFirstItemIndex,
    selectedImageByDetection,
    setSelectedImageByDetection,
    logTableFirstItemIndex,
    logTableScrollTop,
  };

  return (
    <DetectionsContext.Provider value={value}>
      {children}
    </DetectionsContext.Provider>
  );
};

export const useDetections = (): DetectionsContextType => {
  const context = useContext(DetectionsContext);
  if (context === undefined)
    throw new Error(
      "useDetections must be used within DetectionsContextProvider"
    );
  return context;
};

export const handleDuplicates = (
  existingDetections: Detection[],
  newDetections: Detection[]
): Detection[] => {
  const existingIds = existingDetections.map((detection) => detection.id);
  const merged = [
    ...existingDetections,
    ...newDetections.filter((detection) => !existingIds.includes(detection.id)),
  ];

  const mergedVehiclesIds = merged.map((i) => i.vehicleId);

  const duplicatesOnly = merged.filter(
    (detection) =>
      detection.duplicateOf && mergedVehiclesIds.includes(detection.duplicateOf)
  );

  const detectionsByDuplicates = groupByKey(duplicatesOnly, "duplicateOf");
  let idsToRemove: string[] = [];
  for (const duplicatedId of Object.keys(detectionsByDuplicates)) {
    const duplicatesMerged = detectionsByDuplicates[duplicatedId];

    const updateToIdx = merged.findIndex(
      (detection) => detection.vehicleId === duplicatedId
    );
    if (updateToIdx >= 0) {
      merged[updateToIdx] = mergeDuplicatedDetections(
        merged[updateToIdx],
        duplicatesMerged
      );
      idsToRemove = idsToRemove.concat(
        duplicatesMerged.map((value) => value.id).flat(1)
      );
    }
  }

  return merged.filter((detection) => !idsToRemove.includes(detection.id));
};

const mergeDuplicatedDetections = (
  detection: Detection,
  duplicates: Detection[]
) => {
  const thumbnailsNew = duplicates.map((det) => det.thumbnails).flat(1);
  const updated = {
    ...detection,
    thumbnails: [...detection.thumbnails, ...thumbnailsNew],
  };

  return updated;
};
