import { DropdownMenu } from "@asantech/common/react/DropdownMenu";
import { Loader } from "@asantech/common/react/Loader";
import { useIsDebugEnabled } from "@asantech/common/react/common/hooks/useIsDebugEnabled";
import { Dropdown, DropdownValue, Filter } from "components";
import React, { useCallback, useEffect, useMemo } from "react";
import { useLanes } from "store/Lanes.context";
import {
  TrafficFilters,
  useDetections,
} from "store/detections/Detections.context";
import styled from "styled-components";
import { useActiveLanesFilter } from "views/lanes";
import { Checkbox } from "../Checkbox";

interface Props {
  disabled?: boolean;
}

const lowConfidenceDropdownOptions: {
  value: keyof TrafficFilters;
  label: string;
}[] = [
  { value: "lowConfidence", label: "All low confidence" },
  { value: "differing", label: "Differing detections" },
  { value: "sentDifferent", label: "Sent different" },
];

export const FiltersBar: React.FC<Props> = ({ disabled }) => {
  const { lanes } = useLanes();
  const { filters, setFilters } = useDetections();
  const isDebugMode = useIsDebugEnabled();
  const { activeLaneIds, toggleActiveLane } = useActiveLanesFilter(
    lanes,
    filters,
    setFilters
  );

  const handleLowConfidenceBtnClick = useCallback(() => {
    setFilters((filters) => ({
      ...filters,
      lowConfidence: !filters.lowConfidence,
    }));
  }, [setFilters]);

  const lowConfidenceDropdownValue = useMemo(
    () => lowConfidenceDropdownOptions.find((o) => filters[o.value])?.value,
    [filters]
  );

  const handleLowConfidenceDropdownChange = useCallback(
    (value: DropdownValue) => {
      setFilters((filters) => ({
        ...filters,
        lowConfidence: undefined,
        differing: undefined,
        sentDifferent: undefined,
        ...(value ? { [value]: true } : {}),
      }));
    },
    [setFilters]
  );

  const handleOrphanBtnClick = useCallback(() => {
    setFilters((filters) => ({
      ...filters,
      orphaned: !filters.orphaned,
    }));
  }, [setFilters]);

  useEffect(() => {
    const laneIds = activeLaneIds || [];

    if (!lanes.length || laneIds?.join(",") === filters.lanes?.join(","))
      return;

    setFilters((filters) => ({
      ...filters,
      lanes: laneIds || [],
    }));
  }, [activeLaneIds, lanes, filters, setFilters]);

  return (
    <Wrapper className="filters-bar">
      <Content>
        <DropdownMenu placeholder="Select lanes">
          <DropdownPlaceholder>
            {!lanes && <DropdownLoader size={20} />}
            {Object.values(lanes).map((lane) => (
              <DropdownMenuItem key={lane.id}>
                <Checkbox
                  label={lane.name}
                  onChange={() => toggleActiveLane(lane.id)}
                  checked={Boolean(
                    activeLaneIds.find((laneId) => laneId === lane.id)
                  )}
                />
              </DropdownMenuItem>
            ))}
          </DropdownPlaceholder>
        </DropdownMenu>
        <RightCol>
          {isDebugMode ? (
            <DropdownStyled
              allowEmpty={true}
              placeholder="Select low confidence filter"
              value={lowConfidenceDropdownValue}
              onChange={handleLowConfidenceDropdownChange}
              options={lowConfidenceDropdownOptions}
            />
          ) : (
            <Filter
              onClick={handleLowConfidenceBtnClick}
              value={!!filters.lowConfidence}
              variant="primary"
              disabled={disabled}
            >
              Low confidence
            </Filter>
          )}
          <OrphanedDetectionsFilterContainer>
            <Filter
              value={!!filters.orphaned}
              onClick={handleOrphanBtnClick}
              variant="warning"
              disabled={disabled}
            >
              Orphaned detections
            </Filter>
          </OrphanedDetectionsFilterContainer>
        </RightCol>
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  background: var(--secondary);
  padding: 12px 48px 28px;
`;

const Content = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  & > *:not(:last-child) {
    margin-right: 16px;
  }
`;

const OrphanedDetectionsFilterContainer = styled.div`
  & > button.warning {
    background-color: #f7ca471a;
  }
`;

const DropdownPlaceholder = styled.div`
  min-height: 80px;
`;

const DropdownMenuItem = styled.div`
  border: 0;
  box-shadow: none;
  background: transparent;
  font-size: 14px;
  font-weight: 400;
  color: var(--text-secondary);
  padding: 11px 16px 10px;
  width: 100%;
  text-align: left;
  border-bottom: 1px solid var(--secondary);

  &:hover {
    cursor: pointer;
    background: #2d3035;
  }

  &:last-child {
    border-bottom: 0;
  }
`;

const DropdownLoader = styled(Loader)`
  margin: 30px;
`;

const DropdownStyled = styled(Dropdown)`
  width: auto;

  input::placeholder {
    color: var(--text-primary);
    opacity: 1;
  }

  input,
  button {
    color: var(--text-primary-contrast);
    background: var(--tertiary);
    outline: none;
  }

  button {
    border-bottom: 1px solid var(--secondary);
  }

  ul {
    top: 45px;
  }

  svg.icon {
    height: 12px;
  }
`;

const RightCol = styled.div`
  display: flex;
  align-items: center;
  border-left: 1px solid var(--tertiary);
  padding-left: 24px;
  & > *:not(:last-child) {
    margin-right: 16px;
  }
`;
