import client from "api/client";
import { ApiEndpoints } from "api/endpoints";

import { PlateData, PlateDataInput } from "../../../backend/src/plates/types";

export function getExcludedPlates() {
  return client.get<PlateData[]>(ApiEndpoints.PlatesExcluded);
}

export function getIncludedPlates() {
  return client.get<PlateData[]>(ApiEndpoints.PlatesIncluded);
}

export async function createExcludedPlate(plateData: PlateDataInput) {
  const response = await client.post<PlateData>(
    ApiEndpoints.PlatesExcluded,
    plateData
  );
  return response.data;
}

export function deleteExcludedPlate(plateId: string) {
  return client.delete<PlateData[]>(
    `${ApiEndpoints.PlatesExcluded}/${plateId}`
  );
}
