import styled from "styled-components";

export const ResponseStatusMessage = styled.p`
  text-align: center;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
