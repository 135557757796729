import { useAsync, useList } from "@react-hookz/web";
import { handleError } from "api/client";
import { getDoors } from "api/doors";
import { Pages } from "common/pages";
import { ChildrenOnly } from "common/types";
import React, { createContext, useCallback, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { DoorDb } from "../../../backend/src/doors/types";
import { ApiError } from "../api/types";

export type DoorsContextType = {
  doors: DoorDb[];
  addDoor: (...items: DoorDb[]) => void;
  deleteDoor: (item: string) => void;
  updateDoor: (item: DoorDb) => void;
  selectedDoorId: string | undefined;
  selectDoor: React.Dispatch<React.SetStateAction<string | undefined>>;
  doFetchDoors: () => Promise<DoorDb[]>;
};

export const DoorsContext = createContext<DoorsContextType | undefined>(
  undefined
);

export const DoorsContextProvider = ({ children }: ChildrenOnly) => {
  const history = useHistory();
  const [selectedDoorId, selectDoor] = useState<string>();

  const [
    doors,
    { set: setDoors, update: updateDoorBy, filter: filterDoors, push: addDoor },
  ] = useList<DoorDb>([]);
  const updateDoor = useCallback(
    (door: DoorDb) => updateDoorBy((item) => item.id === door.id, door),
    [updateDoorBy]
  );
  const deleteDoor = useCallback(
    (doorId: string) => {
      filterDoors((item) => item.id !== doorId);
    },
    [filterDoors]
  );

  const [, { execute: doFetchDoors }] = useAsync(async () => {
    try {
      const response = await getDoors();
      if (response.data.length < 1) {
        history.push(Pages.Doors);
      }
      setDoors(response.data);
      return response.data;
    } catch (error) {
      const message =
        (error as ApiError)?.response?.data?.error || "Failed to fetch doors.";
      handleError(error, message);
      return [];
    }
  });

  const state: DoorsContextType = {
    doors,
    addDoor,
    updateDoor,
    deleteDoor,
    doFetchDoors,
    selectedDoorId,
    selectDoor,
  };

  return (
    <DoorsContext.Provider value={state}>{children}</DoorsContext.Provider>
  );
};

export const useDoors = (): DoorsContextType => {
  const context = useContext(DoorsContext);
  if (context === undefined)
    throw new Error("useDoors must be used within DoorsContextProvider");
  return context;
};
