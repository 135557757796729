import { ReactComponent as AlertIcon } from "assets/svgs/alert-warning-icon.svg";
import React from "react";
import { useDetections } from "store/detections/Detections.context";
import styled from "styled-components";
import {
  Direction,
  OrphanReason,
} from "../../../../../backend/src/detections/types";
import { LaneDirection } from "../../../../../backend/src/lanes/types";
import { getOrphanedDetectionAlertText } from "../../../common/utils";

interface Props {
  orphanReasons?: OrphanReason[];
  laneDirection: LaneDirection;
  direction: Direction | null;
  lowConfidenceAlertText: string | null | undefined;
}

export const MainTitleButtons: React.FC<Props> = ({
  orphanReasons,
  lowConfidenceAlertText,
  laneDirection,
  direction,
}) => {
  const { filters } = useDetections();
  const isLowConfidenceListVisible = !!filters.lowConfidence;
  const isOrphanListVisible = !!filters.orphaned;
  const isLowConfidence = isLowConfidenceListVisible && lowConfidenceAlertText;
  const isOrphaned =
    isOrphanListVisible && !!orphanReasons && orphanReasons.length > 0;

  return (
    <Container>
      {isOrphaned && (
        <Icon
          role="tooltip"
          data-tooltip-id="tooltip"
          data-tooltip-content={getOrphanedDetectionAlertText(
            laneDirection,
            direction,
            orphanReasons
          )}
        >
          <OrphanedAlertIcon />
        </Icon>
      )}
      {isLowConfidence && (
        <>
          <AlertIcon
            className="lowConfidence"
            role="tooltip"
            data-tooltip-id="tooltip"
            data-tooltip-content={lowConfidenceAlertText}
          />
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;

  && > * {
    margin-left: 8px;
  }
`;

export const IconButton = styled.button<{ disabled?: boolean }>`
  width: 48px;
  height: 48px;
  border: 0;
  box-shadow: none;
  outline: 0;
  background: transparent;
  padding: 0;
  margin: 0;
  line-height: 0;
  background: var(--primary);

  ${(props) =>
    props.disabled
      ? `
      & > svg {
        opacity: 0.3;
      }
    `
      : ""}

  &:hover:not([disabled]) {
    cursor: pointer;
  }
`;

const Icon = styled.div`
  width: 35px;
  height: 35px;
  display: flex;
  line-height: 0;
  align-items: center;
  justify-content: center;

  svg {
    outline: none;
  }
`;

const StyledAlertIcon = styled(AlertIcon)`
  width: 18px;
  height: 16px;

  path {
    stroke: #fff;
    fill: transparent;
    stroke-width: 1;
  }
`;

const OrphanedAlertIcon = styled(StyledAlertIcon)`
  path {
    background-color: #f7ca47a1;
    stroke: #f7ca47;
  }
`;
