export enum Pages {
  Home = "/",
  Login = "/login",
  Settings = "/settings",
  Lanes = "/settings/lanes",
  Doors = "/settings/doors",
  SystemSettings = "/settings/system",
  ExcludedPlates = "/settings/plates/excluded",
  IncludedPlates = "/settings/plates/included",
  Maintenance = "/settings/maintenance",
  Traffic = "/traffic",
  InfoDisplay = "/info-display",
}
