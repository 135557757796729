import { ToastContainer } from "@asantech/common/react/ToastContainer/ToastContainer";
import { Tooltip } from "components";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { AppRouter } from "./AppRouter";

import "./react-modal";

function App() {
  return (
    <DndProvider backend={HTML5Backend}>
      <AppRouter />
      <ToastContainer
        hideProgressBar
        closeButton={false}
        position="bottom-center"
      />
      <Tooltip id="tooltip" />
    </DndProvider>
  );
}

export default App;
