import { useToggle } from "@react-hookz/web";
import { ReactComponent as Logo } from "assets/svgs/6sense-logo.svg";
import { useAuth } from "common/hooks/useAuth";
import { Button, CurrentUser, DialogPopup, HamburgerMenu } from "components";
import React, { SyntheticEvent, useCallback } from "react";
import styled from "styled-components";

export const TopBar: React.FC = React.memo(() => {
  const { logout } = useAuth();
  const [isMenuOpen, toggleMenu] = useToggle(false);
  const closeMenu = useCallback(() => toggleMenu(false), [toggleMenu]);
  const toggleNoPropagate = useCallback(
    (event: SyntheticEvent) => {
      event.stopPropagation();
      toggleMenu();
    },
    [toggleMenu]
  );

  return (
    <Wrapper>
      <LeftContent isOpen={isMenuOpen} onClick={closeMenu}>
        <HamburgerMenuWrapper>
          <HamburgerMenu isOpen={isMenuOpen} toggle={toggleMenu} />
        </HamburgerMenuWrapper>
        <Logo onClick={toggleNoPropagate} width="66" height="32" fill="#fff" />
      </LeftContent>
      <StyledDialogPopup
        renderActivator={({ toggle }) => (
          <span onClick={toggle} style={{ cursor: "pointer" }}>
            <CurrentUser />
          </span>
        )}
        renderContent={() => (
          <DialogContent>
            <Button onClick={logout}>Log out</Button>
          </DialogContent>
        )}
      />
    </Wrapper>
  );
});

TopBar.displayName = "TopBar";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 55px;
  background: var(--secondary);
  padding: 1px 28px 2px 18px;
  box-shadow: 0px 3px 6px #00000029;
`;

const LeftContent = styled.div<{ isOpen: boolean }>`
  display: flex;
  align-items: center;

  svg {
    z-index: 1000;
    position: relative;
    cursor: ${({ isOpen }) => (isOpen ? "" : "pointer")};
  }
`;

const HamburgerMenuWrapper = styled.div`
  svg {
    height: 32px;
    vertical-align: middle;
  }
`;

const StyledDialogPopup = styled(DialogPopup)`
  left: calc(50% - 150px / 2);
  width: 100%;
  min-width: 150px;
`;

const DialogContent = styled.div`
  & > button:not(first-of-type) {
    margin-left: 16px;
  }
`;
