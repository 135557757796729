import { useIsDebugEnabled } from "@asantech/common/react/common/hooks/useIsDebugEnabled";

export interface TrafficTableColumn {
  label: string;
  key: string;
}

const commonColumns = [
  { label: "Lane", key: "lane" },
  { label: "Date", key: "date" },
  { label: "Direction", key: "direction" },
  { label: "Alerts", key: "alerts" },
];
const trafficTableColumns = [
  { label: "", key: "expand" },
  { label: "Vehicle", key: "vehicle" },
  ...commonColumns,
];
const trafficTableColumnsDebug = [
  { label: "", key: "expand" },
  { label: "Sent", key: "sent" },
  { label: "Vehicle", key: "detected" },
  ...commonColumns,
];

export const useTrafficTableColumns = () => {
  return useIsDebugEnabled() ? trafficTableColumnsDebug : trafficTableColumns;
};
