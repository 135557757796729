import { snb3rdPartyEnabled } from "../config";
import { SettingBoolValue, SettingConfig, SystemSetting } from "./types";

export const settingsConfig: Record<SystemSetting, SettingConfig> = {
  PLATETECH_API_URL: {
    isHttpUrl: true,
    description:
      "URL for platetech API that should receive detections. If empty or not defined, platetech sending will be disabled.",
  },
  PLATETECH_TRIGGER_ENABLED: {
    isBoolean: true,
    description:
      "Whether to wait for Platetech trigger call before sending detections.",
    validate: (values: unknown[]) => {
      if (values?.[0] === SettingBoolValue.True && snb3rdPartyEnabled) {
        return [
          "At most one request based notification type can be enabled at a time",
        ];
      }
    },
    defaults: [SettingBoolValue.False],
  },
  PLATETECH_DIRECTION_REQUIRED_FOR_EXTENDED_LPR_DATA: {
    isBoolean: true,
    description:
      "Whether to always wait for direction detection when sending LPRDataEx data messages.",
    defaults: [SettingBoolValue.True],
  },
  PLATETECH_TRIGGER_AUTH_WHITELIST: {
    isMultiValue: true,
    isIpAddress: true,
    description:
      "List of IP addresses that will be authorized automatically for Platetech software trigger endpoints.",
  },
  PLATETECH_SEND_EXTENDED_LPR_DATA: {
    isBoolean: true,
    description:
      "Whether to send LPRDataEx data instead of LPRData for Platetech.",
    defaults: [SettingBoolValue.False],
  },
  PLATETECH_WAIT_AFTER_IMAGE_WRITE: {
    isNumber: true,
    description:
      "How many milliseconds to wait after writing image file(s) before proceeding to send to Platetech.",
    defaults: ["0"],
  },
  SNB_API_URL: {
    isHttpUrl: true,
    description:
      "URL to the S&B API that should receive vehicle events. If empty or not defined, S&B sending will be disabled.",
  },
  SNB_API_USERNAME: {
    description:
      "User name to be used in the S&B API to send vehicle events. If empty or not defined, S&B sending will be disabled.",
  },
  SNB_API_PASSWORD: {
    description:
      "User password to be used in the S&B API to send vehicle events. If empty or not defined, S&B sending will be disabled.",
  },
  SNB_IP_AUTH_WHITELIST: {
    isMultiValue: true,
    isIpAddress: true,
    description:
      "List of IP addresses that will be authorized automatically for S&B 3rd party endpoints.",
  },
  TASKUPARKKI_API_URL: {
    isMultiValue: true,
    isHttpUrl: true,
    description:
      "URL to the Taskuparkki API that should receive vehicle events. If empty or not defined, sending will be disabled.",
  },
  TASKUPARKKI_API_USERNAME: {
    description:
      "User name to be used in the Taskuparkki API to send vehicle events. If defined then basic auth will be used.",
  },
  TASKUPARKKI_API_PASSWORD: {
    description:
      "User password to be used in the Taskuparkki API to send vehicle events.",
  },
  PARKING_HALL_ID: {
    description: `Globally unique value identifying the parking hall where the backend is running.
      If set to an integer, will be sent as number, otherwise as string.`,
  },
  INCLUDED_PLATES_ONLY: {
    isBoolean: true,
    isReadOnly: true,
    description: `Affects which plates get sent to integrations.
    When enabled, only included plates are sent (if not excluded).
    When disabled, all plates are sent (if not excluded).`,
    defaults: [SettingBoolValue.False],
  },
  POST_NOTIFICATION_URLS: {
    isMultiValue: true,
    isHttpUrl: true,
    description:
      "Where to send generic http POST notifications about detected vehicles.",
  },
  INFO_DISPLAY_STATIC_ENTRANCE_MESSAGE: {
    isReadOnly: true,
    description:
      "Entrance message that is always visible on the info display. This message is defined in anpr-edges.",
    defaults: [],
  },
  INFO_DISPLAY_WELCOME_MESSAGE: {
    description: "Info display entrance message.",
    defaults: ["Welcome!"],
  },
  INFO_DISPLAY_CONTRACT_WELCOME_MESSAGE: {
    description:
      "Info display entrance message for plates included in company contracts.",
    defaults: ["Welcome!"],
  },
  INFO_DISPLAY_OVERPARKING_MESSAGE: {
    description:
      "Info display entrance message shown when overparking is detected.",
    defaults: ["All company slots are in use"],
  },
  INFO_DISPLAY_BYE_MESSAGE: {
    description:
      "Message to be displayed on the info display when vehicle leaves parking hall.",
    defaults: ["Thank you!"],
  },
  INFO_DISPLAY_PARKING_OPERATED_BY_MESSAGE: {
    description:
      "Message to be displayed on the info display informing about the payment provider.",
    defaults: ["Your parking is operated by "],
  },
  INFO_DISPLAY_PARKING_PAID_MESSAGE: {
    description:
      "Message to be displayed on the info display informing that parking has been paid.",
    defaults: ["Your parking has been paid"],
  },
  INFO_DISPLAY_PARKING_NOT_PAID_MESSAGE: {
    description:
      "Info display exit message shown when parking hasn't been paid.",
    defaults: ["Your parking has not been paid"],
  },
  INFO_DISPLAY_PARKING_WAS_FREE_MESSAGE: {
    description:
      "Info display exit message shown when parking was free of charge.",
    defaults: ["Your parking was free"],
  },
  INFO_DISPLAY_MAX_TIME: {
    description:
      "Maximum time plate is visible in milliseconds. Relative to the moment of detection.",
    defaults: ["30000"],
  },
  INFO_DISPLAY_WAIT_TIME: {
    description:
      "How many milliseconds to wait before focusing to new plate when showing multiple plates. Relative to the moment of detection.",
    defaults: ["5000"],
  },
  INFO_DISPLAY_SIMULTANEOUS_PLATES: {
    description: "How many plates to show at the same time.",
    defaults: ["2"],
  },
  MAX_AGE_FOR_REQUEST_FULFILLING_DETECTIONS_MS: {
    isNumber: true,
    description: "How old detections can be used to fulfill requests.",
    defaults: ["3000"],
  },
  REQUESTS_TTL_MS: {
    isNumber: true,
    description: "How long to keep requests in memory before removing them.",
    defaults: ["3000"],
  },
  FALLBACK_BEFORE_TIMEOUT_MS: {
    isNumber: true,
    description:
      "How close to request expiration to wait for high quality detection before falling back to a low quality detection.",
    defaults: ["500"],
  },
};
