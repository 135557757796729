import React, { ReactNode } from "react";

import styled from "styled-components";

interface Props {
  value: boolean;
  onClick: () => void;
  variant?: "error" | "warning" | "primary" | "secondary";
  disabled?: boolean;
  children?: ReactNode;
}

export const Filter: React.FC<Props> = ({
  children,
  onClick,
  value,
  variant = "tertiary",
  disabled = false,
}) => {
  return (
    <Btn
      className={variant}
      onClick={onClick}
      active={value}
      disabled={disabled}
    >
      {children}
    </Btn>
  );
};

const Btn = styled.button<{ active: boolean }>`
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  background: var(--tertiary);
  color: var(--text-secondary);
  padding: 7px 24px 9px;
  border-radius: 3em;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0px;
  font-weight: bold;
  border: 1px solid transparent;
  border-color: ${({ active }) => active && "var(--text-primary)"};
  outline: 0;
  box-shadow: none;
  min-height: 34px;

  ${({ active }) =>
    active &&
    `
    color: var(--text-primary);

    &.primary {
      background: var(--tertiary);
      border-color: var(--text-primary);
    }

    &.secondary {
      background: var(--info);
      border-color: var(--contrast);
    }

    &.error {
      background: var(--alert);
      border-color: #972F21;
    }

    &.warning {
      background: var(--warning);
      border-color: #F7CA47;
    }
  `}

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;
