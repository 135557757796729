import { useAsync } from "@react-hookz/web";
import { downloadCSVDetections } from "api/detections";
import {
  Button,
  DateRangePicker,
  DateRangeValue,
  FiltersBar,
  TopBar,
} from "components";
import { useCallback, useEffect, useMemo } from "react";
import { useDetections } from "store/detections/Detections.context";
import { useLanes } from "store/Lanes.context";
import styled from "styled-components";

import { PlateFilterField } from "views/traffic/PlateFilterField";
import { TrafficAuditLogTable } from "views/traffic/TrafficAuditLogTable";

const useCsvExport = () => {
  const { filters } = useDetections();
  const [csvExportStatus, { execute: doCSVExport }] = useAsync(async () => {
    const { orphaned, lowConfidence, ...otherFilters } = filters;

    const qsParams = {
      noDuplicates: true,
      ...otherFilters,
      ...(lowConfidence ? { lowConfidence: true } : {}),
      ...(orphaned ? { orphan: true } : {}),
    };

    await downloadCSVDetections(qsParams);
  });

  return { status: csvExportStatus, doExport: doCSVExport };
};

export const TrafficAuditLogView = () => {
  const { doFetchLanes, lanes } = useLanes();
  const {
    detections,
    detectionsCount,
    doFetchDetections,
    filters,
    canLoadMore,
    isLoading,
    setFilters,
    logTableFirstItemIndex,
    logTableScrollTop,
  } = useDetections();

  const csvExport = useCsvExport();

  const handleScrolledToEnd = useCallback(() => {
    if (canLoadMore) doFetchDetections();
  }, [canLoadMore, doFetchDetections]);

  const handleSearch = useCallback(
    (searchTerm: string) => {
      setFilters((prev) =>
        (searchTerm || undefined) === prev.plateText
          ? prev
          : {
              ...prev,
              plateText: searchTerm || undefined,
            }
      );
    },
    [setFilters]
  );

  const handleDateRangeChange = useCallback(
    (dateRange: DateRangeValue) => {
      setFilters((prev) => ({
        ...prev,
        fromDate: dateRange?.[0] || null,
        toDate: dateRange?.[1] || null,
      }));
    },
    [setFilters]
  );

  const datePickerValue = useMemo(
    (): DateRangeValue => [filters.fromDate, filters.toDate],
    [filters.fromDate, filters.toDate]
  );

  useEffect(() => {
    doFetchLanes();
  }, [doFetchLanes]);

  return (
    <Container>
      <TopBar />
      <TrafficFilters>
        <TrafficFiltersCell hasPadding={false}>
          <FiltersBar />
        </TrafficFiltersCell>
        <TrafficFiltersCellGroup>
          <TrafficFiltersCell>
            <PlateFilterField onSearch={handleSearch} />
          </TrafficFiltersCell>
          <TrafficFiltersCell>
            <DateRangePicker
              onChange={handleDateRangeChange}
              value={datePickerValue}
              maxDate={new Date()}
              timeSelection
            />
          </TrafficFiltersCell>
          <Button
            disabled={csvExport.status.status === "loading"}
            onClick={csvExport.doExport}
          >
            {csvExport.status.status !== "loading" ? "Export" : "Please wait.."}
          </Button>
        </TrafficFiltersCellGroup>
      </TrafficFilters>
      <Content>
        <TableContainer>
          <TrafficAuditLogTable
            detectionsCount={detectionsCount}
            detections={detections || []}
            lanes={lanes || []}
            dataLoading={isLoading || csvExport.status.status === "loading"}
            dataLoadingKey={JSON.stringify(filters)}
            onScrolledToEnd={handleScrolledToEnd}
            firstItemIndex={logTableFirstItemIndex}
            scrollTopRef={logTableScrollTop}
          />
        </TableContainer>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const TrafficFilters = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  background: var(--secondary);
  padding: 0 16px;
  & .filters-bar {
    padding: 8px 0;
  }
`;

interface TrafficFiltersCellProps {
  hasPadding?: boolean;
}

const TrafficFiltersCellGroup = styled.div`
  display: flex;
  align-items: center;
`;

const TrafficFiltersCell = styled.div<TrafficFiltersCellProps>`
  padding: ${({ hasPadding = true }) => (hasPadding ? "8px 8px 8px 0" : 0)};
  &:last-of-type {
    padding: ${({ hasPadding = true }) =>
      hasPadding ? "8px 8px 8px 16px" : 0};
  }
`;

const Content = styled.div`
  background: var(--primary);
  flex: 1;
  padding: 16px;
  padding-bottom: 32px;
`;

const TableContainer = styled.div`
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
  background: var(--primary);
`;
