import { AreaEditor } from "@asantech/common/react/CamPreview/AreaEditor";
import {
  percentageToRatioCorners,
  ratioToPercentageCorners,
} from "@asantech/common/react/CamPreview/coordinates-utils";
import { useVideoMeasure } from "@asantech/common/react/CamPreview/KonvaVideoWrapper";
import { CornerPos } from "@asantech/common/react/CamPreview/types";
import { useIsDebugEnabled } from "@asantech/common/react/common/hooks/useIsDebugEnabled";
import { LiveVideo } from "@asantech/common/react/LiveVideo/LiveVideo";
import { useToggle } from "@react-hookz/web";
import { AxiosError, getLiveStream, handleError } from "api/client";
import { createLane, updateLane as updateLaneRequest } from "api/lanes";
import { ReactComponent as InfoIcon } from "assets/svgs/alert-info-icon.svg";
import { ReactComponent as MinusIcon } from "assets/svgs/minus-icon.svg";
import { ReactComponent as PlusIcon } from "assets/svgs/plus-icon.svg";
import { DEFAULT_MASK_TO_SCAN } from "common/consts";
import { handleFormSubmitError, nameValidator } from "common/formUtils";
import { directionDropdownOptions } from "common/utils";
import {
  Button,
  Card,
  CardColumn,
  CardRow,
  CardSubtitle,
  Checkbox,
  Dropdown,
  FieldErrorComponent,
  FormSidebar,
  Loader,
  TextField,
  TooltipDialog,
} from "components";
import { Body } from "components/FormSidebar";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useLanes } from "store/Lanes.context";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import {
  ExternalLaneId,
  Lane,
  LaneDirection,
  VideoSource,
  VideoSourceOrientation,
} from "../../../../../backend/src/lanes/types";
import { Form } from "../Form";
import { TriggerMappingSelect } from "../TriggerMappingSelect/TriggerMappingSelect";
import { useLaneTriggerMappingHook } from "../TriggerMappingSelect/useLaneTriggerMappingHook";

type FormData = Omit<Lane, "videoSources" | "externalIds"> & {
  videoSources: {
    deviceId: string;
    rtspUrl?: string;
    areaToScan?: CornerPos[];
    orientation: VideoSourceOrientation | "";
  }[];
  externalIds: Record<string, ExternalLaneId>;
};

const orientationDropdownOptions = [
  { value: "looking-in", label: "Looking in" },
  { value: "looking-out", label: "Looking out" },
];

const newLane = {
  name: "",
  direction: "out" as LaneDirection,
  videoSources: [],
  externalIds: {},
  minimizeNotifiersDelay: true,
};

const formDataToLane = (formData: FormData) => {
  const videoSources = formData.videoSources as VideoSource[];

  return { ...formData, videoSources };
};

type AreaEditorWrapperProps = {
  value?: CornerPos[];
  onChange: (value?: CornerPos[]) => void;
  editMode: boolean;
  videoSource: {
    deviceId: string;
    areaToScan?: CornerPos[];
    rtspUrl?: string;
  };
  isRtsp: boolean;
  isFocusedOnRtspInputField: boolean;
};

const AreaEditorWrapper = (props: AreaEditorWrapperProps) => {
  const {
    value,
    onChange,
    editMode,
    videoSource,
    isRtsp,
    isFocusedOnRtspInputField,
  } = props;
  const { setAspectRatio, ...videoMeasure } = useVideoMeasure();
  return (
    <AreaEditor
      cornersInitial={value && ratioToPercentageCorners(value)}
      drawingActive={editMode}
      videoMeasure={videoMeasure}
      onDrawingChange={(corners) => {
        const cornersStr = corners && percentageToRatioCorners(corners);

        if (
          JSON.stringify(videoSource.areaToScan) !== JSON.stringify(cornersStr)
        ) {
          onChange(cornersStr);
        }
      }}
    >
      {isRtsp && (!videoSource.rtspUrl || isFocusedOnRtspInputField) ? null : (
        <LiveVideo
          cameraId={videoSource.deviceId}
          onError={handleError}
          getLiveStream={getLiveStream}
          setAspectRatio={setAspectRatio}
          useAsanPlayer={!isRtsp}
          rtspStreamUrl={videoSource.rtspUrl}
        />
      )}
    </AreaEditor>
  );
};

export const LaneSidebar = (props: {
  addMode: boolean;
  toggleAddMode: (addMode?: boolean) => void;
  selectedLaneId?: string;
}) => {
  const { addMode, toggleAddMode, selectedLaneId } = props;
  const {
    devices,
    deviceFetchState,
    lanes,
    selectLane,
    addLane,
    updateLane,
    notifiers,
  } = useLanes();
  const enabledNotifiers = notifiers.result || [];

  const [laneEditMode, toggleEditMode] = useToggle(false);

  useEffect(() => {
    toggleEditMode(false);
  }, [selectedLaneId, toggleEditMode]);

  const triggerMappingHook = useLaneTriggerMappingHook();
  const {
    updatedTriggerMapping,
    executeTriggerRemove,
    executeTriggerUpdate,
    triggerMappingSelectedLane,
    setUpdatedTriggerMapping,
    isTriggerMappingLoading,
    isTriggerUsed,
  } = triggerMappingHook;

  const editMode = addMode || laneEditMode;

  const selectedLane = useMemo(
    () => lanes.find((lane) => lane.id === selectedLaneId) || { ...newLane },
    [lanes, selectedLaneId]
  );
  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    register,
    reset,
    setError,
    watch,
    getValues,
    setValue,
  } = useForm<FormData>({
    defaultValues: selectedLane,
    mode: "all",
  });
  const {
    fields: videoSources,
    append: addVideoSource,
    remove: removeVideoSource,
  } = useFieldArray<FormData>({
    control,
    name: "videoSources",
  });

  const videoSourceValues = watch("videoSources");
  const direction = watch("direction");
  const isDebugMode = useIsDebugEnabled();
  const [isRtsp, setIsRtspChecked] = useState(
    !!videoSourceValues?.[0]?.rtspUrl
  );
  const hasDifferentIds = useMemo(
    () =>
      !!Object.values(selectedLane.externalIds).find(
        (extIds) => extIds.externalIdIn !== extIds.externalIdOut
      ),
    [selectedLane]
  );
  const [separateExtIds, setSeparateExtIds] = useState(
    direction === "two-way" || hasDifferentIds
  );
  const handleChange = useCallback(() => {
    setIsRtspChecked((val) => !val);
    setValue("videoSources", [], {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
  }, [setValue]);

  const handleSeparateExtIdsChanged = useCallback(() => {
    const externalIds = getValues("externalIds");

    Object.entries(externalIds).forEach(([notifier, extIds]) => {
      setValue(`externalIds.${notifier}.externalIdOut`, extIds.externalIdIn);
    });
    setSeparateExtIds((val) => !val);
  }, [getValues, setValue]);

  const getVideoSourceDropdownOptions = useCallback(
    (value: string) => {
      const ids = videoSourceValues?.map((s) => s.deviceId);

      return devices
        .filter(
          (d) =>
            d.isCamera && (value === d.deviceId || !ids?.includes(d.deviceId))
        )
        .map((d) => ({
          label: d.deviceName,
          value: d.deviceId,
        }));
    },
    [devices, videoSourceValues]
  );

  const handleVideoSourceAddClicked = useCallback(() => {
    addVideoSource({
      deviceId: isRtsp ? uuidv4() : "",
      rtspUrl: "",
      orientation: "",
    });
  }, [addVideoSource, isRtsp]);

  const [isFocusedOnRtspInputField, setIsFocusedOnRtspInputField] =
    useState(false);

  const handleFormSubmit = useCallback(
    (toggleEditMode: (isEdit: boolean) => void) =>
      handleSubmit(async (formData) => {
        try {
          formData.videoSources.forEach((videoSource) => {
            if (videoSource.areaToScan?.length === 0) {
              videoSource.areaToScan = DEFAULT_MASK_TO_SCAN as CornerPos[];
            }
          });
          const data = formDataToLane(formData);
          const triggerId = triggerMappingSelectedLane?.id;
          const response = await (selectedLaneId
            ? updateLaneRequest(selectedLaneId, data)
            : createLane(data));

          selectedLaneId ? updateLane(response.data) : addLane(response.data);

          if (!selectedLaneId) selectLane(response.data.id);
          if (updatedTriggerMapping) {
            await executeTriggerUpdate(response.data.id, updatedTriggerMapping);
          } else if (updatedTriggerMapping === null && triggerId) {
            await executeTriggerRemove(triggerId);
          }

          toast.success("Lane configuration updated succesfully");
          toggleAddMode(false);
          toggleEditMode(false);
        } catch (error) {
          const reason = (error as AxiosError<{ error?: string }>).response
            ?.data?.error;
          handleFormSubmitError(error, setError, reason);
          return false;
        }
      })(),
    [
      handleSubmit,
      triggerMappingSelectedLane?.id,
      selectedLaneId,
      updateLane,
      addLane,
      selectLane,
      updatedTriggerMapping,
      toggleAddMode,
      executeTriggerUpdate,
      executeTriggerRemove,
      setError,
    ]
  );

  const handleFormEditCancel = useCallback(() => {
    reset(selectedLane);
    setUpdatedTriggerMapping(undefined);
  }, [reset, selectedLane, setUpdatedTriggerMapping]);

  return (
    <FormWrapper
      title={
        editMode
          ? `${addMode ? "Adding a new" : "Editing"} lane`
          : "Lane settings"
      }
      secondaryTitle={editMode ? "" : "Configuration for selected lane"}
      onCancel={handleFormEditCancel}
      onSubmit={handleFormSubmit}
      editMode={editMode}
      toggleEditMode={toggleEditMode}
      toggleAddMode={toggleAddMode}
      size="largish"
      hasErrors={!isValid}
    >
      <Form>
        <Card size="largish">
          <CardRow>
            <CardColumn>
              <CardSubtitle>Lane name</CardSubtitle>
              <TextField
                register={register}
                name="name"
                registerRules={nameValidator()}
                required={true}
                placeholder="Insert name"
                variant="secondary"
                size="largish"
                errors={errors.name}
              />
              <FieldErrorComponent error={errors.name} />
            </CardColumn>
            <CardColumn>
              <CardSubtitle>Direction</CardSubtitle>
              <Controller
                name="direction"
                control={control}
                render={({ field }) => (
                  <Dropdown
                    {...field}
                    options={directionDropdownOptions}
                    isDisabled={false}
                    size="largish"
                  />
                )}
              />
            </CardColumn>
          </CardRow>

          <Controller
            name="isInternal"
            control={control}
            render={({ field }) => (
              <Checkbox
                {...field}
                label="Is internal lane"
                checked={!!field.value}
              />
            )}
          />
          <Checkbox
            label="Use RTSP video sources"
            onChange={handleChange}
            checked={isRtsp}
          />
          {isDebugMode && (
            <>
              <Controller
                name="disableRecognition"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    label="Disable recognition"
                    checked={!!field.value}
                  />
                )}
              />
            </>
          )}
        </Card>
        {enabledNotifiers.length > 0 && (
          <Fragment>
            <Header>External IDs</Header>
            <CardGrid columns={separateExtIds ? 1 : 2}>
              <Card size="normal">
                {enabledNotifiers.map((notifier) => (
                  <CardRow key={notifier}>
                    <NotifierNameColumn>{notifier}</NotifierNameColumn>
                    <CardColumn>
                      <CardSubtitle>
                        {separateExtIds
                          ? "Ext. ID for incoming traffic"
                          : "External ID"}
                      </CardSubtitle>
                      <TextField
                        register={register}
                        name={`externalIds.${notifier}.externalIdIn`}
                        type="text"
                        required={false}
                        placeholder="Insert ID or leave blank to disable"
                        variant="secondary"
                        size="largish"
                        errors={errors?.externalIds?.[notifier]?.externalIdIn}
                      />
                      <FieldErrorComponent
                        error={errors?.externalIds?.[notifier]?.externalIdIn}
                      />
                    </CardColumn>

                    {separateExtIds && (
                      <CardColumn>
                        <CardSubtitle>
                          Ext. ID for outgoing traffic
                        </CardSubtitle>
                        <TextField
                          register={register}
                          name={`externalIds.${notifier}.externalIdOut`}
                          type="text"
                          required={false}
                          placeholder="Insert ID or leave blank to disable"
                          variant="secondary"
                          size="largish"
                          errors={
                            errors?.externalIds?.[notifier]?.externalIdOut
                          }
                        />
                        <FieldErrorComponent
                          error={errors?.externalIds?.[notifier]?.externalIdOut}
                        />
                      </CardColumn>
                    )}
                  </CardRow>
                ))}
                <CardFooter>
                  <Checkbox
                    onChange={handleSeparateExtIdsChanged}
                    label="Separate IDs per direction"
                    checked={separateExtIds}
                    disabled={direction === "two-way"}
                  />
                  <Controller
                    name="minimizeNotifiersDelay"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        label={
                          <>
                            Minimize sending delay
                            <TooltipDialog
                              id="mininize-notifiers-delay"
                              content={
                                <span>
                                  Plates are detected multiple times and the
                                  highest confidence detection is sent.
                                  <br />
                                  <br />
                                  When this is checked, detection is sent as
                                  early as possible.
                                  <br />
                                  <br />
                                  When this is unchecked, sending is delayed
                                  until the vehicle has left the field of view
                                  of at least one camera.
                                  <br />
                                  This increases the chances of sending the best
                                  possible detection for each vehicle.
                                </span>
                              }
                            >
                              <StyledInfoIcon />
                            </TooltipDialog>
                          </>
                        }
                        checked={!!field.value}
                      />
                    )}
                  />
                </CardFooter>
              </Card>
            </CardGrid>
          </Fragment>
        )}
        <Header>
          Video sources ({videoSources.length})
          <TooltipDialog
            id="video-source-tooltip"
            content={
              direction === "two-way" ? (
                <span>
                  The first source should see the whole <br />
                  area and all vehicles driving in or out
                </span>
              ) : (
                <span>
                  The first source should be the <br /> camera that sees the
                  vehicle first
                </span>
              )
            }
          >
            <StyledInfoIcon />
          </TooltipDialog>
        </Header>

        {deviceFetchState.status === "loading" ? (
          <Loader size={30} />
        ) : (
          <CardGrid>
            {videoSources.map((field, index) => (
              <Card
                title={`Source ${index + 1}`}
                key={field.id}
                size="largish"
                actions={
                  <Button
                    onClick={() => removeVideoSource(index)}
                    type="button"
                    variant={"secondary"}
                    size="largish"
                    withIcon
                    noText
                  >
                    <MinusIcon />
                  </Button>
                }
              >
                <CardRow>
                  <CardColumn>
                    <CardSubtitle>Source</CardSubtitle>
                    {!isRtsp ? (
                      <Controller
                        name={`videoSources.${index}.deviceId`}
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Dropdown
                            {...field}
                            options={getVideoSourceDropdownOptions(field.value)}
                            invalid={!!errors.videoSources?.[index]?.deviceId}
                            size="largish"
                            autoWidth
                          />
                        )}
                      />
                    ) : (
                      <TextField
                        register={register}
                        name={`videoSources.${index}.rtspUrl`}
                        placeholder="Insert RTSP source rtsp://..."
                        variant="secondary"
                        size="largish"
                        errors={errors?.videoSources?.[index]?.rtspUrl}
                        required
                        onFocus={() => setIsFocusedOnRtspInputField(true)}
                        onBlur={() => setIsFocusedOnRtspInputField(false)}
                      />
                    )}
                    <FieldErrorComponent
                      error={
                        errors?.videoSources?.[index]?.[
                          isRtsp ? "rtspUrl" : "deviceId"
                        ]
                      }
                    />
                  </CardColumn>
                  <CardColumn>
                    <CardSubtitle>
                      Orientation
                      <TooltipDialog
                        content={
                          <span>
                            Orientation is needed to determine whether vehicles
                            are entering or exiting. <br />
                            <br />
                            Choose “Looking in” if vehicles moving towards
                            camera are exiting.
                            <br />
                            Choose “Looking out” if vehicles moving towards
                            camera are entering.
                          </span>
                        }
                      >
                        <StyledInfoIcon />
                      </TooltipDialog>
                    </CardSubtitle>
                    <Controller
                      name={`videoSources.${index}.orientation`}
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Dropdown
                          {...field}
                          options={orientationDropdownOptions}
                          size="largish"
                        />
                      )}
                    />
                    <FieldErrorComponent
                      error={errors?.videoSources?.[index]?.orientation}
                    />
                  </CardColumn>
                </CardRow>

                <MaskWrapper>
                  <CardSubtitle>Mask</CardSubtitle>
                  <Mask>
                    <Controller
                      name={`videoSources.${index}.areaToScan`}
                      control={control}
                      render={({ field }) => (
                        <AreaEditorWrapper
                          value={field.value}
                          onChange={field.onChange}
                          videoSource={videoSourceValues[index]}
                          editMode={editMode}
                          isRtsp={isRtsp}
                          isFocusedOnRtspInputField={isFocusedOnRtspInputField}
                        />
                      )}
                    />
                  </Mask>
                </MaskWrapper>
              </Card>
            ))}
          </CardGrid>
        )}
        <ButtonWrapper>
          <Button
            withIcon
            noText
            type="button"
            onClick={handleVideoSourceAddClicked}
            size="largish"
            variant="tertiary"
          >
            <PlusIcon />
          </Button>
        </ButtonWrapper>
        {isTriggerUsed ? (
          <Fragment>
            <Header>
              Gate open configuration
              <TooltipDialog
                id="gate-config-tooltip"
                content={
                  <span>
                    Select a UVP device trigger to be <br /> fired on a
                    successful detection
                  </span>
                }
              >
                <StyledInfoIcon />
              </TooltipDialog>
            </Header>
            <Card size="largish">
              {isTriggerMappingLoading ? (
                <Loader size={30} />
              ) : (
                <TriggerMappingSelect
                  key={`${editMode}${selectedLaneId}`}
                  value={
                    updatedTriggerMapping === null
                      ? undefined
                      : updatedTriggerMapping || triggerMappingSelectedLane
                  }
                  onChange={setUpdatedTriggerMapping}
                />
              )}
            </Card>
          </Fragment>
        ) : null}
      </Form>
    </FormWrapper>
  );
};

const FormWrapper = styled(FormSidebar)`
  ${Body} {
    padding: 30px;
    padding-bottom: 10px;
  }

  header {
    padding: 20px 30px;
    height: auto;
    font-size: 16px;
  }

  h4 {
    font-size: 18px;
    line-height: 25px;
  }

  .react-tooltip {
    font-size: 16px;
  }
`;

const Header = styled.div`
  display: flex;
  margin-bottom: 20px;
  margin-top: 5px;
  font-size: 16px;
`;

const CardGrid = styled.div<{ columns?: number }>`
  display: grid;
  grid-template-columns: ${(props) => "1fr ".repeat(props.columns || 2)};
  @media (max-width: 1280px) {
    grid-template-columns: 1fr;
  }
  grid-gap: 20px;
  margin-bottom: 20px;

  & > div {
    margin-bottom: 0;
  }
`;

const MaskWrapper = styled.div`
  margin-top: 20px;
`;

const NotifierNameColumn = styled(CardColumn)`
  font-size: 16px;
  color: var(--text-primary);
  line-height: 22px;
  min-width: 100px;
  flex-grow: unset;
  &::first-letter {
    text-transform: capitalize;
  }
`;
const Mask = styled.div`
  position: relative;
  padding-bottom: 56.25%;

  & > div {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--secondary);
  }

  .streamerrorbox {
    width: auto !important;
    position: absolute;
    text-align: center;
    user-select: none;
    z-index: 0 !important;
    margin: -50% auto !important;
  }
`;

const ButtonWrapper = styled.div`
  margin-bottom: 20px;
`;

const StyledInfoIcon = styled(InfoIcon)`
  margin: 0 10px;
  position: relative;
  top: 1px;
  width: 16px;
  height: 16px;
`;

const CardFooter = styled.div`
  padding-top: 5px;
  float: right;
`;
