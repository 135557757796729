import styled from "styled-components";

export const TableSelectableRow = styled.tr<{
  selected?: boolean;
}>`
  & td,
  & th {
    word-break: break-all;
    background-color: ${({ selected }) =>
      selected ? "#313740!important" : "var(--secondary)"};
  }
`;
