import { AxiosError, handleError } from "api/client";
import { FieldPath, FieldValues, UseFormSetError } from "react-hook-form";

export const PLATE_COUNTRY_CODE_MAX_LENGTH = 3;
export const PLATE_TEXT_MAX_LENGTH = 10;
export const NAME_GENERAL_MAX_LENGTH = 100;

export type ValidationError = {
  path: string;
  message: string;
};

/**
 * Attempts to display validation errors using the setError function
 * @returns False if the error wasn't a validation error, otherwise True
 */
const setInputValidationErrors = <T extends FieldValues>(
  axiosError: AxiosError<ValidationError & { errors?: ValidationError[] }>,
  setError: UseFormSetError<T>
) => {
  const message = axiosError.response?.data?.message;
  if (message !== "Input validation error") return false;

  const errors = (axiosError.response?.data?.errors || []) as ValidationError[];

  errors.forEach((error) => {
    const field = error.path.replace(".body.", "") as FieldPath<T>;
    setError(field, {
      type: "server",
      message: error.message,
    });
  });
  return true;
};

export const handleFormSubmitError = <T extends FieldValues>(
  error: unknown,
  setError: UseFormSetError<T>,
  errorMessage?: string
) => {
  if (
    !setInputValidationErrors(
      error as AxiosError<ValidationError>,
      setError as UseFormSetError<FieldValues>
    )
  )
    handleError(error, errorMessage || (error as Error).message);
};

export const maxLengthValidator = (maxLength: number) => ({
  maxLength: {
    value: maxLength,
    message: `Max length of ${maxLength} characters exceeded`,
  },
});

export const nameValidator = (maxLength = NAME_GENERAL_MAX_LENGTH) =>
  maxLengthValidator(maxLength);

export const isValidHttpUrlValidator = () => ({
  pattern: {
    value:
      /^(http(s)?:\/\/)[\w-]+(\.[\w-]+)*(:[0-9]+)?[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/i,
    message: "A valid http(s) URL is required",
  },
});

export const isValidIpAddress = () => ({
  pattern: {
    value:
      /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/,
    message: "A valid IP address is required",
  },
});
