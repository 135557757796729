import { toast } from "react-toastify";
import {
  LiveStreamRequestParams,
  LiveStreamResponse,
} from "@asantech/common/react/LiveVideo/types";
import createClient, { AxiosError } from "@asantech/common/react/apiClient";
import { history } from "../App/history";
import { backendRootUrl } from "../config";
import { ApiEndpoints } from "./endpoints";
export * from "@asantech/common/react/apiClient";

export const { client, handleError } = createClient(backendRootUrl, history);

export async function getLiveStream(params: LiveStreamRequestParams) {
  try {
    const response = await client.post<LiveStreamResponse>(
      `${ApiEndpoints.LiveStream}`,
      params
    );
    return response.data;
  } catch (e) {
    let errorMessage = "Something went wrong getting live stream";
    if (e instanceof AxiosError && e.response?.status === 400) {
      errorMessage = e.response.data.error;
    }
    toast.error(`Error in displaying stream: ${errorMessage}`);
    throw e;
  }
}

export default client;
