import { AsantechDevice } from "@asantech/common/asantech-client";
import {
  AsyncState,
  useAsync,
  useList,
  useMountEffect,
} from "@react-hookz/web";
import {
  axiosStatic as axios,
  CancelTokenSource,
  handleError,
} from "api/client";
import {
  getDevices,
  getLanes,
  getNotifiers,
  updateLanesOrder,
} from "api/lanes";
import { Pages } from "common/pages";
import { ChildrenOnly } from "common/types";
import React, {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import { Direction } from "../../../backend/src/detections/types";
import { Lane } from "../../../backend/src/lanes/types";
import { ApiError } from "../api/types";
import { useSystemStatusSocket } from "../common/hooks";

export type LaneKeepScrollState = {
  scrollTop?: number;
  isFocused?: boolean;
};

export type LanesKeepScrollState = Record<
  string,
  Partial<Record<Direction, LaneKeepScrollState>>
>;

export type LanesContextType = {
  lanesResponseState: AsyncState<Lane[] | undefined>;
  lanes: Lane[];
  notifiers: AsyncState<string[] | undefined>;
  lanesKeepScrollState: LanesKeepScrollState;
  setLaneKeepScrollState: (
    laneId: string,
    direction: Direction,
    dispatch: (prev: LaneKeepScrollState) => LaneKeepScrollState
  ) => void;
  addLane: (...items: Lane[]) => void;
  deleteLane: (item: string) => void;
  updateLane: (item: Lane) => void;
  selectedLaneId: string | undefined;
  selectLane: React.Dispatch<React.SetStateAction<string | undefined>>;
  moveLane: (index: number, newIndex: number) => void;
  doFetchLanes: () => Promise<Lane[]>;
  dataLoading: boolean;
  devices: AsantechDevice[];
  getDeviceById: (id: string) => AsantechDevice | undefined;
  deviceFetchState: AsyncState<AsantechDevice[]>;
  doFetchDevices: () => Promise<AsantechDevice[]>;
  doUpdateLanesOrder: () => Promise<void>;
};

export const LanesContext = createContext<LanesContextType | undefined>(
  undefined
);

export const LanesContextProvider = ({ children }: ChildrenOnly) => {
  useSystemStatusSocket();
  const history = useHistory();
  const [selectedLaneId, selectLane] = useState<string>();
  const axiosSource = useRef<CancelTokenSource>();

  const [
    lanes,
    { set: setLanes, update: updateLaneBy, filter: filterLanes, push: addLane },
  ] = useList<Lane>([]);
  const updateLane = useCallback(
    (lane: Lane) => updateLaneBy((item) => item.id === lane.id, lane),
    [updateLaneBy]
  );
  const deleteLane = useCallback(
    (laneId: string) => filterLanes((item) => item.id !== laneId),
    [filterLanes]
  );

  const [lanesKeepScrollState, setLanesKeepScrollState] =
    useState<LanesKeepScrollState>({});

  const setLaneKeepScrollState = useCallback(
    (
      laneId: string,
      direction: Direction,
      dispatch: (prevLaneState: LaneKeepScrollState) => LaneKeepScrollState
    ) =>
      setLanesKeepScrollState((prev) => {
        prev[laneId] = prev[laneId] || {};
        prev[laneId][direction] = dispatch(prev[laneId][direction] || {});
        return { ...prev };
      }),
    []
  );

  const [notifiersResponseState, { execute: doFetchNotifiers }] = useAsync(
    async () => (await getNotifiers()).data
  );

  const [lanesResponseState, { execute: doFetchLanes }] = useAsync(async () => {
    try {
      const response = await getLanes();
      if (response.data.length < 1) {
        history.push(Pages.Lanes);
      }
      setLanes(response.data);
      return response.data;
    } catch (error) {
      const message =
        (error as ApiError)?.response?.data?.error ||
        "Cannot fetch lanes. Error occurred.";
      handleError(error, message);
      return [];
    }
  });

  const moveLane = useCallback(
    (index: number, newIndex: number) => {
      const movedLane = lanes[index];
      const newLanes = lanes.filter((_, i) => i !== index);

      newLanes.splice(newIndex, 0, movedLane);
      setLanes(newLanes);
    },
    [lanes, setLanes]
  );

  const [, { execute: doUpdateLanesOrder }] = useAsync(async () => {
    try {
      if (axiosSource.current) axiosSource.current.cancel();
      axiosSource.current = axios.CancelToken.source();
      const axiosConfig = { cancelToken: axiosSource.current.token };
      await updateLanesOrder(
        lanes.map((l) => l.id),
        axiosConfig
      );
      axiosSource.current = undefined;
    } catch (error) {
      const message =
        (error as ApiError)?.response?.data?.error ||
        "Cannot update lanes order. Error occurred.";
      handleError(error, message);
    }
  });

  const [devices, setDevices] = useState<AsantechDevice[]>([]);
  const [deviceFetchState, { execute: doFetchDevices }] = useAsync(async () => {
    try {
      const response = await getDevices();
      if (response.data) {
        setDevices(response.data);
      }
      return response.data;
    } catch (error) {
      const message =
        (error as ApiError)?.response?.data?.error ||
        "Cannot fetch devices. Error occurred.";

      handleError(error, message);
      throw error;
    }
  }, []);

  const getDeviceById = useCallback(
    (deviceId: string) => {
      return devices.find((device) => device.deviceId === deviceId);
    },
    [devices]
  );

  const state: LanesContextType = {
    lanes,
    notifiers: notifiersResponseState,
    lanesKeepScrollState,
    setLaneKeepScrollState,
    lanesResponseState,
    addLane,
    updateLane,
    deleteLane,
    doFetchLanes,
    dataLoading: lanesResponseState.status === "loading",
    selectedLaneId,
    selectLane,
    moveLane,
    devices,
    deviceFetchState,
    doFetchDevices,
    getDeviceById,
    doUpdateLanesOrder,
  };

  useMountEffect(doFetchNotifiers);

  return (
    <LanesContext.Provider value={state}>{children}</LanesContext.Provider>
  );
};

export const useLanes = (): LanesContextType => {
  const context = useContext(LanesContext);
  if (context === undefined)
    throw new Error("useLanes must be used within LanesContextProvider");
  return context;
};
