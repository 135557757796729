import { Pages } from "common/pages";
import { HorizontalNav, TopBar } from "components";
import { useMemo } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import styled from "styled-components";
import { DoorsView, ExcludedPlatesTable, LanesView } from "views/settings";
import {
  SettingBoolValue,
  SystemSetting,
} from "../../../backend/src/settings/types";
import { useSystemSettings } from "../common/hooks/useSystemSettings";
import { IncludedPlatesTable } from "../views/settings/IncludedPlatesTable";
import { Maintenance } from "../views/settings/Maintenance";
import { SystemSettings } from "../views/settings/SystemSettings";

const NAV_LINKS = [
  {
    title: "Lanes",
    to: Pages.Lanes,
  },
  {
    title: "Doors",
    to: Pages.Doors,
  },
  {
    title: "Excluded plates",
    to: Pages.ExcludedPlates,
  },
  {
    title: "Included plates",
    to: Pages.IncludedPlates,
  },
  {
    title: "System settings",
    to: Pages.SystemSettings,
  },
  {
    title: "Maintenance",
    to: Pages.Maintenance,
  },
];

export const SettingsView = () => {
  const { pathname } = useLocation();
  const { settingsMap, loaded } = useSystemSettings();
  const navLinks = useMemo(() => {
    const includedOnlySetting = settingsMap[SystemSetting.INCLUDED_PLATES_ONLY];

    if (!loaded) return [];
    if (
      !includedOnlySetting ||
      includedOnlySetting?.values?.[0] !== SettingBoolValue.True
    ) {
      return NAV_LINKS.filter((page) => page.to !== Pages.IncludedPlates);
    }

    return NAV_LINKS;
  }, [loaded, settingsMap]);

  return (
    <Container>
      <TopBar />
      {loaded && (
        <HorizontalNav
          size={pathname === Pages.Settings ? "largish" : "normal"}
          links={navLinks}
        />
      )}
      <Content>
        <Switch>
          <Route exact path={Pages.Lanes}>
            <LanesView />
          </Route>
          <Route exact path={Pages.Doors}>
            <DoorsView />
          </Route>
          <Route exact path={Pages.ExcludedPlates}>
            <ExcludedPlatesTable />
          </Route>
          <Route exact path={Pages.IncludedPlates}>
            <IncludedPlatesTable />
          </Route>
          <Route exact path={Pages.SystemSettings}>
            <SystemSettings />
          </Route>
          <Route exact path={Pages.Maintenance}>
            <Maintenance />
          </Route>
          <Route>
            <Redirect to={Pages.Lanes} />
          </Route>
        </Switch>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  width: 100%;
  flex: 1;
  background: var(--primary);
  overflow-x: auto;
`;
