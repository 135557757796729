import { initialFirstItemIndex } from "common/consts";
import { ScrollablePane } from "components";
import React, { useEffect, useMemo } from "react";
import { useDetections } from "store/detections/Detections.context";
import { useLanesStats } from "store/detections/useLaneStats";
import styled from "styled-components";
import { Direction } from "../../../../../backend/src/detections/types";
import { Lane, LaneStats } from "../../../../../backend/src/lanes/types";
import { Detection } from "../../../common/utils/detections";
import { LaneColumn } from "../LaneColumn";

interface Props {
  lanes: Lane[];
  getLaneFirstItemIndex: (
    laneId: string,
    direction: Direction
  ) => number | undefined;
}

type DetectionsGroupedByLaneAndDirection = [Lane, Direction, Detection[]];

export const LaneColumns: React.FC<Props> = ({
  lanes,
  getLaneFirstItemIndex,
}) => {
  const { setFilters, filters, detections } = useDetections();
  const lanesStats = useLanesStats();
  const activeLaneIds = filters.lanes;

  const filteredLanes = useMemo(
    () =>
      lanes
        .filter((lane) => activeLaneIds.includes(lane.id))
        .sort((laneA, laneB) => Number(laneA.id) - Number(laneB.id)),
    [lanes, activeLaneIds]
  );

  const detectionsByLaneIdByDirection = useMemo(() => {
    const result: DetectionsGroupedByLaneAndDirection[] = [];
    filteredLanes.forEach((lane) => {
      const laneDetections = (detections || []).filter(
        (d) => d.laneId === lane.id
      );

      if (lane.direction === "two-way") {
        result.push([
          lane,
          "in",
          laneDetections.filter((d) => d.direction === "in"),
        ]);
        result.push([
          lane,
          "out",
          laneDetections.filter((d) => d.direction === "out"),
        ]);
      } else {
        result.push([lane, lane.direction, laneDetections]);
      }
    });
    return result;
  }, [filteredLanes, detections]);

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      plateText: undefined,
      fromDate: null,
      toDate: null,
    }));
  }, [setFilters]);

  return (
    <ScrollablePane scrollByValue={430}>
      {detectionsByLaneIdByDirection.map(([lane, direction, detections]) => {
        let statsKey: keyof LaneStats = "detectionsCount";

        if (lane.direction === "two-way") {
          statsKey =
            direction === "in" ? "detectionsInCount" : "detectionsOutCount";
        }

        return (
          <ColumnsLaneColumn
            key={`lane-${lane.id}-${direction}`}
            id={lane.id}
            title={lane.name}
            direction={direction as Direction}
            count={lanesStats?.[lane.id]?.[statsKey]}
            detections={detections}
            firstItemIndex={
              getLaneFirstItemIndex(lane.id, direction as Direction) ||
              initialFirstItemIndex
            }
          />
        );
      })}
    </ScrollablePane>
  );
};

const ColumnsLaneColumn = styled(LaneColumn)`
  &:first-child {
    margin-left: 54px;
  }

  &:last-child {
    margin-right: 54px;
  }
`;
