import { getLanesStats } from "api/lanes";
import { useDetectionsWebSocket, useDirectionsWebSocket } from "common/hooks";
import { keyBy } from "common/utils";
import { ApiDetection } from "common/utils/detections";
import { startOfToday } from "date-fns";
import { useCallback, useEffect, useState } from "react";
import {
  Direction,
  DirectionMessage,
} from "../../../../backend/src/detections/types";
import { LaneStats } from "../../../../backend/src/lanes/types";
import { useDetections } from "./Detections.context";

export type LanesStatsByLaneId = { [x: string]: LaneStats };

const directionCountKeyMap: Record<
  string,
  "detectionsInCount" | "detectionsOutCount" | undefined
> = {
  in: "detectionsInCount",
  out: "detectionsOutCount",
};

export const useLanesStats = () => {
  const { detections } = useDetections();
  const [lanesStats, setLanesStats] = useState<LanesStatsByLaneId>({});

  const doFetchLanesStats = useCallback(async () => {
    const todayMidnight = startOfToday();
    const stats = (await getLanesStats(todayMidnight)).data;

    const statsByLaneId = keyBy(stats, "laneId");
    setLanesStats(statsByLaneId);
  }, []);

  const updateStats = useCallback(
    (
      detection: { laneId: string; direction: Direction | null },
      prevDetection?: { direction: Direction | null }
    ) => {
      setLanesStats((prev) => {
        const nextStats = prev[detection.laneId]
          ? { ...prev[detection.laneId] }
          : {
              laneId: detection.laneId,
              detectionsCount: 0,
              detectionsInCount: 0,
              detectionsOutCount: 0,
            };
        const destinationKey = directionCountKeyMap[detection.direction || ""];
        const sourceKey = directionCountKeyMap[prevDetection?.direction || ""];

        if (!prevDetection) nextStats.detectionsCount += 1;
        if (destinationKey) nextStats[destinationKey] += 1;
        if (sourceKey) nextStats[sourceKey] -= 1;

        return {
          ...prev,
          [detection.laneId]: nextStats,
        };
      });
    },
    []
  );

  const handleDetectionWsMessage = useCallback(
    (detection: ApiDetection) => {
      if (!detection.duplicateOf) {
        const prevDetection = detections.find((d) => d.id === detection.id);
        updateStats(detection, prevDetection);
      }
    },
    [detections, updateStats]
  );

  const handleDirectionWsMessage = useCallback(
    (message: DirectionMessage["payload"]) => {
      const prevDetection = detections.find(
        (d) => d.vehicleId === message.vehicleId
      );

      if (prevDetection) {
        updateStats(
          { ...prevDetection, direction: message.direction },
          prevDetection
        );
      }
    },
    [detections, updateStats]
  );

  useDetectionsWebSocket(handleDetectionWsMessage);
  useDirectionsWebSocket(handleDirectionWsMessage);

  useEffect(() => {
    void doFetchLanesStats();
  }, [doFetchLanesStats]);

  return lanesStats;
};
