import { useIsDebugEnabled } from "@asantech/common/react/common/hooks/useIsDebugEnabled";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { DisplayPlate } from "./types";

type Props = {
  plates: DisplayPlate[];
  focusIndex: number | undefined;
};

export const DebugPlates = (props: Props) => {
  const isDebugMode = useIsDebugEnabled();
  if (!isDebugMode) return null;
  return <PlatesList {...props} />;
};

const useRefresh = () => {
  const [remaining, setRemaining] = useState(true);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRemaining((prev) => !prev);
    }, 100);

    return () => clearInterval(intervalId);
  }, []);

  return remaining;
};

const PlatesList = ({ plates, focusIndex }: Props) => {
  const now = Date.now();
  useRefresh();

  return (
    <Plates>
      {plates
        .map((plate, idx) => {
          const { payload, focusEnd, message } = plate;
          return (
            <Plate
              key={payload.vehicleId}
              $isFocus={idx === focusIndex}
              $focusEnded={focusEnd.getTime() < now}
            >
              {idx}: {payload.plateText} - {message}{" "}
              {Math.max(0, (focusEnd.getTime() - now) / 1000).toFixed(1)}
            </Plate>
          );
        })
        .reverse()}
    </Plates>
  );
};

const Plate = styled.div<{ $isFocus: boolean; $focusEnded: boolean }>`
  color: ${({ $isFocus, $focusEnded }) =>
    $isFocus ? ($focusEnded ? "#9ff" : "#F00") : "#FFF"};
`;

const Plates = styled.div`
  position: absolute;
`;
