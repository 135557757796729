export enum ApiEndpoints {
  Lanes = "/lanes",
  LanesStats = "/lanes/stats",
  Doors = "/doors",
  Notifiers = "/lanes/notifiers",
  LanesOrder = "/lanes/order",
  Detections = "/detections",
  DetectionsBatch = "/detections/batch",
  DetectionsExport = "/detections/export",
  DetectionsListen = "/detections/listen",
  DetectionsImages = "/detections/images",
  PlatesExcluded = "/plates/excluded",
  PlatesIncluded = "/plates/included",
  SetNewPassword = "/set-new-password",
  Devices = "/lanes/devices",
  DeviceTriggers = "/lanes/device-triggers",
  TriggerMapping = "/lanes/trigger-mapping",
  AppUrls = "/app-urls",
  SystemSettings = "/settings",
  LiveStream = `lanes/livestream`,
  InfoDisplayListen = "/info-display/listen",
  MaintenanceReboot = "/maintenance/reboot",
}
