import { useResizeObserver } from "@react-hookz/web";
import { findLastIndex } from "common/utils";
import { useCallback, useRef, useState } from "react";
import styled from "styled-components";
import { Message, MessageIcon } from "views/infoDisplay/Message";
import { DebugPlates } from "./DebugPlates";
import { DisplayPlate } from "./types";
import { useOrientation } from "./useOrientation";

export const MessageAndPlates = ({
  icon,
  plates,
  focusPlate,
  maxShowTimeMs,
  simultaneousPlates,
  staticMessage,
}: {
  icon: MessageIcon;
  plates: DisplayPlate[];
  focusPlate: DisplayPlate | undefined;
  maxShowTimeMs: number;
  simultaneousPlates: number;
  staticMessage?: string;
}) => {
  const focusIndex = Math.max(
    focusPlate ? plates.indexOf(focusPlate) : plates.length - 1,
    0
  );

  // plates are sorted most recent last
  // show max amount of most recent plates with the same message as the focus plate
  // keep focus plate visible

  const messageForFocused = plates[focusIndex]
    ? plates[focusIndex].message
    : staticMessage;

  let endIndex = plates.findIndex((plate, idx) => {
    const isAfterFocusIdx = idx > focusIndex;
    const maxSimultaneousReached = idx - focusIndex >= simultaneousPlates;

    if (!isAfterFocusIdx) return false;
    if (maxSimultaneousReached) return true;
    if (plate.message === messageForFocused) return false;
    return true;
  });
  if (endIndex === -1) endIndex = plates.length;

  const startIndexExclusive = findLastIndex(plates, (plate, idx) => {
    const isBeforeFocusIdx = idx < focusIndex;
    const maxSimultaneousReached = endIndex - idx - 1 >= simultaneousPlates;

    if (!isBeforeFocusIdx) return false;
    if (maxSimultaneousReached) return true;
    if (plate.message === messageForFocused) return false;
    return true;
  });
  const startIndex = startIndexExclusive + 1;

  const tooOldPlates = startIndex > 0 ? [plates[startIndex - 1]] : [];
  const shownPlates = plates.slice(startIndex, endIndex);
  const tooNewPlates = endIndex < plates.length ? plates.slice(endIndex) : [];
  const renderedPlates = [...tooOldPlates, ...shownPlates, ...tooNewPlates];

  const ref = useRef<HTMLDivElement>(null);
  const [space, setAvailableSpace] = useState({ width: 0, height: 0 });
  const { isVertical } = useOrientation();
  const handleResize = useCallback(
    (entry: ResizeObserverEntry) => {
      const rects = entry.target.parentElement?.getClientRects()[0];
      const fullHeight = isVertical ? rects?.width : rects?.height;
      setAvailableSpace({
        width: entry.contentRect.width,
        height: fullHeight ? fullHeight - entry.borderBoxSize[0].blockSize : 0,
      });
    },
    [isVertical]
  );
  useResizeObserver<HTMLDivElement>(ref, handleResize);

  const rowHeight = space.height / simultaneousPlates;
  const getTopPosition = useCallback(
    (row: number) => `${rowHeight * row}px`,
    [rowHeight]
  );
  const getFontSizePx = useCallback(
    (str = "") => {
      const lengthToFit = Math.max(str.length, 6);
      const fontSizeToWidthRatio = 100 / 60; // with Noto Mono
      const widthBased = fontSizeToWidthRatio * (space.width / lengthToFit);
      const verticalPadding = rowHeight / 15;
      const heightBased = rowHeight - verticalPadding;
      return Math.min(widthBased, heightBased);
    },
    [space.width, rowHeight]
  );

  return (
    <MessageAndPlatesElement>
      <Header ref={ref}>
        {messageForFocused && (
          <Message message={messageForFocused} icon={icon} />
        )}
      </Header>
      <Plates>
        {renderedPlates.reverse().map((plate, i) => {
          const { plateText, vehicleId } = plate.payload;
          const isTooNew = i < tooNewPlates.length;
          const isTooOld = i >= tooNewPlates.length + shownPlates.length;
          const fontSize = getFontSizePx(plateText);
          return (
            <FadeOutWrapper key={vehicleId} $maxShowTimeMs={maxShowTimeMs}>
              {!isTooNew && (
                <PlateText
                  style={{
                    opacity: isTooOld ? 0 : 1,
                    top: getTopPosition(i - tooNewPlates.length),
                    fontSize: `${fontSize}px`,
                    padding: `${fontSize / 50}px ${fontSize / 10}px`,
                    borderRadius: `${fontSize / 10}px`,
                    outline: `${fontSize / 20}px solid #ccc`,
                    outlineOffset: `-${fontSize / 20}px`,
                  }}
                  $topMinusOne={getTopPosition(-1)}
                  $topZero={getTopPosition(0)}
                >
                  {plateText}
                </PlateText>
              )}
            </FadeOutWrapper>
          );
        })}
      </Plates>
      <DebugPlates plates={plates} focusIndex={focusIndex} />
    </MessageAndPlatesElement>
  );
};

const Header = styled.div`
  ${({ theme }) => `
  padding-top: 2${theme.vh};
  padding-bottom: 2${theme.vh};`}
`;

const Plates = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const MessageAndPlatesElement = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  ${({ theme }) => `margin: 0 5${theme.vw};`}
`;

const FadeOutWrapper = styled.div<{ $maxShowTimeMs: number }>`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  left: 0;
  right: 0;
  animation: 0.7s ease-out ${({ $maxShowTimeMs }) => $maxShowTimeMs - 700}ms
    forwards 1 disappear;
  @keyframes disappear {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

const PlateText = styled.p<{
  $topMinusOne: string;
  $topZero: string;
}>`
  background: #e8eaed;
  color: #000;
  line-height: 1;
  font-family: "Noto Mono", Consolas, monospace;
  transition-property: top, opacity;
  transition-duration: 0.2s, 0.2s;
  transition-timing-function: ease-in-out, ease-in-out;
  position: absolute;
  animation: 0.2s ease-in-out 1 appear;
  top: ${({ $topZero: topZero }) => topZero};
  @keyframes appear {
    0% {
      opacity: 0;
      top: ${({ $topMinusOne: topMinusOne }) => topMinusOne};
    }
    100% {
      opacity: 1;
      top: ${({ $topZero: topZero }) => topZero};
    }
  }
`;
