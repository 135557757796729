import { ReactComponent as UnpluggedIcon } from "assets/svgs/unplugged-icon.svg";
import styled from "styled-components";

export const OfflineStatus = () => (
  <Container>
    <UnpluggedIcon /> Offline
  </Container>
);

const Container = styled.span`
  font-weight: bold;

  svg {
    vertical-align: middle;
  }
`;
