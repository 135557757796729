import { useAsync } from "@react-hookz/web";
import { useCallback, useEffect, useState } from "react";
import { useLanes } from "store/Lanes.context";
import {
  TriggerMapping,
  TriggerMappingInput,
} from "../../../../../backend/src/lanes/types";
import {
  getTriggerMapping,
  removeTriggerMapping,
  setTriggerMapping,
} from "../../../api/lanes";

export const useLaneTriggerMappingHook = () => {
  const [isTriggerUsed, setIsTriggerUsed] = useState<boolean>();

  const { selectedLaneId } = useLanes();
  const [updatedTriggerMapping, setUpdatedTriggerMapping] = useState<Omit<
    TriggerMappingInput,
    "laneId"
  > | null>();
  const [triggerMappingSelectedLane, setTriggerMappingSelectedLane] =
    useState<Omit<TriggerMapping, "laneId">>();

  const [triggerMappingRequestState, { execute: doFetchTriggerMapping }] =
    useAsync(async (laneId: string) => {
      try {
        const response = await getTriggerMapping(laneId);
        if (response.status === 204) {
          setIsTriggerUsed(false);
        } else {
          setIsTriggerUsed(true);
        }
        return response.data;
      } catch (e) {
        setIsTriggerUsed(true);
        throw e;
      }
    });

  const fetchTriggerMappingSelectedLane = useCallback(async () => {
    if (selectedLaneId) {
      try {
        setTriggerMappingSelectedLane(
          await doFetchTriggerMapping(selectedLaneId)
        );
      } catch (e) {
        setTriggerMappingSelectedLane(undefined);
      }
    } else {
      setTriggerMappingSelectedLane(undefined);
    }
  }, [doFetchTriggerMapping, selectedLaneId]);

  useEffect(() => {
    if (!selectedLaneId) {
      return setTriggerMappingSelectedLane(undefined);
    }
    fetchTriggerMappingSelectedLane();
  }, [fetchTriggerMappingSelectedLane, selectedLaneId]);

  const executeTriggerUpdate = useCallback(
    async (laneId: string, mapping: Omit<TriggerMappingInput, "laneId">) => {
      const created = await setTriggerMapping({ ...mapping, laneId });
      await fetchTriggerMappingSelectedLane();
      return created;
    },
    [fetchTriggerMappingSelectedLane]
  );

  const executeTriggerRemove = useCallback(
    async (mappingId: string) => {
      await removeTriggerMapping(mappingId);
      await fetchTriggerMappingSelectedLane();
    },
    [fetchTriggerMappingSelectedLane]
  );

  return {
    isTriggerUsed,
    isTriggerMappingLoading: triggerMappingRequestState.status === "loading",
    triggerMappingSelectedLane,
    updatedTriggerMapping,
    setUpdatedTriggerMapping,
    executeTriggerUpdate,
    executeTriggerRemove,
  };
};

export type TriggerMappingHook = ReturnType<typeof useLaneTriggerMappingHook>;
