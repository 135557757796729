import { useToggle } from "@react-hookz/web";
import { ReactComponent as EyeOffIcon } from "assets/svgs/eye-off.svg";
import { ReactComponent as VideoIcon } from "assets/svgs/video.svg";
import { getLowConfidenceAlertText } from "common/utils";
import { Detection, isDetectionLowConfidence } from "common/utils/detections";
import { Button, Timestamp } from "components";
import React, { useCallback, useMemo, useRef } from "react";
import { Virtuoso } from "react-virtuoso";
import { useDetections } from "store/detections/Detections.context";
import { useLanes } from "store/Lanes.context";
import styled from "styled-components";
import { Direction } from "../../../../../backend/src/detections/types";
import { LaneColumnCard } from "../LaneColumnCard";
import { LaneCardLiveStream } from "../LaneColumnCard/LaneCardLiveStream";

const virtualListOverscan = 1500;

interface Props {
  id: string;
  title: string;
  count?: number;
  className?: string;
  direction: Direction;
  detections: Detection[];
  firstItemIndex: number;
}

export const LaneColumn: React.FC<Props> = ({
  id,
  className,
  title,
  count,
  direction,
  detections,
  firstItemIndex,
}) => {
  const isScrolling = useRef(false);
  const scrollTop = useRef(0);

  const { setLaneKeepScrollState, lanes } = useLanes();

  const {
    isLoading,
    hasMoreResults,
    canLoadMore,
    doFetchDetections,
    lastDetectionFetched,
    isFilterActive,
    setFilters,
    selectedImageByDetection,
    setSelectedImageByDetection,
  } = useDetections();

  const handleIsScrolling = useCallback(
    (scrolling: boolean) => {
      isScrolling.current = scrolling;
      setLaneKeepScrollState(id, direction, (prev) => ({
        ...prev,
        scrollTop: scrollTop.current,
      }));
    },
    [id, direction, setLaneKeepScrollState]
  );

  const handleHideLaneClick = useCallback(() => {
    setFilters((prevState) => ({
      ...prevState,
      lanes: prevState.lanes.filter((laneId) => {
        return laneId !== id;
      }),
    }));
  }, [id, setFilters]);

  const handleOnScroll = useCallback((e: React.UIEvent<"div">) => {
    scrollTop.current = (
      e.currentTarget as unknown as HTMLDivElement
    ).scrollTop;
  }, []);

  const handleScrolledToEnd = useCallback(() => {
    if (canLoadMore && isScrolling.current) {
      doFetchDetections();
    }
  }, [canLoadMore, doFetchDetections, isScrolling]);

  const ListItem = useCallback(
    (index: number) => {
      const obj = detections[index - firstItemIndex];
      const gate = obj.direction ? `${title} - ${obj.direction}` : title;
      const lowConfidenceAlertText = isDetectionLowConfidence(obj)
        ? getLowConfidenceAlertText(obj)
        : null;
      const selectedImage = selectedImageByDetection[obj.id] || 0;
      const setSelectedImage = (fn: (index: number) => number) =>
        setSelectedImageByDetection((prev) => ({
          ...prev,
          [obj.id]: fn(prev[obj.id] || 0),
        }));

      return (
        <LaneColumnCard
          key={`detection-${obj.id}`}
          id={obj.id}
          plateText={obj.plateText}
          plateCountry={obj.plateCountry}
          regionCode={obj.regionCode}
          lowConfidenceAlertText={lowConfidenceAlertText}
          date={obj.detectionDate}
          gate={gate}
          thumbnails={obj.thumbnails}
          plateTextConfidence={obj.plateTextConfidence}
          plateCountryConfidence={obj.plateCountryConfidence}
          appliedTextDetectionImageId={obj.appliedTextDetectionImageId}
          appliedCountryDetectionImageId={obj.appliedCountryDetectionImageId}
          orphanReasons={obj?.orphanReasons || []}
          direction={obj.direction}
          laneDirection={direction}
          selectedImage={selectedImage}
          setSelectedImage={setSelectedImage}
        />
      );
    },
    [
      detections,
      direction,
      title,
      firstItemIndex,
      selectedImageByDetection,
      setSelectedImageByDetection,
    ]
  );

  const Footer = useCallback(() => {
    return (
      <LoadMoreActionWrapper>
        {!detections.length && lastDetectionFetched && (
          <NoResultsTextWrapper>
            {isFilterActive
              ? "Filters applied yielded no results in this lane"
              : "No traffic"}
            {" since "}
            <Timestamp date={lastDetectionFetched.detectionDate} />
          </NoResultsTextWrapper>
        )}
        <Button
          variant="primary"
          disabled={!canLoadMore}
          onClick={doFetchDetections}
          {...(!hasMoreResults ? { title: "All entries has been loaded" } : {})}
        >
          {isLoading ? "Loading ..." : "Load older entries"}
        </Button>
      </LoadMoreActionWrapper>
    );
  }, [
    canLoadMore,
    hasMoreResults,
    isLoading,
    detections,
    doFetchDetections,
    isFilterActive,
    lastDetectionFetched,
  ]);
  const lane = useMemo(() => lanes.find((lane) => lane.id === id), [id, lanes]);
  const [liveVideo, toggleLiveVideo] = useToggle(false);

  const computeItemKey = useCallback(
    (_: number, item: Detection) => item.id,
    []
  );
  const components = useMemo(() => ({ Footer }), [Footer]);

  return (
    <Wrapper className={className}>
      <Head>
        <Title>{title}</Title>
        <Count>
          <span>{count ?? "0"}</span>
          <TodayLabel>today</TodayLabel>
          <CountType>{direction}</CountType>
        </Count>
        <LaneActionsWrapper>
          {(lane?.videoSources || []).length > 0 && (
            <EnableCamButton active={liveVideo} onClick={toggleLiveVideo}>
              <VideoIcon />
            </EnableCamButton>
          )}
          <EyeOffIcon onClick={handleHideLaneClick} />
        </LaneActionsWrapper>
        {liveVideo && lane && <LaneCardLiveStream lane={lane} />}
      </Head>
      <Content>
        <Virtuoso
          firstItemIndex={firstItemIndex}
          isScrolling={handleIsScrolling}
          onScroll={handleOnScroll}
          itemContent={ListItem}
          initialItemCount={detections.length}
          data={detections}
          computeItemKey={computeItemKey}
          endReached={handleScrolledToEnd}
          components={components}
          increaseViewportBy={virtualListOverscan}
        />
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 0 8px;
  height: 100%;
  width: 416px;
  min-width: 416px;
  display: flex;
  flex-direction: column;
`;

const Head = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  background: var(--primary);
  padding: 14px 16px 15px;
  margin-bottom: 8px;
  border-radius: 4px;
`;

const Title = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--text-primary);
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
`;

const Count = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--text-primary);
  font-size: 16px;
  font-weight: bold;

  & > svg {
    margin-right: 8px;
  }
`;

const TodayLabel = styled.span`
  font-size: 14px;
  padding-left: 4px;
`;

const CountType = styled.span`
  font-size: 14px;
  font-weight: 400;
  padding-left: 8px;
  text-transform: capitalize;
`;

const Content = styled.div`
  background: var(--primary);
  border-radius: 4px;
  flex-grow: 1;

  & > *:first-child {
    margin-top: 0;
  }
`;

const LoadMoreActionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  > * {
    margin: 8px;
  }
  padding: 8px;
  text-align: center;
`;

const NoResultsTextWrapper = styled.div`
  min-height: 190px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
  color: var(--text-secondary);
`;

const EnableCamButton = styled.a<{ active: boolean }>`
  cursor: pointer;
  opacity: ${(props) => (props.active ? 1 : 0.5)};
`;

const LaneActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  & > * {
    margin-left: 10px;
    display: flex;
    justify-content: center;
    color: var(--text-primary);
    cursor: pointer;
  }
  & svg {
    fill: var(--text-primary);
  }
`;
