import { ReactComponent as EditIcon } from "assets/svgs/edit-icon.svg";
import { ReactComponent as PlusIcon } from "assets/svgs/plus-icon.svg";
import { Button, Spacer } from "components";
import React, { ReactNode, useCallback, useState } from "react";
import styled from "styled-components";

type Size = "normal" | "largish";

interface Props {
  title?: string;
  secondaryTitle?: string;
  onCancel: () => void;
  onSubmit: (toggleEditMode: (editMode: boolean) => void) => Promise<void>;
  editMode: boolean;
  hasErrors?: boolean;
  toggleEditMode: (editMode?: boolean) => void;
  toggleAddMode?: (addMode?: boolean) => void;
  className?: string;
  size?: Size;
  children?: ReactNode;
}

export const FormSidebar: React.FC<Props> = ({
  title,
  secondaryTitle,
  onCancel,
  onSubmit,
  editMode,
  toggleEditMode,
  toggleAddMode,
  hasErrors = false,
  className,
  children,
  size = "normal",
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleToggleEditMode = useCallback(
    (isEditMode: boolean) => {
      toggleEditMode(isEditMode);
      if (!isEditMode) onCancel();
    },
    [toggleEditMode, onCancel]
  );

  const handleFormSubmit = useCallback(async () => {
    setIsSubmitting(true);
    await onSubmit(toggleEditMode);
    setIsSubmitting(false);
  }, [onSubmit, toggleEditMode]);

  const handleFormCancel = useCallback(async () => {
    handleToggleEditMode(false);
    toggleAddMode && toggleAddMode(false);
  }, [handleToggleEditMode, toggleAddMode]);

  return (
    <div className={className}>
      <Header>
        <HeaderTitle>
          <Name>{title || ""}</Name>
          {secondaryTitle ? (
            <SecondaryName editMode={editMode}>{secondaryTitle}</SecondaryName>
          ) : (
            ""
          )}
        </HeaderTitle>
        {editMode ? (
          <HeaderButtonsWrapper>
            <Button
              disabled={isSubmitting || hasErrors}
              onClick={handleFormSubmit}
              size={size}
            >
              Save
            </Button>
            <Spacer width={10} />
            <Button
              onClick={handleFormCancel}
              disabled={isSubmitting}
              size={size}
              withIcon
              noText
            >
              <CrossIcon />
            </Button>
          </HeaderButtonsWrapper>
        ) : (
          <Button withIcon noText size={size} onClick={() => toggleEditMode()}>
            <EditIcon />
          </Button>
        )}
      </Header>
      <Body disabled={!editMode}>{children}</Body>
    </div>
  );
};

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 3px 6px #00000029;
  height: 64px;
  padding: 0 16px 0 24px;
  position: sticky;
  z-index: 2;
  top: 0px;
  background: var(--secondary);
`;

const HeaderTitle = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
`;

const HeaderButtonsWrapper = styled.div`
  white-space: nowrap;
  display: flex;
`;

export const Body = styled.div<{ disabled: boolean }>`
  position: relative;
  padding: 26px 16px 24px 24px;

  &:before {
    display: ${(props) => (props.disabled ? "block" : "none")};
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    background: #2021247a;
  }
`;

const Name = styled.h4`
  font-size: 16px;
  color: var(--text-primary);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SecondaryName = styled.p<{ editMode: boolean }>`
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  ${(props) =>
    props.editMode
      ? `
    text-overflow: ellipsis;
  `
      : ""}
`;

const CrossIcon = styled(PlusIcon)`
  transform: rotate(45deg);
`;
