import { getToken } from "@asantech/common/react/localStorage";
import { ApiEndpoints } from "api/endpoints";
import { ChildrenOnly } from "common/types";
import { OfflineStatus } from "components/OfflineStatus";
import { backendRootUrl } from "config";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { toast } from "react-toastify";
import ReconnectingWebSocket from "reconnecting-websocket";
import {
  DetectionMessage,
  DirectionMessage,
  ExternalNotificationMessage,
  WebSocketMessage,
} from "../../../../backend/src/detections/types";
import { SystemStatusMessage } from "../../components/SystemStatusMessage";

export const appendTokenIfAuthenticated = (url: string): string => {
  const token = getToken();
  return token ? `${url}?token=${token}` : url;
};

const SocketContext = createContext<ReconnectingWebSocket | null | undefined>(
  undefined
);

export const SocketContextProvider = ({ children }: ChildrenOnly) => {
  const [socket, setSocket] = useState<ReconnectingWebSocket | null>(null);
  const [readyState, setReadyState] = useState<number>(WebSocket.OPEN);

  useEffect(() => {
    const socket = new ReconnectingWebSocket(
      appendTokenIfAuthenticated(
        `${backendRootUrl.replace(/^http/, "ws")}${
          ApiEndpoints.DetectionsListen
        }`
      ),
      undefined,
      { connectionTimeout: 1000 }
    );

    setSocket(socket);
    return () => socket.close();
  }, []);

  useEffect(() => {
    if (!socket) return;

    const onStateChange = () => {
      setReadyState(socket.readyState);
    };

    socket.onopen = onStateChange;
    socket.onclose = onStateChange;
    socket.onerror = onStateChange;
  }, [socket]);

  useEffect(() => {
    const toastId = "websocket-offline";

    if (readyState === WebSocket.OPEN) {
      toast.dismiss(toastId);
    } else {
      toast.error(OfflineStatus, {
        autoClose: false,
        position: "bottom-right",
        closeOnClick: false,
        toastId,
      });
    }
  }, [readyState]);

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};

export const useSocket = (messageCb: (message: WebSocketMessage) => void) => {
  const socket = useContext(SocketContext);

  if (socket === undefined)
    throw new Error("useSocket must be used within SocketContextProvider");

  useEffect(() => {
    if (!messageCb || !socket) return;

    const handleMessage = (messageEvent: MessageEvent) => {
      messageCb(JSON.parse(messageEvent.data));
    };

    socket.addEventListener("message", handleMessage);
    return () => socket.removeEventListener("message", handleMessage);
  }, [socket, messageCb]);

  return socket;
};

export const useDetectionsWebSocket = (
  onDetection: (message: DetectionMessage["payload"]) => void
) => {
  const handleMessage = useCallback(
    (message: WebSocketMessage) => {
      if (message.type === "detection") onDetection(message.payload);
    },
    [onDetection]
  );
  useSocket(handleMessage);
};

export const useDirectionsWebSocket = (
  onDetection: (message: DirectionMessage["payload"]) => void
) => {
  const handleMessage = useCallback(
    (message: WebSocketMessage) => {
      if (message.type === "detection_direction") onDetection(message.payload);
    },
    [onDetection]
  );
  useSocket(handleMessage);
};

export const useExternalNotificationWebSocket = (
  onNotification: (message: ExternalNotificationMessage["payload"]) => void
) => {
  const handleMessage = useCallback(
    (message: WebSocketMessage) => {
      if (message.type === "external_notification")
        onNotification(message.payload);
    },
    [onNotification]
  );
  useSocket(handleMessage);
};

export const useSystemStatusSocket = () => {
  const handleMessage = useCallback((wsMessage: WebSocketMessage) => {
    if (wsMessage.type === "system_status") {
      const { message } = wsMessage.payload;
      toast.error(() => <SystemStatusMessage message={message} />, {
        autoClose: false,
        position: "bottom-right",
        closeOnClick: false,
        toastId: "system_status_message",
      });
    }
  }, []);
  useSocket(handleMessage);
};
