import { ChildrenOnly } from "common/types";
import styled, { ThemeProvider } from "styled-components";
import { useOrientation } from "./useOrientation";

export const OrientationContainer = ({ children }: ChildrenOnly) => {
  const orientation = useOrientation();
  return (
    <ThemeProvider theme={orientation}>
      <OrientationElement>
        <Contents>{children}</Contents>
      </OrientationElement>
    </ThemeProvider>
  );
};

const OrientationElement = styled.div`
  ${({ theme }) =>
    theme.deg === "90" || theme.deg === "180" || theme.deg === "270"
      ? `transform: rotate(${theme.deg}deg);`
      : ""}
`;

const Contents = styled.div`
  position: relative;
  ${({ theme }) => `
      ${theme.deg === "270" ? "left: calc(100vw - 100vh);" : ""}
      height: 100${theme.vh};
      width: 100${theme.vw};`}
`;
