import {
  getActiveLanesLocalStorage,
  setFilterActiveLaneIds,
} from "common/localStorage";
import { toggleElementInArray } from "common/utils";
import { useCallback, useEffect, useState } from "react";
import { Lane } from "../../../../../backend/src/lanes/types";
import { TrafficFilters } from "../../../store/detections/Detections.context";

function getInitialLanes(lanes: Lane[]) {
  const storageLanesIds = getActiveLanesLocalStorage();

  const ids = lanes.map((lane: Lane) => lane.id);
  if (storageLanesIds && storageLanesIds.length > 0) {
    return ids.filter((id) => storageLanesIds.includes(id));
  }
  return ids;
}

export const useActiveLanesFilter = (
  lanes: Lane[],
  filters: TrafficFilters,
  setFilters: (filters: TrafficFilters) => void
) => {
  const [initialized, setInitialized] = useState(false);
  const toggleActiveLane = useCallback(
    (lane: string) => {
      const newLanes = toggleElementInArray(lane, filters.lanes);
      setFilters({ ...filters, lanes: newLanes });
    },
    [filters, setFilters]
  );

  useEffect(() => {
    if (lanes.length > 0 && !initialized) {
      setInitialized(true);
      setFilters({ ...filters, lanes: getInitialLanes(lanes) });
    }
  }, [filters, initialized, lanes, setFilters]);

  useEffect(() => {
    if (!initialized) return;
    setFilterActiveLaneIds(filters.lanes);
  }, [filters.lanes, initialized, lanes]);

  return {
    toggleActiveLane: toggleActiveLane,
    activeLaneIds: filters.lanes,
  };
};
