import { ReactComponent as IconWarning } from "assets/svgs/alert-warning-icon.svg";
import styled from "styled-components";

export const SystemStatusMessage = ({ message }: { message: string }) => (
  <Container>
    <IconWarning /> {message}
  </Container>
);

const Container = styled.span`
  font-weight: bold;

  svg {
    vertical-align: middle;
  }
`;
