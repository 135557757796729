import { CarPlatePlain } from "@asantech/common/react/CarPlate";
import { useToggle } from "@react-hookz/web";
import { handleError } from "api/client";
import { deleteExcludedPlate, getExcludedPlates } from "api/plates";
import { usePlateData } from "common/hooks";
import {
  Button,
  Card,
  Table,
  TableSelectableRow,
  TooltipDialog,
  TooltipDialogContainer,
  TooltipDialogContent,
  TooltipDialogFooter,
} from "components";
import { useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { AddExcludedPlateForm } from "views/settings";
import { PlateData } from "../../../../../backend/src/plates/types";

export const ExcludedPlatesTable = () => {
  const { plateData, setPlateData } = usePlateData(getExcludedPlates);
  const [selectedPlateId, setSelectedPlateId] = useState<string | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDeleteExcludedPlate = async () => {
    if (!selectedPlateId) return;

    try {
      setIsDeleting(true);
      await deleteExcludedPlate(selectedPlateId);
      setPlateData(
        plateData.filter((el: PlateData) => el.id !== selectedPlateId)
      );
      setIsDeleting(false);
      toggleDeleteDialog(false);
      setSelectedPlateId(null);
      toast.success("Plate was deleted from excluded list successfully.");
    } catch (error) {
      setIsDeleting(false);
      handleError(error, "Error deleting plate from excluded list.");
    }
  };

  const handleNewExcludedPlate = (newPlate: PlateData) => {
    if (newPlate) {
      setPlateData([...plateData, newPlate]);
      toast.success("Plate was excluded successfully.");
    }
  };

  const handleRowSelected = (id: string) => {
    setSelectedPlateId(id === selectedPlateId ? null : id);
  };
  const [showDeleteDialog, toggleDeleteDialog] = useToggle(false);

  return (
    <Panels>
      <PanelLeft>
        <StyledCard title="Exclude plate">
          <AddExcludedPlateForm onNewPlate={handleNewExcludedPlate} />
        </StyledCard>
      </PanelLeft>
      <PanelCenter>
        <ButtonWrapper>
          <TooltipDialog
            isOpen={showDeleteDialog}
            onDismiss={toggleDeleteDialog}
            content={
              <TooltipDialogContainer role="dialog">
                <TooltipDialogContent>
                  Are you sure you want
                  <br /> to delete this plate?
                </TooltipDialogContent>
                <TooltipDialogFooter>
                  <Button
                    disabled={isDeleting}
                    onClick={handleDeleteExcludedPlate}
                  >
                    Delete
                  </Button>
                  <Button onClick={toggleDeleteDialog}>Don&apos;t</Button>
                </TooltipDialogFooter>
              </TooltipDialogContainer>
            }
          >
            <Button
              variant="error"
              disabled={!selectedPlateId || isDeleting}
              onClick={toggleDeleteDialog}
              size="largish"
            >
              Delete
            </Button>
          </TooltipDialog>
        </ButtonWrapper>
        <TableWrapper>
          <Table>
            <thead>
              <tr>
                <th>Plate</th>
                <th>Comment</th>
              </tr>
            </thead>
            <tbody>
              {plateData.map(({ id, text, country, regionCode, comment }) => (
                <TableSelectableRow
                  selected={id === selectedPlateId}
                  onClick={() => handleRowSelected(id)}
                  key={id}
                >
                  <td>
                    <CarPlatePlain
                      text={text}
                      country={country}
                      regionCode={regionCode}
                    />
                  </td>
                  <td>{comment}</td>
                </TableSelectableRow>
              ))}
            </tbody>
          </Table>
        </TableWrapper>
      </PanelCenter>
    </Panels>
  );
};

const Panels = styled.div`
  display: flex;
  height: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 16px;
  flex-shrink: 0;
`;

const TableWrapper = styled.div`
  flex: 1;
  min-height: 0;
`;

const PanelLeft = styled.div`
  display: block;
  width: 384px;
  height: 100%;
  background: var(--secondary);
  padding: 16px;
`;

const StyledCard = styled(Card)`
  overflow-y: auto;
  max-height: 100%;
`;

const PanelCenter = styled.div`
  width: 100%;
  padding: 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
