import { format } from "date-fns";
import React, { useMemo } from "react";
import styled from "styled-components";
import { ExternalNotifications } from "../../../../backend/src/detections/types";
import { capitalize } from "../../common/utils";

export const DebugInfo: React.FC<{
  plateText: string;
  plateCountry: string | null;
  plateTextConfidence: number;
  detectionDate: string;
  sentToExternal: ExternalNotifications[];
  imageId: string;
}> = ({
  plateText,
  plateCountry,
  plateTextConfidence,
  detectionDate,
  sentToExternal,
  imageId,
}) => {
  const sentDelayInfo = useMemo(() => {
    const notificationsForCurrent = sentToExternal.filter(
      (value) => value.sentDetectionImageId === imageId
    );
    if (notificationsForCurrent.length > 0) {
      const lines = notificationsForCurrent.map((sent) => {
        const sentAt = format(new Date(sent.sentDate), "HH:mm:ss.SSS");
        const notifiersText = sent.notifiers.length
          ? sent.notifiers.map(capitalize).join(", ")
          : "external";
        return `Sent ${sent.sentPlateText || plateText}-${
          sent.sentPlateCountry || plateCountry
        } to ${notifiersText} at ${sentAt}`;
      });
      return Array.from(new Set(lines)).map((line) => <p key={line}>{line}</p>);
    }
  }, [imageId, sentToExternal, plateText, plateCountry]);

  return (
    <InfoWrapper>
      <p>{`Detected text - ${plateText} with confidence of ${plateTextConfidence.toPrecision(
        3
      )}`}</p>
      <p>{`Detected country - ${plateCountry || "Not detected"}`}</p>
      <p>{`Detection time - ${format(
        new Date(detectionDate),
        "HH:mm:ss.SSS"
      )}`}</p>
      <br />
      {sentDelayInfo}
    </InfoWrapper>
  );
};

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
