export const initialFirstItemIndex = Math.floor(Number.MAX_SAFE_INTEGER / 2);

export const DEFAULT_MASK_TO_SCAN = [
  [0.1, 0.1],
  [0.1, 0.9],
  [0.9, 0.9],
  [0.9, 0.1],
];

export const uuidv4Regex =
  /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
