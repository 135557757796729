import { FiltersBar, ResponseStatusMessage, TopBar } from "components";
import { useEffect } from "react";
import { useDetections } from "store/detections/Detections.context";
import { useLanes } from "store/Lanes.context";
import styled from "styled-components";
import { LaneColumns } from "views/lanes";

export const LanesView = () => {
  const { lanes, lanesResponseState, doFetchLanes, doFetchDevices } =
    useLanes();

  const { getLaneFirstItemIndex } = useDetections();

  useEffect(() => {
    doFetchLanes();
    doFetchDevices();
  }, [doFetchDevices, doFetchLanes]);

  return (
    <Container>
      <TopBar />
      <FiltersBar />
      <Content>
        {lanesResponseState.error ? (
          <ResponseStatusMessage>
            Something went wrong... Try again or contact support.
          </ResponseStatusMessage>
        ) : lanesResponseState.status === "not-executed" ||
          lanesResponseState.status === "loading" ? (
          <ResponseStatusMessage>Loading...</ResponseStatusMessage>
        ) : (
          <LaneColumns
            lanes={lanes}
            getLaneFirstItemIndex={getLaneFirstItemIndex}
          />
        )}
      </Content>
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  width: 100%;
`;
