import React from "react";

import {
  FieldError,
  FieldValues,
  Path,
  RegisterOptions,
  UseFormRegister,
} from "react-hook-form";
import styled from "styled-components";

type FieldVariant = "tertiary" | "secondary" | "primary";

type Props<TFieldValues extends FieldValues> = {
  name: Path<TFieldValues>;
  placeholder: string;
  register: UseFormRegister<TFieldValues>;
  required: boolean;
  errors?: FieldError;
  variant?: FieldVariant;
  className?: string;
  registerRules?: RegisterOptions;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
};

export function TextArea<T extends FieldValues>({
  name,
  placeholder,
  register,
  required,
  errors,
  variant = "tertiary",
  registerRules = {},
  onChange,
}: Props<T>) {
  return (
    <StyledTextArea
      {...register(name, { required, ...registerRules })}
      {...(onChange ? { onChange } : {})}
      variant={variant}
      placeholder={placeholder}
      invalid={!!errors}
    />
  );
}

export const StyledTextArea = styled.textarea<{
  invalid: boolean;
  variant: FieldVariant;
}>`
  display: inline-block;
  width: 100%;
  background-color: ${({ variant }) => `var(--${variant})`};
  border-radius: 4px;
  min-height: 40px;
  height: 120px;
  padding: 10px 16px 7px;
  border: 1px solid transparent;
  box-shadow: none;
  color: var(--text-primary);
  border-color: ${({ invalid }) => (invalid ? "var(--live)" : "transparent")};
  resize: vertical;

  &::placeholder {
    color: var(--text-secondary);
    font-style: italic;
    opacity: 0.4;
  }
`;
