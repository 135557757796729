import client from "./client";
import { Setting } from "../../../backend/src/settings/types";
import { ApiEndpoints } from "./endpoints";

export async function getSystemSettings() {
  return client.get<Setting[]>(ApiEndpoints.SystemSettings);
}

export function updateSystemSetting(setting: Setting) {
  return client.put<Setting[]>(`${ApiEndpoints.SystemSettings}`, setting);
}
