import React, { useMemo } from "react";
import { format, parseISO, formatRelative } from "date-fns";

interface Props {
  date: string;
  /** Date format pattern that follows date-fns guidelines. */
  pattern?: string;
  /** Whether output should be relative or not. */
  relative?: boolean;
}

export const Timestamp: React.FC<Props> = ({
  date,
  relative = false,
  pattern = "LLLL dd 'at' HH:mm",
}) => {
  const dateObj = useMemo(() => parseISO(date), [date]);
  const formattedDate = useMemo(
    () =>
      relative ? formatRelative(dateObj, new Date()) : format(dateObj, pattern),
    [pattern, dateObj, relative]
  );

  return <time dateTime={date}>{formattedDate}</time>;
};
