import { ReactComponent as UserIcon } from "assets/svgs/user-icon.svg";
import { useAuth } from "common/hooks/useAuth";
import styled from "styled-components";

export const CurrentUser = () => {
  const { isAuth, userInfo } = useAuth();
  if (!isAuth) return null;
  return (
    <Wrapper>
      <Name>{userInfo?.username || "Guest user"}</Name>
      <UserIcon width="40" height="40" />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
`;

const Name = styled.span`
  display: inline-block;
  color: var(--text-primary);
  text-align: left;
  font-size: 14px;
  font-weight: bold;
  padding-right: 17px;
`;
