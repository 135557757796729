const backendRootUrl_ = process.env.REACT_APP_BACKEND_ROOT_URL || "";

let absoluteBackendRootUrl;
if (backendRootUrl_.startsWith("/")) {
  absoluteBackendRootUrl =
    window.location.protocol + "//" + window.location.host + backendRootUrl_;
} else {
  absoluteBackendRootUrl = backendRootUrl_;
}
export const backendRootUrl = absoluteBackendRootUrl;
