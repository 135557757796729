import { EnvUrls } from "@asantech/common/express/types";
import {
  HamburgerMenu as Menu,
  HamburgerMenuProps as MenuProps,
} from "@asantech/common/react/HamburgerMenu";
import { getAppUrls } from "api/appUrls";
import { Pages } from "common/pages";
import React, { useEffect, useState } from "react";

export const menuLinks = [
  {
    label: "Lanes",
    to: Pages.Home,
  },
  {
    label: "Settings",
    to: Pages.Settings,
  },
  {
    label: "Traffic Audit Log",
    to: Pages.Traffic,
  },
];

export type HamburgerMenuProps = Pick<MenuProps, "isOpen" | "toggle">;

export const HamburgerMenu: React.FC<HamburgerMenuProps> = (props) => {
  const [appUrls, setAppUrls] = useState<EnvUrls>({});

  useEffect(() => {
    const fetchUrls = async () => {
      setAppUrls(await getAppUrls());
    };
    fetchUrls();
  }, []);

  return (
    <Menu {...props} currentApp="anpr" pages={menuLinks} appUrls={appUrls} />
  );
};
