import {
  Direction,
  OrphanReason,
  OrphanReasonMessage,
} from "../../../backend/src/detections/types";
import { LaneDirection } from "../../../backend/src/lanes/types";
import { Detection } from "./utils/detections";

export const toggleElementInArray = <T>(el: T, arr: T[]) => {
  if (arr.includes(el)) {
    return arr.filter((currentEl: T) => currentEl !== el);
  } else {
    return [...arr, el];
  }
};

const orphanedAlertTextByDirection = (direction: Direction | null) =>
  `Orphaned ${direction === "out" ? "exit" : "entrance"}${
    !direction ? " or exit" : ""
  }`;

export const getOrphanedDetectionAlertText = (
  laneDirection: LaneDirection,
  detectionDirection: Direction | null,
  orphanReasons?: OrphanReason[] | null
) => {
  const directionText = orphanedAlertTextByDirection(
    laneDirection === "two-way" ? detectionDirection : laneDirection
  );
  const reasonText = (orphanReasons || [])
    .map((reasonKey) => OrphanReasonMessage?.[reasonKey])
    .filter(Boolean)
    .join(". ");

  return directionText + (reasonText ? `. ${reasonText}` : "");
};

export const getLowConfidenceAlertText = (
  detection?: Pick<
    Detection,
    "inconsistent" | "lowConfidenceSymbols" | "lowConfidenceCountry"
  >
) => {
  let message = "Low confidence detection";
  if (detection?.inconsistent) {
    message = "Inconsistent plate text detections";
  } else if (detection?.lowConfidenceSymbols) {
    message = "Plate text was detected with a low confidence";
  } else if (detection?.lowConfidenceCountry) {
    message = "Country was detected with a low confidence";
  }
  return message;
};

export const keyBy = <T>(array: T[], key: keyof T) =>
  (array || []).reduce((prev, cur) => {
    const index = cur[key] + "";
    prev[index] = cur;
    return prev;
  }, {} as { [x: string]: T });

export const groupByKey = <T>(array: T[], key: keyof T): Record<string, T[]> =>
  (array || []).reduce((prev, cur) => {
    const index = cur[key] + "";
    let existing = prev[index];
    if (!existing) {
      existing = [];
      prev[index] = existing;
    }
    existing.push(cur);
    return prev;
  }, {} as Record<string, T[]>);

export const capitalize = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);

export const directionDropdownOptions = ["in", "out", "two-way"].map(
  (value) => ({
    value,
    label: capitalize(value),
  })
);

export const blobDownload = (data: Blob, filename: string) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
};

export const isNullOrEmpty = (value?: string | null) =>
  value == null || value === "";

export const moveElToFirst = <T>(arr: T[], predicate: (item: T) => boolean) => {
  const idx = arr.findIndex(predicate);
  const item = arr[idx] as T;

  if (idx > 0) {
    arr.splice(idx, 1);
    arr.unshift(item);
    return true;
  }
  return false;
};

export const noop = () => {
  return;
};

/**
 * While target is < es2023
 * https://stackoverflow.com/a/53187807
 */
export function findLastIndex<T>(
  array: Array<T>,
  predicate: (value: T, index: number, obj: T[]) => boolean
): number {
  let l = array.length;
  while (l--) {
    if (predicate(array[l] as T, l, array)) return l;
  }
  return -1;
}
