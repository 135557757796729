import { useCallback, useEffect, useMemo, useState } from "react";

import useScroll from "@asantech/common/react/common/hooks/useScroll";
import { useWindowSize } from "@react-hookz/web";

export const useScrollableMenu = (scrollByValue: number) => {
  const [scrollWrapper, setScrollWrapper] = useState<HTMLDivElement | null>(
    null
  );

  const scrollWrapperRef = useMemo(
    () => ({ current: scrollWrapper }),
    [scrollWrapper]
  );

  const { x } = useScroll(scrollWrapperRef);

  const maxScrollLeft =
    scrollWrapper !== null
      ? scrollWrapper.scrollWidth - scrollWrapper.clientWidth
      : 0;

  const { width } = useWindowSize();

  useEffect(() => {
    if (scrollWrapper === null) return;
    scrollWrapper.scrollLeft = 0;
  }, [scrollWrapper, width]);

  const handleArrowLeftClick = useCallback(() => {
    if (scrollWrapper === null) return;
    scrollWrapper.scrollLeft -= scrollByValue;
  }, [scrollByValue, scrollWrapper]);

  const handleArrowRightClick = useCallback(() => {
    if (scrollWrapper === null) return;
    scrollWrapper.scrollLeft += scrollByValue;
  }, [scrollByValue, scrollWrapper]);

  return {
    setScrollWrapper,
    isLeftArrowVisible: x > 0,
    isRightArrowVisible: x < maxScrollLeft,
    handleArrowLeftClick,
    handleArrowRightClick,
  };
};
