import { AxiosError } from "axios";
import { useCallback, useMemo } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import styled from "styled-components";
import { settingsConfig } from "../../../../../backend/src/settings/config";
import {
  Setting,
  SettingBoolValue,
  SettingConfig,
} from "../../../../../backend/src/settings/types";
import { ReactComponent as MinusIcon } from "../../../assets/svgs/minus-icon.svg";
import { ReactComponent as PlusIcon } from "../../../assets/svgs/plus-icon.svg";
import {
  isValidHttpUrlValidator,
  isValidIpAddress,
} from "../../../common/formUtils";
import { isNullOrEmpty } from "../../../common/utils";
import {
  Button,
  Dropdown,
  FieldErrorComponent,
  TextFieldInput,
} from "../../../components";

interface Props {
  setting: Setting;
  leaveEditMode: () => void;
  onSave: (payload: Setting) => Promise<void>;
}

type FormState = {
  values: { value: string }[];
};

const boolDropdownOptions = [SettingBoolValue.True, SettingBoolValue.False].map(
  (label) => ({ label, value: label })
);

export const ValueEditor = (props: Props) => {
  const { onSave, leaveEditMode, setting } = props;
  const settingConfig: SettingConfig = settingsConfig[setting.codeName];

  const {
    register,
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<FormState>({
    defaultValues: {
      values: setting.values.length
        ? setting.values.map((value) => ({ value }))
        : [{ value: "" }],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "values",
  });

  const doUpdate = async (data: FormState) => {
    const payload = {
      codeName: setting.codeName,
      values: data.values
        .map(({ value }) => value)
        .filter((value) => !isNullOrEmpty(value)),
    };
    try {
      await onSave(payload);
      leaveEditMode();
    } catch (e) {
      let errorMessage = "Failed to save settings";

      if (e instanceof AxiosError && e?.response?.data?.error) {
        errorMessage = e.response.data.error;
      }

      console.error(e);
      toast.error(errorMessage);
    }
  };

  const inputConfig = useMemo(
    () => ({
      ...(settingConfig.isHttpUrl ? isValidHttpUrlValidator() : {}),
      ...(settingConfig.isIpAddress ? isValidIpAddress() : {}),
    }),
    [settingConfig.isHttpUrl, settingConfig.isIpAddress]
  );

  const getErrors = useCallback(
    (index: number) => {
      return errors?.["values"]?.[index]?.value;
    },
    [errors]
  );

  return (
    <Form onSubmit={handleSubmit(doUpdate)}>
      {fields.map((_, index) => (
        <div key={index}>
          <EditSettingRow>
            {settingConfig.isBoolean ? (
              <Controller
                name={`values.${index}.value`}
                control={control}
                render={({ field }) => (
                  <Dropdown
                    {...field}
                    value={field.value}
                    options={boolDropdownOptions}
                  />
                )}
              />
            ) : (
              <TextFieldInput
                {...register(`values.${index}.value`, inputConfig)}
                invalid={!!getErrors(index)}
                required={false}
                autoFocus={true}
                variant="secondary"
                placeholder=""
              />
            )}
            {settingConfig.isMultiValue && (
              <Button
                disabled={getValues("values").length <= 1}
                type="button"
                onClick={() => remove(index)}
              >
                <MinusIcon />
              </Button>
            )}
          </EditSettingRow>
          <FieldErrorComponent error={getErrors(index)} />
        </div>
      ))}
      {settingConfig.isMultiValue && (
        <EditSettingRow>
          <Button type="button" onClick={() => append({ value: "" })}>
            <PlusIcon />
          </Button>
        </EditSettingRow>
      )}
      <ButtonActions>
        <Button
          type="button"
          variant="secondary"
          onClick={() => leaveEditMode()}
        >
          Cancel
        </Button>
        <Button variant={"primary"} type="submit">
          Save
        </Button>
      </ButtonActions>
    </Form>
  );
};

const Form = styled.form`
  width: 100%;
`;
const EditSettingRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-direction: row;
  row-gap: 10px;
  column-gap: 10px;
  padding: 5px 0;
`;

const ButtonActions = styled(EditSettingRow)`
  justify-content: flex-end;
`;
