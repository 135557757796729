import {
  regionFromText,
  useInputPlate,
} from "@asantech/common/react/common/hooks/useInputPlate";
import { createExcludedPlate } from "api/plates";
import {
  handleFormSubmitError,
  maxLengthValidator,
  PLATE_COUNTRY_CODE_MAX_LENGTH,
  PLATE_TEXT_MAX_LENGTH,
} from "common/formUtils";
import { Button, FieldErrorComponent, TextArea, TextField } from "components";
import React from "react";
import { useForm } from "react-hook-form";
import { PlateData } from "../../../../../backend/src/plates/types";
import { ButtonWrapper, Form, Label } from "../Form";

type ExcludePlateFormData = {
  text: string;
  country: string;
  regionCode: string;
  comment: string;
};

const defaultValues = {
  text: "",
  country: "FIN",
  regionCode: "",
  comment: "",
};

export const AddExcludedPlateForm: React.FC<{
  onNewPlate: (newPlate: PlateData) => void;
}> = ({ onNewPlate }) => {
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
    setError,
    getValues,
    clearErrors,
    trigger,
  } = useForm<ExcludePlateFormData>({
    defaultValues,
    mode: "onChange",
  });

  const onSubmit = async (formData: ExcludePlateFormData) => {
    const input = regionFromText(formData);
    try {
      const newPlate = await createExcludedPlate(input);
      if (newPlate) {
        onNewPlate(newPlate);
        reset();
      }
    } catch (error) {
      handleFormSubmitError(error, setError);
    }
  };

  const regionRequired = () => {
    const country = getValues("country");
    if (!country) return;
    return country === "DEU";
  };

  const { validateText, textRegister, countryRegister } = useInputPlate(
    register,
    regionRequired
  );

  return (
    <Form data-testid="AddExcludedPlateForm" onSubmit={handleSubmit(onSubmit)}>
      <Label>Vehicle registration*</Label>
      <TextField
        name="text"
        register={textRegister}
        registerRules={{
          validate: validateText,
          onBlur: () => {
            clearErrors("regionCode");
          },
        }}
        maxLength={PLATE_TEXT_MAX_LENGTH}
        placeholder={regionRequired() ? "A BC1234" : "ABC123"}
        required={true}
        variant="secondary"
        errors={errors?.text}
        autoComplete="off"
      />
      <FieldErrorComponent error={errors?.text} />

      <Label>Vehicle registration country*</Label>
      <TextField
        name="country"
        register={countryRegister}
        registerRules={{
          onBlur: () => {
            clearErrors("regionCode");
            trigger("text");
          },
        }}
        maxLength={PLATE_COUNTRY_CODE_MAX_LENGTH}
        minLength={PLATE_COUNTRY_CODE_MAX_LENGTH}
        placeholder="FIN"
        required={true}
        variant="secondary"
        errors={errors?.country}
        autoComplete="off"
      />
      <FieldErrorComponent error={errors?.country} />

      <Label>Comment*</Label>
      <TextArea
        register={register}
        name="comment"
        registerRules={maxLengthValidator(250)}
        required={true}
        placeholder="Insert comment"
        variant="secondary"
        errors={errors.comment}
      />
      <FieldErrorComponent error={errors.comment} />
      <ButtonWrapper>
        <Button type="submit" variant="secondary">
          Exclude plate
        </Button>
      </ButtonWrapper>
    </Form>
  );
};
