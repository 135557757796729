import { useAsync } from "@react-hookz/web";
import { reboot } from "api/maintenance";
import { Button, Table } from "components";
import styled from "styled-components";

export const Maintenance = () => {
  const [state, { execute: handleRebootClick }] = useAsync(reboot);

  return (
    <Container>
      <Table>
        <thead>
          <tr>
            <th colSpan={2}>Maintenance operations</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Reboot edge device</td>
            <td>
              <Button
                disabled={state.status === "loading"}
                onClick={handleRebootClick}
              >
                Reboot
              </Button>
            </td>
          </tr>
        </tbody>
      </Table>
    </Container>
  );
};

const Container = styled.div`
  max-width: 900px;
  padding: 20px;
  margin: 20px auto;
`;
