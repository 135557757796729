import React from "react";
import { ValidationError } from "common/formUtils";
import { FieldError } from "react-hook-form";
import styled from "styled-components";

interface Props {
  error: ValidationError | FieldError | undefined;
}

const standardErrorMessages: { [key: string]: string } = {
  required: "Field is required",
  email: "Email is not valid",
};

export const FieldErrorComponent: React.FC<Props> = ({ error }) => {
  const type: string = (error as FieldError)?.type;
  const message = error?.message || standardErrorMessages[type];

  if (!message) {
    return null;
  }
  return <FieldErrorMessage>{message}</FieldErrorMessage>;
};

const FieldErrorMessage = styled.p`
  color: var(--live);
  font-weight: 500;
  margin-top: 4px;
`;
